import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadChefs = async (cancelToken) => {
    const res = await axios({
        url: '/api/chefs/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadChefInfo = async (cancelToken) => {
    const res = await axios({
        url: '/api/chefs/load_info/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addChef = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        data,
        url: '/api/chefs/',
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editChef = async (data, chefId, cancelToken) => {
    const res = await axios({
        method: 'patch',
        data,
        url: `/api/chefs/${chefId}/`,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deleteChef = async (chefId, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/chefs/${chefId}/`,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};
