import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import moment from 'moment';
import styles from '../styles';

function SelectCheckList({
    restaurant, dateFormatted, checkLists, onCheckListSelected,
}) {
    function findCheckList(date, week) {
        const dateMoment = moment(date, 'YYYY-MM-DD');
        const weekMoment = moment(week.week_start, 'YYYY-MM-DD');
        const endWeekMoment = moment(week.week_end, 'YYYY-MM-DD');
        if (dateMoment.isBetween(weekMoment, endWeekMoment, null, '[]')) {
            return true;
        }
        return false;
    }

    function getCheckListsOptions() {
        if (checkLists.length === 0) {
            return [];
        }
        return checkLists.map(checkList => ({
            value: checkList.id,
            label: `${checkList.week.week_start} - ${checkList.week.week_end}`,
        }));
    }

    function getSelectedCheckList() {
        if (dateFormatted === null || checkLists.length === 0) {
            return null;
        }

        const selectedCheckList = checkLists.filter(checkList => findCheckList(dateFormatted, checkList.week))[0];
        if (!selectedCheckList) return null;
        const week = `${selectedCheckList.week.week_start} - ${selectedCheckList.week.week_end}`;
        return {
            value: selectedCheckList.id,
            label: week,
        };
    }

    function selectCheckList(selected) {
        onCheckListSelected(selected.label.substring(0, 10));
    }

    return (
        <Select
            styles={styles.selectField}
            placeholder="Выберите чек-лист"
            value={getSelectedCheckList()}
            options={getCheckListsOptions()}
            onChange={selectCheckList}
            noOptionsMessage={() => 'Нет доступных чек-листов'}
        />
    );
}

export default SelectCheckList;

SelectCheckList.defaultProps = {
    restaurant: {},
    dateFormatted: '',
    checkLists: [],
};

SelectCheckList.propTypes = {
    restaurant: PropTypes.shape({
        id: PropTypes.number,
        chef: PropTypes.number,
        name: PropTypes.string,
        sort_order: PropTypes.number,
    }),
    dateFormatted: PropTypes.string,
    checkLists: PropTypes.arrayOf(PropTypes.object),
    onCheckListSelected: PropTypes.func.isRequired,
};
