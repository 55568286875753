import React, { memo } from 'react';
import { TableCell as MaterialTableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from '../styles';

function TableCell({ children, style, ...rest }) {
    return (
        <MaterialTableCell style={{ ...styles.positionsTableCell, ...style }} {...rest}>
            {children}
        </MaterialTableCell>
    );
}

TableCell.defaultProps = {
    children: null,
    style: {},
};

TableCell.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(PropTypes.string, PropTypes.number),
        PropTypes.node,
    ]),
    style: PropTypes.objectOf(PropTypes.string),
};

export default memo(TableCell);
