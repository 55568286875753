import React, { Component } from 'react';
import {
    Card, CardContent, Button, TextField,
} from '@material-ui/core';
import styles from './styles';
import AuthService from './AuthService';
import history from './history';

class Login extends Component {
    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.Auth = new AuthService();
        this.state = {
            errorText: '',
            email: '',
            password: '',
        };
    }

    componentWillMount() {
        if (this.Auth.loggedIn()) {
            history.replace('/');
        }
    }

    async handleFormSubmit(e) {
        const { email, password } = this.state;
        e.preventDefault();
        try {
            await this.Auth.login(email, password);
            history.push('/');
        } catch (err) {
            this.setState({ errorText: 'Неправильная почта или пароль' });
        }
    }

    handleChange(e) {
        this.setState({
            [e.target.name]: e.target.value,
        });
    }

    render() {
        const { errorText, email, password } = this.state;
        return (
            <div style={styles.parent}>
                <Card style={{ width: 250, paddingLeft: 10 }}>
                    <CardContent>
                        <form onSubmit={this.handleFormSubmit}>
                            <TextField
                                name="email"
                                type="email"
                                value={email}
                                style={styles.loginTextField}
                                onChange={this.handleChange}
                                placeholder="Введите почту"
                                required
                            />
                            <TextField
                                name="password"
                                value={password}
                                type="password"
                                style={styles.loginTextField}
                                onChange={this.handleChange}
                                placeholder="Введите пароль"
                                required
                            />
                            <Button
                                style={{ marginTop: 20 }}
                                variant="contained"
                                color="primary"
                                type="submit"
                            >
                                Вход
                            </Button>
                        </form>
                        {errorText !== '' ? <p style={{ color: 'red', margin: 0, marginTop: 10 }}>{errorText}</p> : ''}
                    </CardContent>
                </Card>
            </div>
        );
    }
}

export default Login;
