import React from 'react';
import withAuth from '../withAuth';
import BaseObject from '../common/BaseObject';

function Units() {
    return (
        <BaseObject
            baseUrl="units"
            title="Ед. измерения"
        />
    );
}

export default withAuth(Units);
