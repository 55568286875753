import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function ChooseDialog({
    open, dialogParams: {
        dialogTitle, dialogText, primaryButtonTitle, secondaryButtonTitle, onPrimaryOption, onSecondaryOption,
    }, onCancel,
}) {
    return (
        <Dialog
            open={open}
            onBackdropClick={onCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
                style={{ width: '600px' }}
                className="dialog"
            >
                <DialogContentText>
                    {dialogText}
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
                {onSecondaryOption
                    ? (
                        <div>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onSecondaryOption}
                            >
                                {secondaryButtonTitle}
                            </Button>
                        </div>
                    )
                    : <div />
                }
                <div>
                    <Button
                        style={{ marginRight: '7px' }}
                        variant="contained"
                        color="primary"
                        onClick={onPrimaryOption}
                    >
                        {primaryButtonTitle}
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                    >
                        Отмена
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default (ChooseDialog);

ChooseDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    dialogParams: PropTypes.shape({
        dialogTitle: PropTypes.string.isRequired,
        dialogText: PropTypes.string,
        primaryButtonTitle: PropTypes.string,
        secondaryButtonTitle: PropTypes.string,
        onPrimaryOption: PropTypes.func,
        onSecondaryOption: PropTypes.func,
    }).isRequired,
    onCancel: PropTypes.func.isRequired,
};
