import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import Select from 'react-select';
import moment from 'moment';
import styles from '../styles';

function SelectEditedCheckList({ editedCheckLists, onDateSelected }) {
    function getCheckListOptions() {
        if (editedCheckLists.length === 0) {
            return [];
        }
        return editedCheckLists.map(checkList => ({
            value: checkList.id,
            label: `${checkList.week.week_start} - ${checkList.week.week_end}`,
        }));
    }

    function selectCheckList(e) {
        const date = moment(e.label, 'YYYY-MM-DD');
        onDateSelected(date);
    }

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'wrap',
            marginBottom: '10px',
        }}
        >
            <Typography
                style={{ marginRight: '10px' }}
                variant="h6"
            >
                Непросмотренные чек-листы:
            </Typography>
            <Select
                styles={{ ...styles.selectField, marginRight: '0px' }}
                placeholder="Выберите..."
                // value={}
                options={getCheckListOptions()}
                onChange={e => selectCheckList(e)}
            // noOptionsMessage={() => 'Нет доступных чек-листов'}
            />
        </div>
    );
}

SelectEditedCheckList.propTypes = {
    editedCheckLists: PropTypes.arrayOf(PropTypes.object).isRequired,
    onDateSelected: PropTypes.func.isRequired,
};

export default SelectEditedCheckList;
