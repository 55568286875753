import React from 'react';
import AuthService from './AuthService';
import history from './history';

export default function withAuth(AuthComponent) {
    return function AuthWrapped(props) {
        const Auth = new AuthService();
        const username = Auth.getUsername();
        const role = Auth.getRole();

        if (!Auth.loggedIn()) {
            history.replace('/login');
        }
        return <AuthComponent username={username} role={role} {...props} />;
    };
}
