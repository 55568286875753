import React from 'react';
import {
    Router as ReactRouter, Route, Switch, Redirect,
} from 'react-router-dom';
import history from './history';

import Login from './Login';
import Units from './units';
import Salesmen from './salesmen';
import Restaurants from './restaurants';
import Chains from './chains';
import Groups from './groups';
import CheckLists from './check_lists';
import PriceAnalysis from './price_analysis';
import Offer from './offer';
import Demand from './demand';
import Settings from './settings';
import PriceAnalysisChef from './chef';
import NotFound from './NotFound';
import AuthService from './AuthService';
import AdminRoute from './AdminRoute';

function Router() {
    const Auth = new AuthService();
    const getRootComponent = () => {
        const role = Auth.getRole();
        if (role === 'admin') return <Redirect to="/offer" />;
        if (role === 'chef') return <Redirect to="/chef" />;
        return <Redirect to="/login" />;
    };
    return (
        <ReactRouter history={history}>
            <div style={{ margin: 20 }}>
                <Switch>
                    <Route exact path="/" render={getRootComponent} />
                    <Route exact path="/login" component={Login} />
                    <AdminRoute exact path="/units" component={Units} />
                    <AdminRoute exact path="/salesmen" component={Salesmen} />
                    <AdminRoute exact path="/restaurants" component={Restaurants} />
                    <AdminRoute exact path="/chains" component={Chains} />
                    <AdminRoute exact path="/groups" component={Groups} />
                    <AdminRoute exact path="/check_lists" component={CheckLists} />
                    <AdminRoute exact path="/price_analysis" component={PriceAnalysis} />
                    <AdminRoute exact path="/offer" component={Offer} />
                    <AdminRoute exact path="/demand" component={Demand} />
                    <AdminRoute exact path="/settings" component={Settings} />
                    <Route path="/chef" component={PriceAnalysisChef} />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
        </ReactRouter>
    );
}

export default Router;
