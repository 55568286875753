import React, { Fragment, memo } from 'react';
import {
    Button, Paper, Table, TableBody, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import ChefRow from './ChefRow';
import { round2 } from '../common/utils';
import styles from '../styles';

function ChefDayTab({
    tabType, dayOrder, orderIndex, checkListTime, sortingPositions, changeVolume, recalculate, role, sortType,
    anotherOffers, salesmanChanged,
}) {
    const sortingCounter = sortingPositions.length;

    function onRecalculate(salesman) {
        if (tabType === 'week') {
            recalculate(salesman, null);
        } else if (tabType === 'day') {
            recalculate(salesman, dayOrder.date);
        }
    }

    function updatedPositionSortOrder(pos) {
        let isEdited = false;
        sortingPositions.map((sortPos) => {
            if (sortPos.name === pos.name) {
                pos.sort_order = sortPos.sort_order;
                isEdited = true;
            }
            return sortPos;
        });
        if (!isEdited) {
            pos.sort_order += sortingCounter + 1000;
            // sortingCounter += 1;
        }
    }

    function sortForChef(positions) {
        positions.map(pos => updatedPositionSortOrder(pos));
        return positions;
    }

    function sortPositions(nextPos, pos) {
        if (nextPos.sort_order < pos.sort_order) {
            return -1;
        }
        if (nextPos.sort_order > pos.sort_order) {
            return 1;
        }
        return 0;
    }

    function sortPositionsAlphabet(nextPos, pos) {
        if (nextPos.name < pos.name) {
            return -1;
        }
        if (nextPos.name > pos.name) {
            return 1;
        }
        return 0;
    }

    function sortPositionsGroups(nextPos, pos) {
        if (nextPos.group.sort_order < pos.group.sort_order) {
            return -1;
        }
        if (nextPos.group.sort_order > pos.group.sort_order) {
            return 1;
        }
        if (nextPos.group.name < pos.group.name) {
            return -1;
        }
        if (nextPos.group.name > pos.group.name) {
            return 1;
        }
        return 0;
    }

    function sortPositionsSalesmen(nextPos, pos) {
        if (nextPos.salesman_sort_order < pos.salesman_sort_order) {
            return -1;
        }
        if (nextPos.salesman_sort_order > pos.salesman_sort_order) {
            return 1;
        }
        if (nextPos.salesman_name < pos.salesman_name) {
            return -1;
        }
        if (nextPos.salesman_name > pos.salesman_name) {
            return 1;
        }
        return 0;
    }

    function getDuplicates(flatData) {
        const count = flatData.map(position => ({ name: position.name, count: 1 })).reduce((a, b) => {
            a[b.name] = (a[b.name] || 0) + b.count;
            return a;
        }, {});
        const duplicates = Object.keys(count).filter(a => count[a] > 1);
        return duplicates;
    }

    function mergePositions(positions) {
        const res = positions.reduce((a, b, i) => {
            if (i === 0) {
                return {
                    ...a,
                    salesman_name: [a.salesman_name],
                    pack: [a.pack],
                    volume: [a.volume],
                    unit_price: [a.unit_price],
                    volume_price: [a.volume_price],
                    last_edit: [a.last_edit],
                };
            }
            const newObj = a;
            newObj.salesman_name.push(b.salesman_name);
            newObj.pack.push(b.pack);
            newObj.volume.push(b.volume);
            newObj.unit_price.push(b.unit_price);
            newObj.volume_price.push(b.volume_price);
            newObj.last_edit.push(b.last_edit);
            return newObj;
        }, positions[0]);
        return res;
    }
    function getSortedPositions(data) {
        let flatData = data.flatMap(salesman => salesman.positions);
        if (tabType === 'week') {
            const duplicates = getDuplicates(flatData);
            const mergedArray = [];
            duplicates.forEach((pos) => {
                const temp = flatData.filter(position => position.name === pos);
                const merged = mergePositions(temp);
                mergedArray.push(merged);
            });
            mergedArray.forEach((newPos) => {
                const replaceIndex = flatData.findIndex((pos => pos.name === newPos.name));
                flatData[replaceIndex] = newPos;
                flatData[replaceIndex].replaced = true;
                let foundIndex;
                do {
                    foundIndex = flatData.findIndex((pos => (!pos.replaced && pos.name === newPos.name)));
                    if (foundIndex !== -1) {
                        flatData.splice(foundIndex, 1);
                    }
                } while (foundIndex !== -1);
            });
        }
        if (sortingPositions.length !== 0) {
            flatData = sortForChef(flatData);
        }
        let sortFunction = null;
        switch (sortType) {
            case 'alphabet':
                sortFunction = sortPositionsAlphabet;
                break;
            case 'groups':
                sortFunction = sortPositionsGroups;
                break;
            case 'salesmen':
                sortFunction = sortPositionsSalesmen;
                break;
            default:
                sortFunction = sortPositions;
        }
        const sortedPositions = flatData.sort(sortFunction);
        sortedPositions.forEach((pos, index) => { pos.id = index + 1; });
        return sortedPositions.map((position) => {
            const filteredPositions = anotherOffers.filter(pos => pos.name === position.name &&
                pos.salesman_name !== position.salesman_name &&
                (tabType === 'week' || position.frequency === pos.frequency || position.frequency === 0));
            return (
                <ChefRow
                    key={`${position.sort_order} ${position.salesman_name}`}
                    position={position}
                    orderIndex={orderIndex}
                    changeVolume={changeVolume}
                    date={dayOrder.date}
                    status={dayOrder.status}
                    checkListTime={checkListTime}
                    anotherOffers={filteredPositions}
                    salesmanChanged={salesmanChanged}
                />
            )
        });
    }

    return (
        <Fragment>
            <h5 style={{ margin: '0 0 10px 10px' }}>
                {tabType === 'week' ? 'Закупка на неделю' : `Дата заказа: ${dayOrder.date}`}
            </h5>
            <Paper style={styles.positionsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>id</TableCell>
                            <TableCell>Группа</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Марка</TableCell>
                            <TableCell>Ед. изм.</TableCell>
                            <TableCell>Упаковка</TableCell>
                            <TableCell>Объем</TableCell>
                            <TableCell />
                            <TableCell>Частота</TableCell>
                            <TableCell>Поставщик</TableCell>
                            {role === 'admin'
                                && <TableCell>Цена за ТЕ</TableCell>
                            }
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {getSortedPositions(dayOrder.data)}
                        {role === 'admin'
                            && (
                                <TableRow>
                                    <TableCell style={styles.boldCell}>Итого:</TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                    <TableCell style={styles.boldCell}>
                                        {round2(dayOrder.data.reduce((total, current) => total + current.total, 0))}
                                    </TableCell>
                                    <TableCell />
                                </TableRow>
                            )}
                    </TableBody>
                </Table>
            </Paper>
            <Fragment>
                <h5 style={{ margin: '0 0 10px 10px' }}>
                    Информация поставщиков
                </h5>
                <Paper style={styles.positionsTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Поставщик</TableCell>
                                <TableCell>Сумма заказа</TableCell>
                                <TableCell>Сумма доставки</TableCell>
                                <TableCell>Пороговая сумма</TableCell>
                                {role === 'admin'
                                    && <TableCell />
                                }
                            </TableRow>
                        </TableHead>
                        {dayOrder.data
                            .filter(salesman => salesman.name !== '')
                            .map(salesman => (
                                <TableBody key={salesman.name}>
                                    <TableRow>
                                        <TableCell>{salesman.name}</TableCell>
                                        <TableCell>{round2(salesman.total)}</TableCell>
                                        <TableCell>
                                            { salesman.delivery_price !== 0
                                                ? round2(salesman.delivery_price)
                                                : 'Бесплатно'
                                            }
                                        </TableCell>
                                        <TableCell
                                            style={
                                                salesman.delivery_price !== 0 || salesman.total - salesman.delivery_price < salesman.min_price
                                                    ? styles.redCell
                                                    : styles.greenCell
                                            }
                                        >
                                            {round2(salesman.min_price)}
                                        </TableCell>
                                        {role === 'admin'
                                            && (
                                                <TableCell
                                                    style={{ textAlign: 'center' }}
                                                >
                                                    <Button
                                                        style={{ minWidth: '151px' }}
                                                        color="primary"
                                                        variant="contained"
                                                        // disabled={tabType !== 'week'}
                                                        onClick={() => onRecalculate(salesman)}
                                                    >
                                                        Пересчитать
                                                    </Button>
                                                </TableCell>
                                            )}
                                    </TableRow>
                                </TableBody>
                            ))}
                    </Table>
                </Paper>
            </Fragment>
        </Fragment>
    );
}

ChefDayTab.propTypes = {
    tabType: PropTypes.string.isRequired,
    dayOrder: PropTypes.shape({
        id: PropTypes.number,
        data: PropTypes.array,
        date: PropTypes.string,
        order: PropTypes.number,
        status: PropTypes.string,
    }).isRequired,
    orderIndex: PropTypes.number.isRequired,
    checkListTime: PropTypes.string.isRequired,
    sortingPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeVolume: PropTypes.func.isRequired,
    recalculate: PropTypes.func.isRequired,
    role: PropTypes.string.isRequired,
    anotherOffers: PropTypes.arrayOf(PropTypes.object),
    salesmanChanged: PropTypes.func.isRequired,
    sortType: PropTypes.string,
};

ChefDayTab.defaultProps = {
    sortType: 'default',
    anotherOffers: [],
};

export default memo(ChefDayTab);
