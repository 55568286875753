const parseErrors = (showError, title, error) => {
    if (!error) {
        showError(title, 'Неизвестная ошибка, обратитесь к администратору.');
        return;
    }

    if (!error.response) {
        showError(title, JSON.stringify(error));
        return;
    }

    if (!error.response.data) {
        showError(title, JSON.stringify(error.response));
        return;
    }

    // console.log(error.response);
    if (error.response.status === 500) {
        showError(title, 'Ошибка сервера, обратитесь к администратору.');
        return;
    }

    const { data } = error.response;

    if (data.errors) {
        const text = data.errors.join('\r\n');
        showError(title, text);
        return;
    }
    if (!data.errors) {
        const values = Object.values(data).map(value => Object.values(value));
        const flatted = values.flat(2);
        const text = flatted.join('\r\n');
        showError(title, text);
        return;
    }


    showError(title, JSON.stringify(error.response.data));
};

export default parseErrors;
