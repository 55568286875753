import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import {
    Table, TableBody, TableRow, TableCell, TableHead, Paper, Typography,
} from '@material-ui/core';
import styles from '../styles';
import AddPreferredSalesmenRow from './AddPreferredSalesmenRow';
import PreferredSalesmenRow from './PreferredSalesmenRow';

export default function PreferredSalesmenTable({
    preferredPositions, salesmen, addPosition, deletePosition, editPosition, offerPositions,
}) {
    return (
        <Fragment>
            <Typography variant="h6">Приоритетные поставщики</Typography>
            <Paper style={styles.positionsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Поставщик</TableCell>
                            <TableCell>Товар</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <AddPreferredSalesmenRow
                            salesmen={salesmen}
                            addPosition={addPosition}
                            offerPositions={offerPositions}
                        />
                        {preferredPositions.map(pos => (
                            <PreferredSalesmenRow
                                key={pos.id}
                                position={pos}
                                salesmen={salesmen}
                                editPosition={editPosition}
                                deletePosition={deletePosition}
                                offerPositions={offerPositions}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Fragment>
    );
}

const salesman = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    min_price: PropTypes.number,
    sort_order: PropTypes.number,
});


PreferredSalesmenTable.propTypes = {
    preferredPositions: PropTypes.arrayOf(
        PropTypes.shape({
            salesman_name: PropTypes.string,
            salesman: PropTypes.number,
            position: PropTypes.string,
            id: PropTypes.number,
        }),
    ).isRequired,
    offerPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    salesmen: PropTypes.arrayOf(salesman).isRequired,
    addPosition: PropTypes.func.isRequired,
    editPosition: PropTypes.func.isRequired,
    deletePosition: PropTypes.func.isRequired,
};
