import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadOffers = async (params, cancelToken) => {
    const res = await axios({
        method: 'get',
        url: '/api/offers/',
        params,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadOffer = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/offers/get_or_create/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addPosition = async (offerPosition, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/offer_positions/',
        data: offerPosition,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editPosition = async (offerPosition, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/offer_positions/${offerPosition.id}/`,
        data: offerPosition,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deletePosition = async (positionId, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/offer_positions/${positionId}/`,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const handleImport = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/offers/import-sheet/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const handleExport = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/offers/export-sheet/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
