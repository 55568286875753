import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Link,
} from '@material-ui/core';
import PropTypes from 'prop-types';

function LinksDialog({
    open, dialogTitle, links, onCancel,
}) {
    return (
        <Dialog
            open={open}
            onBackdropClick={onCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent className="dialog">
                {links.map(link => (
                    <Link
                        key={link.salesman}
                        style={{ display: 'block', marginTop: '5px' }}
                        href={link.url}
                        target="_blank"
                    >
                        {link.salesman}
                    </Link>
                ))}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
}

LinksDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    dialogTitle: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
        PropTypes.shape({
            url: PropTypes.string,
            salesman: PropTypes.string,
        }),
    ).isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default LinksDialog;
