import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadCheckLists = async (dateFormatted, cancelToken) => {
    const res = await axios({
        url: '/api/check_lists/',
        params: {
            week: dateFormatted,
        },
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadChefCheckLists = async (data, cancelToken) => {
    const res = await axios({
        url: '/api/check_lists/chef/',
        method: 'post',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const handleExport = async (data, cancelToken) => {
    await axios({
        method: 'post',
        url: '/api/check_lists/export_sheet/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
};

export const getDocumentsForSalesman = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: 'api/check_lists/create_documents/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const saveCheckList = async (data, cancelToken) => {
    await axios({
        method: 'patch',
        url: `/api/check_lists/${data.check_list_id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
};

export const calculateCheckList = async (params, cancelToken) => {
    const res = await axios({
        url: '/api/check_lists/calculate/',
        params,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const calculateCheckListChain = async (params, cancelToken) => {
    const res = await axios({
        url: '/api/check_lists/calculate_chain/',
        params,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const recalculateCheckList = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/check_lists/recalculate/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadEditedCheckLists = async (cancelToken) => {
    const res = await axios({
        method: 'get',
        url: '/api/check_lists/list_edited_check_lists/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const changePosition = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/check_lists/change_position/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
