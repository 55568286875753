import React, { Fragment } from 'react';
import { TextField, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

function SalesmanEmail({ salesman, changeEmail, saveEmail }) {
    return (
        <Fragment>
            <Grid
                container
                alignItems="center"
                justify="space-between"
                spacing={24}
            >
                <Grid item>
                    {salesman.name}
                </Grid>
                <Grid item>
                    <TextField
                        placeholder="Email"
                        // disabled={disabled}
                        value={salesman.email}
                        onChange={e => changeEmail(e.target.value, salesman.id)}
                        onBlur={() => saveEmail(salesman)}
                    />
                </Grid>
            </Grid>
        </Fragment>
    );
}

SalesmanEmail.propTypes = {
    salesman: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
    }).isRequired,
    changeEmail: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
};

export default SalesmanEmail;
