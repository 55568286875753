import React, { Fragment, memo } from 'react';
import { TableRow, Button } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
// import styles from '../styles';

// const NEW = 'NW';
// const SENT = 'ST';
// const EDITED = 'ED';
// const APPROVED = 'AP';

function CheckListRow({
    id, restaurant, status, isViewed, date,
}) {
    // const getStatus = (status) => {
    //     if (status === NEW) {
    //         return 'Новый';
    //     } if (status === SENT) {
    //         return 'Отправлен шефу';
    //     } if (status === EDITED) {
    //         return 'Исправлен шефом';
    //     } if (status === APPROVED) {
    //         return 'Подтвержден';
    //     }
    //     return 'Нет статуса';
    // };

    // const statusColor = () => {
    //     if (status === NEW) return { ...styles.blueCell };
    //     if (status === SENT) return { ...styles.yellowCell };
    //     if (status === EDITED) return { ...styles.redCell };
    //     if (status === APPROVED) return { ...styles.greenCell };
    //     return {};
    // };

    return (
        <Fragment>
            <TableRow>
                <TableCell>
                    {restaurant.name}
                </TableCell>
                {/* <TableCell style={statusColor()}>
                    {getStatus(status)}
                </TableCell> */}
                <TableCell style={{ minWidth: '40px' }}>
                    {!isViewed
                        ? <VisibilityOffIcon style={{ margin: '0 0 -7px 10' }} color="primary" />
                        : ''}
                </TableCell>
                <TableCell
                    style={{ minWidth: '230px' }}
                    align="center"
                >
                    <Button
                        style={{ color: '#ffffff' }}
                        component={Link
                        }
                        to={{
                            pathname: '/chef',
                            search: `?restaurant=${restaurant.id}&date=${date}`,
                        }}
                        color="primary"
                        variant="contained"
                    >
                        Перейти к чек-листу
                    </Button>
                </TableCell>
            </TableRow>
        </Fragment>
    );
}

CheckListRow.propTypes = {
    id: PropTypes.number.isRequired,
    restaurant: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
    }).isRequired,
    // status: PropTypes.string.isRequired,
    isViewed: PropTypes.bool.isRequired,
    date: PropTypes.string.isRequired,
};

export default memo(CheckListRow);
