import React, { Fragment } from 'react';
import {
    Paper,
    ExpansionPanel,
    ExpansionPanelSummary,
    ExpansionPanelDetails,
    Typography,
    Table,
    TableBody,
    TableHead,
    TableRow,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PropTypes from 'prop-types';
import AddPositionRow from './AddPositionRow';
import OfferPositionRow from './OfferPositionRow';
import TableCell from '../common/TableCell';
import styles from '../styles';


export default function OfferPositionsTable({
    positions, groups, units, addPosition, editPosition, deletePosition,
}) {
    return (
        <Fragment>
            <ExpansionPanel>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>Информация</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                    style={{
                        maxWidth: '600px',
                        textAlign: 'center',
                        padding: '5px',
                        display: 'block',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    Если Вы хотите указать несколько вариантов упаковка-цена, то введите их через запятую. <br />
                    Пример: <br />
                    Упаковка - 0.25,1,2 <br />
                    Цена за ТЕ. - 250,1000,2000 <br />
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <Paper style={styles.positionsTable}>
                <Table>
                    <TableBody>
                        <AddPositionRow
                            groups={groups}
                            units={units}
                            addPosition={addPosition}
                        />
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell>Группа</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Марка</TableCell>
                            <TableCell>Ед. изм.</TableCell>
                            <TableCell>Размер ТЕ</TableCell>
                            <TableCell>Цена за ТЕ</TableCell>
                            <TableCell>Упаковка</TableCell>
                            <TableCell>Примечание поставщика</TableCell>
                            <TableCell>Статус позиции</TableCell>
                            <TableCell style={{ minWidth: '100px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {positions.map(position => (
                            <OfferPositionRow
                                key={position.id}
                                position={position}
                                groups={groups}
                                units={units}
                                editPosition={editPosition}
                                deletePosition={deletePosition}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Fragment>
    );
}

OfferPositionsTable.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.object).isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPosition: PropTypes.func.isRequired,
    editPosition: PropTypes.func.isRequired,
    deletePosition: PropTypes.func.isRequired,
};
