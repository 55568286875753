import React, { Component, Fragment } from 'react';
import { IconButton, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import AddFrequencyRow from './AddFrequencyRow';
import TableCell from '../common/TableCell';

export default class DemandFrequencyRow extends Component {
    state = {
        editing: false,
    };

    static propTypes = {
        frequency: PropTypes.PropTypes.shape({
            selectedType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            selectedPosition: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            frequency: PropTypes.number,
        }).isRequired,
        positions: PropTypes.arrayOf(PropTypes.object).isRequired,
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        frequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
        editFrequency: PropTypes.func.isRequired,
        deleteFrequency: PropTypes.func.isRequired,
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { editing } = this.state;
        const { frequency } = this.props;
        if (isEqual(frequency, nextProps.frequency) && editing === nextState.editing) {
            return false;
        }
        return true;
    }

    edit = (type, position, frequency) => {
        const { editFrequency } = this.props;
        const result = editFrequency(position, frequency);
        if (result === 'ERROR') return;
        this.setState({ editing: false });
    };

    render() {
        const { editing } = this.state;
        const {
            frequency, positions, groups, frequencies, deleteFrequency,
        } = this.props;
        return (
            <Fragment>
                {editing
                    ? (
                        <AddFrequencyRow
                            disabled
                            frequency={frequency}
                            positions={positions}
                            groups={groups}
                            frequencies={frequencies}
                            addFrequency={this.edit}
                        />
                    )
                    : (
                        <TableRow style={{ height: '50px' }}>
                            <TableCell>{frequency.type}</TableCell>
                            <TableCell>{frequency.name}</TableCell>
                            <TableCell>{frequency.frequency}</TableCell>
                            <TableCell>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={() => deleteFrequency(frequency)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="Edit"
                                    onClick={() => this.setState({ editing: true })}
                                >
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                }
            </Fragment>
        );
    }
}
