import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

export default function AdminRoute({ component: Component, ...rest }) {
    const role = localStorage.getItem('role');
    return (
        <Route render={props => (role === 'admin'
            ? <Component {...props} {...rest} />
            : <Redirect to="/login" />)}
        />
    );
}

AdminRoute.propTypes = {
    component: PropTypes.func.isRequired,
};
