import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const updateDayOrdersData = async (data, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: '/api/day_orders/update_day_orders_data/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const updateDayOrder = async (data, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/day_orders/${data.id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};
