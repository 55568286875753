const selectStyles = {
    option: (provided, state) => ({
        ...provided,
        width: '270px',
    }),
    container: base => ({
        ...base,
        width: '270px',
    }),
};

export default selectStyles;
