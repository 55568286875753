import React from 'react';
import {
    Paper, Table, TableHead, TableBody, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import CheckListRow from './CheckListRow';
import styles from '../styles';

function CheckListTable({ checkLists, date }) {
    return (
        <Paper style={{ ...styles.positionsTable, width: '900px' }}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            Название ресторана
                        </TableCell>
                        {/* <TableCell>
                            Статус
                        </TableCell> */}
                        <TableCell style={{ minWidth: '40px' }} />
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {checkLists.map(checkList => (
                        <CheckListRow
                            key={checkList.id}
                            id={checkList.id}
                            restaurant={checkList.restaurant}
                            status={checkList.status}
                            isViewed={checkList.is_viewed}
                            date={date}
                        />
                    ))}
                </TableBody>
            </Table>
        </Paper>

    );
}

CheckListTable.propTypes = {
    checkLists: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.number,
            demand: PropTypes.number,
            restaurant: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            // status: PropTypes.string.isRequired,
        }),
    ).isRequired,
    date: PropTypes.string.isRequired,
};

export default CheckListTable;
