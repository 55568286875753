import React, { Component } from 'react';
import { TableRow, TextField, Button } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import styles from '../styles';

function isValidNumber(s) {
    if (s === '' || s === ' ' || parseFloat(s) === 0) {
        return true;
    }
    return !Number.isNaN(parseFloat(s)) && parseFloat(s) !== 0;
}

export default class AddPositionRow extends Component {
    state = {
        id: null,
        selectedGroup: null,
        name: '',
        mark: '',
        selectedUnit: null,
        unitSize: '',
        pack: '',
        unitPrice: '',
        comment: '',
        status: '',
        packErr: false,
        priceErr: false,
    };

    componentDidMount() {
        const { position } = this.props;
        if (position) {
            this.setState({
                id: position.id,
                selectedGroup: {
                    id: position.group.id,
                    name: position.group.name,
                },
                name: position.name,
                mark: position.mark,
                selectedUnit: {
                    id: position.unit.id,
                    name: position.unit.name,
                },
                unitSize: position.unit_size,
                pack: position.pack.toString(),
                unitPrice: position.unit_price.toString(),
                comment: position.comment,
                status: position.status,
            });
        }
    }


    getGroupsOptions() {
        const { groups } = this.props;

        if (groups === null) {
            return [];
        }

        return groups.map(group => ({
            value: group.id,
            label: group.name,
        }));
    }

    getSelectedGroup() {
        const { selectedGroup } = this.state;

        if (selectedGroup === null) {
            return null;
        }

        return {
            value: selectedGroup.id,
            label: selectedGroup.name,
        };
    }

    getUnitsOptions() {
        const { units } = this.props;
        if (units === null) {
            return [];
        }

        return units.map(unit => ({
            value: unit.id,
            label: unit.name,
        }));
    }

    getSelectedUnit() {
        const { selectedUnit } = this.state;

        if (selectedUnit === null) {
            return null;
        }

        return {
            value: selectedUnit.id,
            label: selectedUnit.name,
        };
    }

    selectGroup = (selected) => {
        this.setState({
            selectedGroup: {
                id: selected.value,
                name: selected.label,
            },
        });
    };

    selectUnit = (selected) => {
        this.setState({
            selectedUnit: {
                id: selected.value,
                name: selected.label,
            },
        });
    };

    save = () => {
        const { addPosition } = this.props;
        addPosition(this.state);
    };

    handleTextChange = name => (e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    onPriceChanged = (e) => {
        const { value } = e.target;
        const unitPrice = value.split(',');
        const error = unitPrice.some(el => (!isValidNumber(el)));
        if (error) {
            this.setState({ priceErr: true });
            return null;
        }

        this.setState({
            unitPrice: value,
            priceErr: false,
        });
        return null;
    };

    onPackChanged = (e) => {
        const { value } = e.target;
        const pack = value.split(',');
        const error = pack.some(el => (!isValidNumber(el)));
        if (error) {
            this.setState({ packErr: true });
            return null;
        }

        this.setState({
            pack: value,
            packErr: false,
        });
        return null;
    };

    render() {
        const {
            name, mark, unitSize, pack, unitPrice, packErr, priceErr, comment, status,
        } = this.state;
        const { disabled } = this.props;
        return (
            <TableRow style={{ height: '50px' }}>
                <TableCell>
                    <Select
                        isDisabled={disabled}
                        placeholder="Группа"
                        style={{ width: '200px' }}
                        value={this.getSelectedGroup()}
                        options={this.getGroupsOptions()}
                        onChange={this.selectGroup}
                        menuPortalTarget={document.querySelector('body')}
                        menuPlacement="top"
                    />
                </TableCell>
                <TableCell style={{ width: '250px' }}>
                    <TextField
                        style={styles.addPositionTextField}
                        disabled={disabled}
                        placeholder="Наименование"
                        value={name}
                        onChange={this.handleTextChange('name')}
                    />
                </TableCell>
                <TableCell style={{ width: '250px' }}>
                    <TextField
                        style={styles.addPositionTextField}
                        value={mark}
                        placeholder="Марка"
                        onChange={this.handleTextChange('mark')}
                    />
                </TableCell>
                <TableCell>
                    <Select
                        placeholder="ед. изм."
                        value={this.getSelectedUnit()}
                        options={this.getUnitsOptions()}
                        onChange={this.selectUnit}
                        menuPortalTarget={document.querySelector('body')}
                        menuPlacement="top"
                    />
                </TableCell>
                <TableCell style={{ minWidth: '200px' }}>
                    <TextField
                        style={styles.addPositionTextField}
                        id="unitSize"
                        placeholder="Размер ТЕ"
                        value={unitSize}
                        onChange={this.handleTextChange('unitSize')}
                    />
                </TableCell>
                <TableCell style={{ minWidth: '200px' }}>
                    <TextField
                        style={styles.addPositionTextField}
                        id="unitPrice"
                        placeholder="Цена за ТЕ"
                        error={priceErr}
                        value={unitPrice}
                        onChange={this.onPriceChanged}
                    />
                </TableCell>
                <TableCell style={{ minWidth: '200px' }}>
                    <TextField
                        style={styles.addPositionTextField}
                        id="pack"
                        placeholder="Упаковка"
                        error={packErr}
                        value={pack}
                        onChange={this.onPackChanged}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        style={styles.addPositionTextField}
                        placeholder="Примечания поставщика"
                        value={comment}
                        onChange={this.handleTextChange('comment')}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        style={styles.addPositionTextField}
                        placeholder="Статус позиции"
                        value={status}
                        onChange={this.handleTextChange('status')}
                    />
                </TableCell>
                <TableCell style={{ minWidth: '100px' }}>
                    <Button
                        style={styles.tableButton}
                        variant="contained"
                        color="primary"
                        onClick={this.save}
                    >
                        {disabled ? 'Сохранить' : 'Добавить'}
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}

AddPositionRow.defaultProps = {
    position: null,
    disabled: false,
};

AddPositionRow.propTypes = {
    position: PropTypes.shape({
        id: PropTypes.number,
        selectedGroup: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        name: PropTypes.string,
        mark: PropTypes.string,
        selectedUnit: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        unit_size: PropTypes.number,
        pack: PropTypes.arrayOf(PropTypes.number),
        unit_price: PropTypes.arrayOf(PropTypes.number),
        comment: PropTypes.string,
    }),
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPosition: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};
