import React from 'react';
import {
    Dialog, Button, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default function InfoDialog({
    dialogTitle, dialogText, open, onClose,
}) {
    function title() {
        if (typeof (dialogTitle) !== 'string') {
            return 'Произошла ошибка';
        }
        return dialogTitle;
    }

    return (
        <Dialog
            open={open}
            onBackdropClick={onClose}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{title()}</DialogTitle>
            <DialogContent className="dialog">
                <pre>
                    <DialogContentText>
                        {dialogText}
                    </DialogContentText>
                </pre>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onClose}
                >
                    ОК
                </Button>
            </DialogActions>
        </Dialog>
    );
}

InfoDialog.defaultProps = {
    dialogText: '',
};

InfoDialog.propTypes = {
    dialogTitle: PropTypes.string.isRequired,
    dialogText: PropTypes.string,
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
