import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadRestaurants = async (cancelToken) => {
    const res = await axios({
        url: '/api/restaurants/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addRestaurant = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/restaurants/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const loadRestaurantForCheckLists = async (cancelToken) => {
    const res = await axios({
        url: '/api/restaurants/check_list/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
