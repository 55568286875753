import React, { Component, Fragment } from 'react';
import {
    IconButton, Table, TableBody, TableRow,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import AddPositionRow from './AddPositionRow';
import TableCell from '../common/TableCell';
import { round2 } from '../common/utils';

export default class OfferPositionRow extends Component {
    state = {
        editing: false,
    };

    static propTypes = {
        position: PropTypes.shape({
            id: PropTypes.number,
            selectedGroup: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            name: PropTypes.string,
            mark: PropTypes.string,
            selectedUnit: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            unit_size: PropTypes.number,
            pack: PropTypes.arrayOf(PropTypes.number),
            unit_price: PropTypes.arrayOf(PropTypes.number),
            comment: PropTypes.string,
        }).isRequired,
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        units: PropTypes.arrayOf(PropTypes.object).isRequired,
        editPosition: PropTypes.func.isRequired,
        deletePosition: PropTypes.func.isRequired,
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { editing } = this.state;
        const { position } = this.props;
        if (isEqual(position, nextProps.position) && editing === nextState.editing) {
            return false;
        }
        return true;
    }


    editPosition = async (position) => {
        const { editPosition } = this.props;
        const result = await editPosition(position);
        if (result === 'ERROR') return;
        this.setState({ editing: false });
    };

    render() {
        const { editing } = this.state;
        const {
            position, groups, units, deletePosition,
        } = this.props;
        return (
            <Fragment>
                {
                    editing
                        ? (
                            <AddPositionRow
                                disabled
                                position={position}
                                groups={groups}
                                units={units}
                                addPosition={this.editPosition}
                            />
                        )
                        : (
                            <TableRow key={position.id}>
                                <TableCell> {position.group.name} </TableCell>
                                <TableCell> {position.name} </TableCell>
                                <TableCell> {position.mark} </TableCell>
                                <TableCell> {position.unit.name} </TableCell>
                                <TableCell> {position.unit_size} </TableCell>
                                <TableCell>
                                    {position.unit_price.map(el => (
                                        <Table key={position.name + el}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{round2(el)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell>
                                <TableCell>
                                    {position.pack.map(el => (
                                        <Table key={position.name + el}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{round2(el)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell>
                                {/* <TableCell>
                                    {position.pricePerPack.map((el, idx) => (
                                        <Table key={idx}>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>{round2(el)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    ))}
                                </TableCell> */}
                                <TableCell> {position.comment} </TableCell>
                                <TableCell> {position.status} </TableCell>
                                <TableCell style={{ minWidth: '100px' }}>
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => deletePosition(position)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={() => this.setState({ editing: true })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                }
            </Fragment>
        );
    }
}
