import React from 'react';
import withAuth from '../withAuth';
import BaseObject from '../common/BaseObject';

function Groups() {
    return (
        <BaseObject
            baseUrl="position_groups"
            title="Группы товаров для закупок"
        />
    );
}

export default withAuth(Groups);
