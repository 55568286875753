import React, { Component, Fragment } from 'react';
import {
    Paper, Tabs, Tab, Table, TableBody, TableRow, TableHead,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import DayTab from './DayTab';
import styles from '../styles';

export default class PriceAnalysisTable extends Component {
    state = {
        selected: 0,
    };

    static propTypes = {
        order: PropTypes.shape({
            id: PropTypes.number,
            check_list: PropTypes.number,
            day_orders: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                data: PropTypes.array,
                date: PropTypes.string,
                order: PropTypes.number,
                status: PropTypes.string,
            })),
            week_order: PropTypes.shape({
                data: PropTypes.array,
                date: PropTypes.string,
                order: PropTypes.number,
            }),
            not_purchased_positions: PropTypes.objectOf(PropTypes.array),
            not_passed_min_price: PropTypes.objectOf(PropTypes.array),
        }).isRequired,
        recalculate: PropTypes.func.isRequired,
    };

    handleChange = (event, value) => {
        this.setState({
            selected: value,
        });
    };

    getMessage = (PreferredInfo, UniqueInfo) => {
        let preferredStr = '';
        let uniqueStr = '';
        if (PreferredInfo.length === 1) {
            preferredStr = `Содержит приоритетную позицию ${PreferredInfo}. `
        } else if (PreferredInfo.length > 1) {
            preferredStr = `Содержит приоритетные позиции ${PreferredInfo.join(', ')}. `
        }

        if (UniqueInfo.length === 1) {
            uniqueStr = `Содержит уникальную позицию ${UniqueInfo}. `
        } else if (PreferredInfo.length > 1) {
            uniqueStr = `Содержит уникальные позиции ${UniqueInfo.join(', ')}. `
        }

        return preferredStr + uniqueStr
    };

    render() {
        const { selected } = this.state;
        const { order, recalculate } = this.props;

        return (
            <Fragment>
                {order.day_orders[0]
                    && order.day_orders[0].length !== 0
                    && (
                        <Tabs
                            value={selected}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab
                                style={{ display: 'block', margin: '0 auto' }}
                                value={0}
                                label="Закупка на неделю"
                            />
                            {order.day_orders.length > 1
                                && order.day_orders
                                    .map((order, idx) => (
                                        <Tab
                                            style={{ display: 'block', margin: '0 auto' }}
                                            key={idx}
                                            value={idx + 1}
                                            label={order.date}
                                        />
                                    ))
                            }
                        </Tabs>
                    )
                }

                {order.week_order.data
                    && order.week_order.data !== 0
                    && selected === 0
                    && (
                        <DayTab
                            tabType="week"
                            order={order.week_order}
                            recalculate={recalculate}
                        />
                    )
                }

                {order.day_orders
                    .filter((order, index) => (index) === selected - 1)
                    .map((order, index) => (
                        <DayTab
                            key={index}
                            tabType="day"
                            order={order}
                            recalculate={recalculate}
                        />
                    ))
                }
                {order.not_passed_min_price.by_grand_total.length !== 0
                    && (
                        <Fragment>
                            <h5 style={{ margin: '15px 0 0 10px' }}>
                                Поставщики, которые не могут преодолеть порог
                            </h5>
                            <Paper style={styles.positionsTable}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Поставщик </TableCell>
                                            <TableCell> Дата заказа </TableCell>
                                            <TableCell> Дополнительно </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.not_passed_min_price.by_grand_total.map((msg, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{msg.name}</TableCell>
                                                <TableCell>{msg.date}</TableCell>
                                                <TableCell>{this.getMessage(msg.preferred, msg.unique)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Fragment>
                    )}
                    {order.not_passed_min_price.actual.length !== 0
                    && (
                        <Fragment>
                            <h5 style={{ margin: '15px 0 0 10px' }}>
                                Поставщики, которым не удалось преодолеть порог
                            </h5>
                            <Paper style={styles.positionsTable}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell> Поставщик </TableCell>
                                            <TableCell> Дата заказа </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {order.not_passed_min_price.actual.map((msg, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{msg.name}</TableCell>
                                                <TableCell>{msg.date}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Fragment>
                    )}
                {order.not_purchased_positions.no_salesman.length !== 0
                    && (
                        <Fragment>
                            <h5 style={{ margin: '15px 0 0 10px' }}>
                                Позиции, отсутствующие у продавцов:
                            </h5>
                            <Paper style={styles.positionsTable}>
                                <Table>
                                    <TableBody>
                                        {order.not_purchased_positions.no_salesman.map((position, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{position}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Fragment>
                    )}
                {order.not_purchased_positions.no_packs.length !== 0
                    && (
                        <Fragment>
                            <h5 style={{ margin: '15px 0 0 10px' }}>
                                Позиции без подходящих упаковок у продавцов:
                            </h5>
                            <Paper style={styles.positionsTable}>
                                <Table>
                                    <TableBody>
                                        {order.not_purchased_positions.no_packs.map((position, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{position}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Fragment>
                    )}
            </Fragment>
        );
    }
}
