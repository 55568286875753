import React from 'react';
import { Button, TextField, Typography } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';

import styles from '../styles';

const MONDAY = { value: 0, label: 'Понедельник' };
const TUESDAY = { value: 1, label: 'Вторник' };
const WEDNESDAY = { value: 2, label: 'Среда' };
const THURSDAY = { value: 3, label: 'Четверг' };
const FRIDAY = { value: 4, label: 'Пятница' };
const SATURDAY = { value: 5, label: 'Суббота' };
const SUNDAY = { value: 6, label: 'Воскресенье' };
const WEEKDAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY, SUNDAY];

function GeneralSettings({ settings, changeSettingsValue, onUpdateSettings }) {
    function parseErrorRate(value) {
        let parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
            parsedValue = '';
        }
        if (parsedValue > 2) {
            parsedValue = 2;
        }
        return parsedValue;
    }

    return (
        <div style={{ ...styles.pageBlock, alignItems: 'center', justifyContent: 'space-around' }}>
            <div style={{ marginRight: '10px' }}>
                <Typography
                    style={{ marginBottom: '10px' }}
                    variant="h6"
                >
                    Первый день недели
                </Typography>
                <Select
                    styles={styles.selectField}
                    value={WEEKDAYS[settings.first_day_of_the_week]}
                    options={WEEKDAYS}
                    onChange={day => changeSettingsValue(day.value, 'first_day_of_the_week')}
                />
            </div>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
                <Typography
                    style={{ marginBottom: '10px' }}
                    variant="h6"
                >
                    Коэффициент погрешности
                </Typography>
                <TextField
                    placeholder="Введите коэффициент"
                    type="number"
                    inputProps={{ min: '0', max: '2', step: '0.01' }}
                    value={settings.error_rate}
                    onChange={e => changeSettingsValue(parseErrorRate(e.target.value), 'error_rate')}
                />
            </div>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
                <Typography
                    style={{ marginBottom: '10px' }}
                    variant="h6"
                >
                    Время закрытия редактирования
                </Typography>
                <TextField
                    placeholder="Введите время"
                    type="time"
                    inputProps={{ step: 60 }}
                    value={settings.check_list_time}
                    onChange={e => changeSettingsValue(e.target.value, 'check_list_time')}
                />
            </div>
            <div style={{ marginBottom: '10px', marginRight: '10px' }}>
                <Typography
                    style={{ marginBottom: '10px' }}
                    variant="h6"
                >
                    Ссылка на документ по умолчанию
                </Typography>
                <TextField
                    style={{ width: '890px' }}
                    placeholder="Введите ссылку"
                    type="url"
                    value={settings.default_import_link}
                    onChange={e => changeSettingsValue(e.target.value, 'default_import_link')}
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '900px',
                }}
            >
                <Button
                    style={styles.regularButton}
                    color="primary"
                    variant="contained"
                    onClick={onUpdateSettings}
                >
                    Сохранить
                </Button>
            </div>
        </div>

    );
}

GeneralSettings.propTypes = {
    settings: PropTypes.shape({
        id: PropTypes.number,
        first_day_of_the_week: PropTypes.number,
        error_rate: PropTypes.number,
    }).isRequired,
    changeSettingsValue: PropTypes.func.isRequired,
    onUpdateSettings: PropTypes.func.isRequired,
};

export default GeneralSettings;
