import React, { Component, Fragment } from 'react';
import {
    Button, CircularProgress, TextField, Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import propTypes from 'prop-types';
import axios from 'axios';
import parseErrors from './ErrorParsing';
import { withAuthHeader } from '../Auth';
import styles from '../styles';

export default class ImportExportControl extends Component {
    signal = axios.CancelToken.source()

    state = {
        loading: false,
        success: false,
    };

    static defaultProps = {
        link: '',
        defaultLink: '',
        disableButton: false,
        onImport: null,
    };

    static propTypes = {
        link: propTypes.string,
        defaultLink: propTypes.string,
        tableId: propTypes.string.isRequired,
        disableButton: propTypes.bool,
        onImport: propTypes.func,
        handleExport: propTypes.func.isRequired,
        handleLinkChange: propTypes.func.isRequired,
        errorDialog: propTypes.func.isRequired,
    };

    componentWillUnmount() {
        clearTimeout(this.timer);
        this.signal.cancel();
    }

    saveLink = async () => {
        const { link, tableId } = this.props;
        try {
            this.setState({
                success: false,
                loading: true,
            });

            await axios({
                method: 'patch',
                url: `/api/${tableId}/`,
                data: { gsheet_link: link },
                headers: withAuthHeader(),
                cancelToken: this.signal.token,
            });
            this.timer = setTimeout(() => {
                this.setState({
                    loading: false,
                    success: true,
                });
            }, 1000);
            this.timer = setTimeout(() => {
                this.setState({
                    success: false,
                });
            }, 3000);
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { errorDialog } = this.props;
            parseErrors(errorDialog, 'Произошла ошибка при сохранении ссылки', err);
            this.setState({ loading: false });
        }
    };

    handleImportClick = () => {
        const { link, errorDialog, onImport } = this.props;
        if (link === '') {
            errorDialog('При импорте произошла ошибка', 'Вы не ввели ссылку на таблицу');
            return;
        }
        onImport('regular_import');
    };

    handleExportClick = () => {
        const { link, errorDialog, handleExport } = this.props;
        if (link === '') {
            errorDialog('При экспорте произошла ошибка', 'Вы не ввели ссылку на таблицу');
            return;
        }
        handleExport(link);
    };

    render() {
        const { loading, success } = this.state;
        const {
            link, defaultLink, onImport, handleLinkChange, disableButton, tableId,
        } = this.props;
        return (
            <div style={{ ...styles.pageBlock, alignItems: 'center' }}>
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <TextField
                        style={{ margin: '0 10px 10px 0' }}
                        value={link}
                        onChange={e => handleLinkChange(e.target.value)}
                        onBlur={this.saveLink}
                        helperText="Ссылка на таблицу с открытым доступом для редактирования"
                        disabled={disableButton}
                    />
                    {success
                        && (
                            <CheckIcon style={{ margin: '0px 3px 0 -5px', width: '27px' }} color="primary" />
                        )}
                    {loading
                        && (
                            <CircularProgress
                                style={{ margin: '0px 5px 0 0px', width: '20px' }}
                                size={20}
                            />
                        )}
                </div>
                <div style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                }}
                >
                    {onImport
                        && (
                            <Fragment>
                                {tableId.includes('demand')
                                && (
                                    <Tooltip
                                        title={defaultLink ? 'Импортировать данные из Google таблицы по умолчанию' : 'Не заполнена ссылка по умолчанию'}
                                        aria-label="Import from default Google sheet"
                                    >
                                        <div>
                                            <Button
                                                disabled={!defaultLink || disableButton}
                                                style={styles.wideButton}
                                                onClick={() => onImport('default_import')}
                                                variant="contained"
                                                color="primary"
                                            >
                                            Импорт из документа по умолчанию
                                            </Button>
                                        </div>
                                    </Tooltip>
                                )
                                }

                                <Tooltip
                                    title="Импортировать данные из Google таблицы"
                                    aria-label="Import from Google sheets"
                                >
                                    <div>
                                        <Button
                                            // icon={<NavigationArrowDownward/>}
                                            disabled={disableButton}
                                            style={styles.wideButton}
                                            onClick={this.handleImportClick}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Импорт из GoogleSheets
                                        </Button>
                                    </div>
                                </Tooltip>
                            </Fragment>
                        )
                    }
                    <Tooltip
                        title="Экспортировать данные в Google таблицу"
                        aria-label="Export to Google sheets"
                    >
                        <div>
                            <Button
                                // icon={<NavigationArrowUpward/>}
                                disabled={disableButton}
                                style={styles.wideButton}
                                onClick={this.handleExportClick}
                                variant="contained"
                                color="primary"
                            >
                                Экспорт в GoogleSheets
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            </div>
        );
    }
}
