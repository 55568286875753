import React from 'react';
import ReactDOM from 'react-dom';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import * as Sentry from '@sentry/browser';
import Router from './Router';
import './utils/polyfill';

const { REACT_APP_ENV } = process.env;

if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'staging') {
    Sentry.init({
        dsn: 'https://221330e4f310499f92f7846e01499065@sentry.io/1246282',
        environment: REACT_APP_ENV,
    });
}


ReactDOM.render(
    <MuiThemeProvider>
        <Router />
    </MuiThemeProvider>,
    document.getElementById('root'),
);
