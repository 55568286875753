import React, { Component } from 'react';
import {
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import Creatable from 'react-select/creatable';
import PropTypes from 'prop-types';
import axios from 'axios';
import parseErrors from './ErrorParsing';
import { loadFilterOptions, createFilter } from '../API_requests/FiltersAPI';


const isValidNewOption = (inputValue, selectValue, selectOptions) => {
    const isNotDuplicated = !selectOptions.map(option => option.label).includes(inputValue);
    const isNotEmpty = inputValue !== '';
    return isNotEmpty && isNotDuplicated;
};

const createOption = filter => ({
    label: filter.name,
    value: filter.id,
});

class ImportFilterDialog extends Component {
    signal = axios.CancelToken.source()

    state = {
        filterOptions: null,
        selectedFilters: [],
    };

    static defaultProps = {
        filterText: '',
        importType: null,
    };

    static propTypes = {
        open: PropTypes.bool.isRequired,
        filterTitle: PropTypes.string.isRequired,
        filterText: PropTypes.string,
        onContinue: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        type: PropTypes.string.isRequired,
        importType: PropTypes.string,
        showError: PropTypes.func.isRequired,

    };

    componentDidMount() {
        this.loadFilterOptions();
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    loadFilterOptions = async () => {
        try {
            const { type } = this.props;
            const res = await loadFilterOptions(type, this.signal.token);
            const filterOptions = res.data.map(filter => createOption(filter));
            this.setState({ filterOptions });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { showError } = this.props;
            parseErrors(showError, 'Произошла ошибка при загрузке фильтров', err);
        }
    };

    createFilter = async (inputValue) => {
        try {
            const { type } = this.props;
            const { filterOptions, selectedFilters } = this.state;

            const data = {
                name: inputValue,
                type,
            };
            const res = await createFilter(data, this.signal.token);
            const newOption = createOption(res.data);

            this.setState({
                filterOptions: [...filterOptions, newOption],
                selectedFilters: [...selectedFilters, newOption],
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { showError } = this.props;
            parseErrors(showError, 'Произошла ошибка при создании фильтра', err);
        }
    };

    onContinue = () => {
        const { selectedFilters } = this.state;
        const { onContinue, importType } = this.props;
        onContinue(importType, selectedFilters);
    };

    render() {
        const { filterOptions, selectedFilters } = this.state;
        const {
            open, filterTitle, filterText, onCancel,
        } = this.props;
        return (
            <Dialog
                open={open}
                onBackdropClick={onCancel}
                scroll="body"
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>{filterTitle}</DialogTitle>
                <DialogContent
                    style={{ height: '250px' }}
                    className="dialog"
                >
                    <DialogContentText>
                        {filterText}
                    </DialogContentText>
                    <Creatable
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue=""
                        placeholder="Выберите фильтры для импорта"
                        isMulti
                        isValidNewOption={isValidNewOption}
                        // isLoading={isLoading}
                        maxMenuHeight={170}
                        isClearable
                        name="filters"
                        value={selectedFilters}
                        onChange={selected => this.setState({ selectedFilters: selected })}
                        onCreateOption={this.createFilter}
                        options={filterOptions}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onContinue}
                    >
                        Сохранить
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                    >
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default (ImportFilterDialog);
