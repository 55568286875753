import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import moment from 'moment';
import App from '../App';
import DatePicker from '../common/DatePicker';
import CheckListTable from './CheckListTable';
import SelectEditedCheckList from './SelectEditedCheckList';
import InfoDialog from '../common/InfoDialog';
import parseErrors from '../common/ErrorParsing';
import { loadCheckLists, loadEditedCheckLists } from '../API_requests/CheckListsAPI';
import styles from '../styles';

export default class CheckLists extends Component {
    signal = axios.CancelToken.source();

    state = {
        checkLists: null,
        editedCheckLists: [],
        date: null,
        dateFormatted: null,
        errorDialogOpen: false,
        errorText: '',
        errorTitle: '',
    };

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    }

    componentDidMount() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const dateFormatted = params.get('date');
        this.setDate(dateFormatted);
        this.getEditedCheckLists();
    }

    onLoadCheckLists = async (dateFormatted) => {
        try {
            const res = await loadCheckLists(dateFormatted, this.signal.token);
            const checkLists = res.data;
            this.setState({
                checkLists: checkLists.filter(checkList => this.filterCheckLists(checkList)),
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.setState({
                checkLists: [],
            });
            parseErrors(this.showError, 'Произошла ошибка при загрузке чек-листов', err);
        }
    }

    setDate(dateFormatted) {
        const storageValues = {};
        if (dateFormatted !== null) {
            storageValues.dateFormatted = dateFormatted;
            storageValues.date = moment(dateFormatted);
        }
        if (Object.keys(storageValues).length > 0 && storageValues.date.isValid()) {
            this.setState(storageValues, () => this.onLoadCheckLists(dateFormatted));
        }
    }

    onDateSelected = (e) => {
        const { date } = this.state;
        if (!e || date === e) {
            return;
        }

        const dateFormatted = e.format('YYYY-MM-DD');
        const { history } = this.props;
        const queryParams = new URLSearchParams({ date: dateFormatted }).toString();
        history.push(`/check_lists?${queryParams}`);
        this.setState({
            date: e,
            dateFormatted,
        }, () => {
            this.onLoadCheckLists(dateFormatted);
        });
    };

    getEditedCheckLists = async () => {
        try {
            const res = await loadEditedCheckLists(this.signal.token);
            this.setState({ editedCheckLists: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке отредактированных чек-листов', err);
        }
    }

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    filterCheckLists(checkList) {
        const result = Object.keys(checkList.order.week_order).length === 0
            && checkList.order.not_purchased_positions.no_packs.length === 0
            && checkList.order.not_purchased_positions.no_salesman.length === 0;
        return !result;
    }

    render() {
        const {
            checkLists, editedCheckLists, date, dateFormatted, errorDialogOpen, errorText, errorTitle,
        } = this.state;
        return (
            <Fragment>
                <App />
                <div style={styles.pageBlock}>
                    <InfoDialog
                        open={errorDialogOpen}
                        dialogText={errorText}
                        dialogTitle={errorTitle}
                        onClose={() => this.setState({ errorDialogOpen: false })}
                    />
                    <Typography style={styles.pageHeader} variant="h4">
                        Чек-листы
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'baseline' }}>
                        <DatePicker
                            tableName="check_lists"
                            date={date}
                            onDateSelected={this.onDateSelected}
                            showError={this.showError}
                        />
                        {editedCheckLists.length > 0
                            && (
                                <SelectEditedCheckList
                                    editedCheckLists={editedCheckLists}
                                    onDateSelected={this.onDateSelected}
                                />
                            )
                        }
                    </div>
                    {checkLists
                        ? (
                            <Fragment>
                                {checkLists.length === 0
                                    ? (
                                        <Paper
                                            style={{
                                                marginTop: '40px',
                                                width: '800px',
                                                padding: '15px',
                                                textAlign: 'center',
                                                display: 'block',
                                                marginLeft: 'auto',
                                                marginRight: 'auto',
                                            }}
                                        >
                                            Нет чек-листов за выбранную неделю
                                        </Paper>
                                    )
                                    : (
                                        <CheckListTable
                                            checkLists={checkLists}
                                            date={dateFormatted}
                                        />
                                    )
                                }
                            </Fragment>
                        )
                        : null
                    }
                </div>
            </Fragment>
        );
    }
}
