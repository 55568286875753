import React, { Component } from 'react';
import { Button, TableRow, TableCell } from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from 'react-select';
import styles from '../styles';
import SettingsSalesman from './SettingsSalesman';

export default class AddPreferredSalesmenRow extends Component {
    state = {
        selectedSalesman: null,
        selectedPosition: null,
        id: null,
        options: [],
    }

    componentDidMount() {
        const { position } = this.props;
        if (position) {
            this.setState({
                selectedPosition: position.position,
                id: position.id,
                selectedSalesman:
                {
                    id: position.salesman,
                    name: position.salesman_name,
                },
            }, this.getOptions);
        }
    }

    onSalesmanSelected = (salesman) => {
        const { selectedSalesman } = this.state;
        if (salesman === selectedSalesman) {
            return;
        }

        this.setState({
            selectedSalesman: salesman,
        }, this.getOptions);
    }

    getOptions = () => {
        const { selectedSalesman, selectedPosition } = this.state;
        const { offerPositions } = this.props;
        const options = offerPositions.filter(pos => pos.salesman_id === selectedSalesman.id).map(pos => ({ value: pos.name, label: pos.name }));
        this.setState({ options });
        if (selectedPosition) {
            const res = options.find(opt => opt.value === selectedPosition);
            if (!res) {
                this.setState({ selectedPosition: null });
            }
        }
    }

    onPositionSelected = (position) => {
        const { selectedPosition } = this.state;
        if (position === selectedPosition) {
            return;
        }

        this.setState({
            selectedPosition: position.label,
        });
    }

    getSelectedPosition = () => {
        const { selectedPosition } = this.state;
        if (!selectedPosition) {
            return null;
        }
        return {
            value: selectedPosition,
            label: selectedPosition,
        };
    };

    save = () => {
        const { addPosition } = this.props;
        this.setState({ selectedPosition: null });
        addPosition(this.state);
    };

    render() {
        const { salesmen, editing } = this.props;
        const { selectedSalesman, options } = this.state;
        return (
            <TableRow style={{ height: '50px' }}>
                <TableCell style={{ width: '250px' }}>
                    <SettingsSalesman
                        salesmen={salesmen}
                        selectedSalesman={selectedSalesman}
                        onSalesmanSelected={salesman => this.onSalesmanSelected(salesman)
                        }
                    />
                </TableCell>
                <TableCell style={{ width: '300px' }}>
                    <Select
                        placeholder="Товар"
                        value={this.getSelectedPosition()}
                        options={options}
                        onChange={this.onPositionSelected}
                        menuPlacement="top"
                        menuPortalTarget={document.querySelector('body')}
                    />
                </TableCell>
                <TableCell style={{ minWidth: '100px' }}>
                    <Button
                        style={styles.tableButton}
                        variant="contained"
                        color="primary"
                        onClick={this.save}
                    >
                        { editing ? 'Сохранить' : 'Добавить' }
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}


const salesman = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    min_price: PropTypes.number,
    sort_order: PropTypes.number,
});

AddPreferredSalesmenRow.propTypes = {
    salesmen: PropTypes.arrayOf(salesman).isRequired,
    offerPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPosition: PropTypes.func.isRequired,
    position: PropTypes.shape({
        id: PropTypes.number,
        salesman_name: PropTypes.string,
        position: PropTypes.string,
    }),
    editing: PropTypes.bool,
};

AddPreferredSalesmenRow.defaultProps = {
    position: null,
    editing: false,
};
