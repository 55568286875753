// import axios from '../common/api_config';
import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadDeliveryDates = async (data, cancelToken) => {
    const res = await axios({
        url: '/api/delivery_dates/',
        params: data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const saveDeilveryDates = async (data, cancelToken) => {
    const res = await axios({
        method: 'put',
        url: '/api/delivery_dates/save_delivery_dates/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};
