import React, { Fragment, memo } from 'react';
import {
    Paper, Table, TableBody, TableHead, TableRow, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import { round2 } from '../common/utils';
import styles from '../styles';

function DayTab({
    tabType, order, recalculate,
}) {
    function onRecalculate(salesman) {
        if (tabType === 'week') {
            recalculate(salesman, null);
        } else if (tabType === 'day') {
            recalculate(salesman, order.date);
        }
    }

    function sortPositions(nextPos, pos) {
        if (nextPos.sort_order < pos.sort_order) {
            return -1;
        }
        if (nextPos.sort_order > pos.sort_order) {
            return 1;
        }
        return 0;
    }

    function getSortedPositions(data) {
        const sortedPositions = data.flatMap(salesman => salesman.positions).sort(sortPositions);

        return sortedPositions.map((position, index) => (
            <TableRow key={`${position.sort_order} ${position.salesman_name}`}>
                <TableCell>{position.group.name}</TableCell>
                <TableCell>{position.name}</TableCell>
                <TableCell>{position.mark}</TableCell>
                <TableCell>{position.unit.name}</TableCell>
                <TableCell>{position.unit_size}</TableCell>
                <TableCell>{round2(position.unit_price)}</TableCell>
                <TableCell>{position.pack}</TableCell>
                <TableCell>{position.volume.edited}</TableCell>
                <TableCell>{position.salesman_name}</TableCell>
            </TableRow>
        ));
    }

    return (
        <Fragment>
            {tabType === 'day'
                ? (
                    <h5 style={{ margin: '15px 0 0 10px' }}>
                        Дата заказа: {order.date}
                    </h5>
                )
                : (
                    <h5 style={{ margin: '15px 0 0 10px' }}>
                        Закупка на неделю
                    </h5>
                )
            }
            <Fragment>
                <Paper style={styles.positionsTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Группа</TableCell>
                                <TableCell>Наименование</TableCell>
                                <TableCell>Марка</TableCell>
                                <TableCell>Ед. изм.</TableCell>
                                <TableCell>Размер ТЕ</TableCell>
                                <TableCell>Цена за ТЕ</TableCell>
                                <TableCell>Закупаемая упаковка</TableCell>
                                <TableCell>Закупаемый объем</TableCell>
                                <TableCell>Поставщик</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {getSortedPositions(order.data)}
                            {/* {salesman.delivery_price > 0
                                    ? (
                                        <TableRow>
                                            <TableCell>Цена доставки:</TableCell>
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell />
                                            <TableCell>{salesman.delivery_price}</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    )
                                    : null
                            } */}

                            <TableRow>
                                <TableCell style={styles.boldCell}>Итого:</TableCell>
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell />
                                <TableCell style={styles.boldCell}>
                                    {round2(order.data.reduce((total, current) => total + current.total, 0))}
                                </TableCell>
                                <TableCell />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Paper>

                <h5 style={{ margin: '15px 0 0 10px' }}>
                    Информация поставщиков
                </h5>
                <Paper style={styles.positionsTable}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Поставщик</TableCell>
                                <TableCell>Сумма заказа</TableCell>
                                <TableCell>Пороговая сумма</TableCell>
                                <TableCell />
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {order.data
                                .filter(salesman => salesman.name !== '')
                                .map(salesman => (
                                    <TableRow key={salesman.name}>
                                        <TableCell>{salesman.name}</TableCell>
                                        <TableCell>{round2(salesman.total)}</TableCell>
                                        <TableCell
                                            style={
                                                salesman.delivery_price !== 0 || salesman.total - salesman.delivery_price < salesman.min_price
                                                    ? styles.redCell
                                                    : styles.greenCell
                                            }
                                        >
                                            {round2(salesman.min_price)}
                                        </TableCell>
                                        <TableCell
                                            style={{ textAlign: 'center' }}
                                        >
                                            <Button
                                                style={{ minWidth: '151px' }}
                                                color="primary"
                                                variant="contained"
                                                // disabled={tabType !== 'week'}
                                                onClick={() => onRecalculate(salesman)}
                                            >
                                                Пересчитать
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Paper>
            </Fragment>
        </Fragment>
    );
}

DayTab.propTypes = {
    order: PropTypes.shape({
        id: PropTypes.number,
        data: PropTypes.array,
        date: PropTypes.string,
        order: PropTypes.number,
        status: PropTypes.string,
    }).isRequired,
    tabType: PropTypes.string.isRequired,
    recalculate: PropTypes.func.isRequired,
};

export default memo(DayTab);
