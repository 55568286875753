import React, { Component } from 'react';
import {
    Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography,
} from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styles from '../styles';
import RE from '../common/RFC 2822';

class AddChefDialog extends Component {
    static defaultProps = {
        open: false,
        restaurants: null,
    }

    static propTypes = {
        open: PropTypes.bool,
        restaurants: PropTypes.arrayOf(PropTypes.shape({
            chef: PropTypes.number,
            id: PropTypes.number,
            name: PropTypes.string,
            sort_order: PropTypes.number,
        })),
        dialogTitle: PropTypes.string.isRequired,
        showSnackBar: PropTypes.func.isRequired,
        addChef: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    }

    state = {
        name: '',
        email: '',
        password: '',
        passwordConfirm: '',
        selectedRestaurant: null,
    }

    getRestaurantsOptions() {
        const { restaurants } = this.props;
        if (restaurants === null) {
            return [];
        }
        return restaurants.map(restaurant => ({
            value: restaurant.id,
            label: restaurant.name,
        }));
    }

    getSelectedRestaurant = () => {
        const { selectedRestaurant } = this.state;
        if (selectedRestaurant === null) {
            return null;
        }
        return {
            value: selectedRestaurant.id,
            label: selectedRestaurant.name,
        };
    }

    selectRestaurant = (selected) => {
        if (selected === null) {
            this.setState({
                selectedRestaurant: null,
            });
            return;
        }
        this.setState({
            selectedRestaurant: {
                id: selected.value,
                name: selected.label,
            },
        });
    };

    validateFields = () => {
        const { addChef, showSnackBar } = this.props;
        const { email, password, passwordConfirm } = this.state;
        if (email === '') {
            showSnackBar('Почта не должна быть пустой!');
            return;
        }
        if (!RE.test(email)) {
            showSnackBar('Неправильный формат почты!');
            return;
        }
        if (password === '' || passwordConfirm === '') {
            showSnackBar('Пароли не должны быть пустыми!');
            return;
        }
        if (password !== passwordConfirm) {
            showSnackBar('Пароли не совпадают!');
            return;
        }
        // if (password.length < 8) {
        //     showSnackBar('Пароль слишком короткий!');
        //     return;
        // }
        addChef(this.state);
    }


    render() {
        const {
            name, email, password, passwordConfirm,
        } = this.state;
        const {
            open, onCancel, dialogTitle,
        } = this.props;
        return (
            <Dialog
                open={open}
                onBackdropClick={onCancel}
                onEscapeKeyDown={onCancel}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent
                    style={{ height: '355px' }}
                    className="dialog"
                >
                    <TextField
                        style={styles.settingsChefTextField}
                        helperText="Имя"
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                    />
                    <TextField
                        style={styles.settingsChefTextField}
                        helperText="Почта *"
                        value={email}
                        type="email"
                        required
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <TextField
                        style={styles.settingsChefTextField}
                        helperText="Пароль *"
                        value={password}
                        type="password"
                        required
                        onChange={e => this.setState({ password: e.target.value })}
                    />
                    <TextField
                        style={styles.settingsChefTextField}
                        helperText="Повторите пароль *"
                        value={passwordConfirm}
                        type="password"
                        required
                        onChange={e => this.setState({ passwordConfirm: e.target.value })}
                    />
                    <Select
                        styles={styles.selectField}
                        maxMenuHeight={170}
                        isClearable
                        placeholder="Ресторан"
                        value={this.getSelectedRestaurant()}
                        options={this.getRestaurantsOptions()}
                        onChange={this.selectRestaurant}
                        required
                    />
                    <Typography style={{ marginTop: '20px' }} variant="body2" gutterBottom>
                        Минимальная длина пароля - 8 символов. <br />
                        Пароль не может состоять только из цифр. <br />
                        * - обязательное поле.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.validateFields}
                    >
                        Создать
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                    >
                        Отмена
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AddChefDialog;
