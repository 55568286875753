import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import isSameDay from 'react-dates/lib/utils/isSameDay';
import './react_dates_overrides.css';
import axios from 'axios';
import moment from 'moment';
import PropTypes from 'prop-types';
import parseErrors from './ErrorParsing';
import { loadDates } from '../API_requests/DatesAPI';

export default class DatePicker extends PureComponent {
    signal = axios.CancelToken.source();

    state = {
        focused: false,
        highlightedDates: null,
        isDatesFetched: false,
    };

    static defaultProps = {
        salesman: {},
        date: {},
    };

    static propTypes = {
        tableName: PropTypes.string.isRequired,
        salesman: PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
        }),
        date: PropTypes.instanceOf(moment),
        onDateSelected: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
    };


    componentDidMount() {
        const { tableName } = this.props;
        if (tableName === 'check_lists') {
            this.loadDates();
        }
    }

    componentDidUpdate(prevProps) {
        const { salesman } = this.props;
        if (salesman !== prevProps.salesman) {
            this.loadDates();
        }
    }
    loadDates = async () => {
        try {
            const { salesman, tableName } = this.props;
            const res = await loadDates(salesman.id, tableName, this.signal.token);
            this.setState({
                highlightedDates: res.data.map(date => (moment(date))),
                isDatesFetched: true,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { showError } = this.props;
            parseErrors(showError, 'Произошла ошибка при обновлении статуса', err);
        }
    };

    onMobile() {
        return !(document.documentElement.clientWidth > 768);
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    render() {
        const { focused, isDatesFetched, highlightedDates } = this.state;
        const { date, onDateSelected } = this.props;
        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    marginBottom: '10px',
                    marginRight: '10px',
                }}
            >
                <Typography variant="h6" style={{ marginRight: '5px' }}>
                    Выберите дату закупки:
                </Typography>
                <SingleDatePicker
                    date={date}
                    onDateChange={onDateSelected}
                    focused={focused}
                    onFocusChange={({ focused }) => this.setState({ focused })}
                    numberOfMonths={1}
                    isOutsideRange={() => false}
                    isDayHighlighted={
                        isDatesFetched
                            ? day1 => highlightedDates.some(day2 => isSameDay(day1, day2))
                            : () => ''
                    }
                    firstDayOfWeek={1}
                    displayFormat="DD-MM-YYYY"
                    anchorDirection="right"
                    small
                    placeholder="Дата"
                    withPortal={this.onMobile()}
                    readOnly
                    id="1"
                />
            </div>
        );
    }
}
