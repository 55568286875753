import axios from '../common/api_config';
import { withAuthHeader } from '../Auth';

const loadGroups = async (cancelToken) => {
    const res = await axios({
        url: '/api/position_groups/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export default loadGroups;
