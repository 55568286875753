import React from 'react';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from '../styles';

export default function OfferSalesman({
    salesmen, selectedSalesman, onSalesmanSelected, onAddSalesman, disableButton,
}) {
    function getSalesmanOptions() {
        return salesmen.map(salesman => ({
            value: salesman.id,
            label: salesman.name,
        }));
    }

    const selectSalesman = (selected) => {
        onSalesmanSelected({
            id: selected.value,
            name: selected.label,
        });
    };

    const getSelectedSalesman = () => {
        if (!selectedSalesman) {
            return null;
        }
        return {
            value: selectedSalesman.id,
            label: selectedSalesman.name,
        };
    };

    return (
        <div
            style={{
                display: 'flex', flexWrap: 'wrap', alignItems: 'center',
            }}
        >
            <Select
                styles={styles.selectField}
                placeholder="Выберите поставщика"
                value={getSelectedSalesman()}
                options={getSalesmanOptions()}
                onChange={selectSalesman}
            />
            <Button
                style={styles.regularButton}
                disabled={disableButton}
                variant="contained"
                color="primary"
                onClick={onAddSalesman}
            >
                Добавить
            </Button>
        </div>
    );
}

const salesman = PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    min_price: PropTypes.number,
    sort_order: PropTypes.number,
});

OfferSalesman.defaultProps = {
    selectedSalesman: null,
};

OfferSalesman.propTypes = {
    salesmen: PropTypes.arrayOf(salesman).isRequired,
    selectedSalesman: salesman,
    onSalesmanSelected: PropTypes.func.isRequired,
    onAddSalesman: PropTypes.func.isRequired,
    disableButton: PropTypes.bool.isRequired,
};
