import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import moment from 'moment/moment';
import {
    Checkbox, CircularProgress, FormControlLabel, Paper, Snackbar, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DemandTabs from './DemandTabs';
import AddRestaurandDialog from './AddRestaurandDialog';
import InfoDialog from '../common/InfoDialog';
import ChooseDialog from '../common/ChooseDialog';
import ImportFilterDialog from '../common/ImportFilterDialog';
import RestaurantSelect from '../common/RestaurantSelect';
import DatePicker from '../common/DatePicker';
import ImportExportControl from '../common/ImportExportControl';
import parseErrors from '../common/ErrorParsing';
import App from '../App';
import withAuth from '../withAuth';
import { DEMAND_TYPE, CONTINUE, ADD_FILTERS } from '../common/constants';
import loadUnits from '../API_requests/UnitsAPI';
import loadGroups from '../API_requests/GroupsAPI';
import { loadSettings } from '../API_requests/SettingsAPI';
import { loadRestaurants, addRestaurant } from '../API_requests/RestaurantsAPI';
import {
    loadDemand, loadDemandFrequencies, saveLink, addPosition, editPosition, deletePosition,
    addFrequency, editFrequency, deleteFrequency, handleImport, handleExport,
} from '../API_requests/DemandsAPI';
import { loadDeliveryDates, saveDeilveryDates } from '../API_requests/DeliveryDatesAPI';
import styles from '../styles';

class Demand extends Component {
    signal = axios.CancelToken.source()

    state = {
        restaurants: null,
        addRestaurantDialogOpen: false,
        selectedRestaurant: null,
        date: null,
        demandFrequencies: [],
        deliveryDates: { table_head: [], table_body: [] },
        dateFormatted: null,
        demandId: null,
        positions: [],
        settings: {},
        importType: '',
        errorDialogOpen: false,
        errorText: '',
        errorTitle: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        filterDialogOpen: false,
        filtersText: '',
        filtersTitle: '',
        chooseDialogButtonTitle: '',
        snackBarOpen: false,
        snackBarMessage: '',
        units: [],
        groups: [],
        link: '',
        loading: false,
        selectedFilters: [],
        sortingByGroup: false,
    };

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const restaurantId = parseInt(params.get('restaurant'));
        const dateFormatted = params.get('date');
        this.onLoadRestaurants(restaurantId);
        this.onLoadUnits();
        this.onLoadGroups();
        this.onLoadSettings();
        this.setDateFromParamsOrStorage(dateFormatted);
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    async onLoadRestaurants(restaurantId) {
        try {
            const res = await loadRestaurants(this.signal.token);
            this.setState({
                restaurants: res.data,
            }, () => {
                const { restaurants } = this.state;
                if (restaurantId && restaurants) {
                    const selectedRestaurant = restaurants.find(x => x.id === restaurantId);
                    if (selectedRestaurant) {
                        this.setState({ selectedRestaurant }, () => this.loadDemandIfReady());
                    }
                } else {
                    const selectedRestaurant = localStorage.getItem('demandSelectedRestaurant');
                    if (selectedRestaurant) {
                        this.setState({
                            selectedRestaurant: restaurants.find(x => x.id === parseInt(selectedRestaurant)),
                        }, () => this.loadDemandIfReady());
                    }
                }
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке ресторанов', err);
        }
    }

    async onLoadUnits() {
        try {
            const res = await loadUnits(this.signal.token);
            this.setState({ units: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.showError('Произошла ошибка при загрузке единиц', JSON.stringify(err.response));
        }
    }

    async onLoadGroups() {
        try {
            const res = await loadGroups(this.signal.token);
            this.setState({
                groups: res.data,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке групп', err);
        }
    }

    onLoadSettings = async () => {
        try {
            const res = await loadSettings(this.signal.token);
            this.setState({ settings: res.data[0] });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.setState({ settings: {} });
            parseErrors(this.showError, 'Произошла ошибка при загрузке настроек', err);
        }
    }

    async onLoadDemand() {
        this.setState({ loading: true });
        const { history } = this.props;
        const { selectedRestaurant, dateFormatted, sortingByGroup } = this.state;
        const params = new URLSearchParams({ restaurant: selectedRestaurant.id, date: dateFormatted }).toString();
        history.push(`/demand?${params}`);
        try {
            const data = {
                restaurant: selectedRestaurant.id, week: dateFormatted,
            };
            const res = await loadDemand(data, this.signal.token);
            this.setState({
                loading: false,
                demandId: res.data.id,
                positions: this.sortPositions(res.data.demand_positions, sortingByGroup),
                link: res.data.gsheet_link,
            }, () => this.onLoadDemandFrequencies());
            this.onLoadDeliveryDates();
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.setState({
                loading: false,
                demandId: null,
                positions: [],
            });
            parseErrors(this.showError, 'Произошла ошибка при загрузке/создании спроса', err);
        }
    }

    async onLoadDemandFrequencies() {
        try {
            const { selectedRestaurant, dateFormatted } = this.state;
            const data = {
                restaurant_id: selectedRestaurant.id,
                week: dateFormatted,
            };
            const res = await loadDemandFrequencies(data, this.signal.token);
            this.setState({
                demandFrequencies: res.data,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке частот', err);
        }
    }

    onLoadDeliveryDates = async () => {
        const { dateFormatted, selectedRestaurant } = this.state;
        try {
            const data = { restaurant_id: selectedRestaurant.id, week: dateFormatted };

            const res = await loadDeliveryDates(data, this.signal.token);
            this.setState({
                deliveryDates: res.data,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке дней доставки', err);
        }
    }

    onRestaurantSelected = (restaurant) => {
        const { selectedRestaurant } = this.state;
        if (restaurant === selectedRestaurant) {
            return;
        }

        this.setState({
            selectedRestaurant: restaurant,
        }, () => {
            localStorage.setItem('demandSelectedRestaurant', restaurant.id);
            this.loadDemandIfReady();
        });
    };

    onDateSelected = (e) => {
        const { date } = this.state;
        if (!e || date === e) {
            return;
        }

        const dateFormatted = e.format('YYYY-MM-DD');
        this.setState({
            date: e,
            dateFormatted,
        }, () => {
            localStorage.setItem('demandDate', dateFormatted);
            this.loadDemandIfReady();
        });
    };

    onImport = (importType, filters = []) => {
        /**
         * Opens a modal window
         * @param {string} importType Type of import
         * @param {array} filters Filters array
         */

        if (filters.length > 0) {
            filters = filters.map(filter => ({ id: filter.value, name: filter.label, type: DEMAND_TYPE }));
        }
        const chooseDialogParams = {
            dialogTitle: 'При импорте все позиции будут заменены на импортируемые',
            dialogText: 'Вы уверены, что хотите удалить все текущие позиции?',
            primaryButtonTitle: CONTINUE,
            secondaryButtonTitle: ADD_FILTERS,
            onPrimaryOption: this.onHandleImport,
            onSecondaryOption: this.addImportFilters,
        };
        this.setState({
            filterDialogOpen: false,
            chooseDialogOpen: true,
            chooseDialogParams,
            selectedFilters: filters,
            importType,
        });
    }

    setDateFromParamsOrStorage(dateFromParams) {
        const storageValues = {};
        if (dateFromParams) {
            storageValues.dateFormatted = dateFromParams;
            storageValues.date = moment(dateFromParams);
        } else {
            const dateFromStorage = localStorage.getItem('demandDate');
            storageValues.dateFormatted = dateFromStorage;
            storageValues.date = moment(dateFromStorage);
        }

        if (Object.keys(storageValues).length > 0 && storageValues.date.isValid()) {
            this.setState(storageValues, () => this.loadDemandIfReady());
        }
    }

    handleLinkChange = (link) => {
        this.setState({
            link,
        });
    };

    saveLink = async (link) => {
        try {
            const { demandId } = this.state;
            this.setState({
                loading: true,
            });
            await saveLink(demandId, link, this.signal.token);
            this.showSnackBar('Ссылка сохранена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении ссылки', err);
        }
        this.setState({
            loading: false,
        });
    };

    onAddPosition = async (position) => {
        const demandPosition = this.checkPosition(position);
        if (!demandPosition) return;
        try {
            const res = await addPosition(demandPosition, this.signal.token);
            const { positions } = this.state;
            const newPositions = [...positions, res.data];
            this.setState({ positions: newPositions });
            this.showSnackBar('Позиция добавлена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при добавлении позиции', err);
        }
    };

    onEditPosition = async (position) => {
        const demandPosition = this.checkPosition(position);
        if (!demandPosition) return 'ERROR';
        try {
            const res = await editPosition(demandPosition, this.signal.token);
            const { positions } = this.state;
            const newPositions = positions.map((item) => {
                if (item.id === res.data.id) {
                    return res.data;
                }
                return item;
            });

            this.setState({ positions: newPositions });
            this.showSnackBar('Позиция сохранена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return 'ERROR';
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении позиции', err);
        }
        return 'OK';
    };

    onDeletePosition = async (position) => {
        try {
            const { positions } = this.state;
            await deletePosition(position.id, this.signal.token);
            const newPositions = positions.filter(pos => pos.id !== position.id);
            this.setState({
                positions: newPositions,
            }, () => this.onLoadDemandFrequencies());
            this.showSnackBar('Позиция удалена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при удалении позиции', err);
        }
    };

    onAddFrequency = async (type, position, frequency) => {
        try {
            const { selectedRestaurant } = this.state;
            const data = { frequency, restaurant: selectedRestaurant.id };
            if (type === 'group') {
                data.group = position.id;
            } else if (type === 'position') {
                data.position = position.name;
            }
            const res = await addFrequency(data, this.signal.token);
            const { demandFrequencies } = this.state;
            const newFrequencies = [...demandFrequencies, res.data];

            this.setState({ demandFrequencies: newFrequencies });
            this.showSnackBar('Добавлено');
            return 'OK';
        } catch (err) {
            if (axios.isCancel(err)) {
                return 'ERROR';
            }
            parseErrors(this.showError, 'Произошла ошибка при добавлении частоты', err);
            return 'ERROR';
        }
    };

    onEditFrequency = async (position, frequency) => {
        try {
            const { selectedRestaurant } = this.state;
            const data = {
                id: position.id,
                frequency,
                restaurant: selectedRestaurant.id,
                position: position.name,
            };
            const res = await editFrequency(data, this.signal.token);
            const { demandFrequencies } = this.state;
            const newFrequencies = demandFrequencies.map((item) => {
                if (item.id === res.data.id) {
                    return res.data;
                }
                return item;
            });

            this.setState({ demandFrequencies: newFrequencies });
            this.showSnackBar('Сохранено');
            return 'OK';
        } catch (err) {
            if (axios.isCancel(err)) {
                return 'ERROR';
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении частоты', err);
            return 'ERROR';
        }
    };

    onDeleteFrequency = async (frequency) => {
        try {
            const { demandFrequencies } = this.state;
            await deleteFrequency(frequency.id, this.signal.token);
            const frequencies = demandFrequencies.filter(pos => pos.id !== frequency.id);

            this.setState({ demandFrequencies: frequencies });
            this.showSnackBar('Удалено');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при удалении частоты', err);
        }
    };

    getImportSheetLink = (importType) => {
        const { link, settings } = this.state;
        if (importType === 'default_import') {
            return settings.default_import_link;
        }
        return link;
    }

    onHandleImport = async () => {
        const {
            demandId, selectedFilters, sortingByGroup, importType,
        } = this.state;

        this.setState({
            chooseDialogOpen: false,
            filterDialogOpen: false,
            loading: true,
        });

        const sheet = this.getImportSheetLink(importType);
        const data = {
            demand: demandId,
            sheet,
            filters: selectedFilters,
        };
        try {
            const res = await handleImport(data, this.signal.token);
            if (res.data.errors.length !== 0) {
                parseErrors(this.showError, 'Некоторые позиции не удалось импортировать', { response: res });
            }
            this.setState({
                positions: this.sortPositions(res.data.positions, sortingByGroup),
                loading: false,
            }, () => this.showSnackBar('Данные успешно импортированы!'));
            this.onLoadDemandFrequencies();
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при импорте', err);
            this.setState({
                loading: false,
            });
        }
    };

    onHandleExport = async (link) => {
        try {
            const { demandId } = this.state;
            const data = {
                demand: demandId,
                sheet: link,
            };
            this.setState({
                loading: true,
            });
            await handleExport(data, this.signal.token);
            this.showSnackBar('Данные успешно экспортированы!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при экспорте', err);
        }
        this.setState({
            loading: false,
        });
    };

    addRestaurant = async (name) => {
        try {
            const res = await addRestaurant({ name }, this.signal.token);
            const { restaurants } = this.state;
            restaurants.push(res.data);
            this.setState({
                restaurants,
                selectedRestaurant: res.data,
            });
            this.loadDemandIfReady();
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при добавлении ресторана', err);
        }
    };

    toggleDeliveryDay = (day) => {
        const { deliveryDates } = this.state;
        day.is_active = !day.is_active;
        this.setState({ deliveryDates });
    };

    onSaveDeliveryDates = async () => {
        const { deliveryDates } = this.state;
        try {
            await saveDeilveryDates(deliveryDates.table_body, this.signal.token);
            this.showSnackBar('Дни доставки сохранены');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении дней доставки', err);
        }
    }

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackBarOpen: false, snackBarMessage: '' });
    };

    onSecondaryOption = () => {
        const { chooseDialogButtonTitle } = this.state;
        if (chooseDialogButtonTitle === 'Добавить фильтры') {
            return this.addImportFilters;
        }
        return null;
    }

    addImportFilters = () => {
        this.setState({
            chooseDialogOpen: false,
            filterDialogOpen: true,
            filtersText: '',
            filtersTitle: 'Позиции содержащие данные подстроки не будут импортированы',
        });
    }

    toggleSorting = () => {
        const { sortingByGroup, positions } = this.state;
        const sortedPositions = this.sortPositions(positions, !sortingByGroup);
        this.setState({ positions: sortedPositions, sortingByGroup: !sortingByGroup });
    }

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    }

    sortPositions(positions, sortingByGroup) {
        if (sortingByGroup) {
            return this.sortByGroup(positions);
        }
        return this.sortById(positions);
    }

    sortById(positions) {
        const sortedPositions = positions.sort((nextPos, pos) => {
            if (nextPos.id < pos.id) {
                return -1;
            }
            if (nextPos.id > pos.id) {
                return 1;
            }
            return 0;
        });
        return sortedPositions;
    }

    sortByGroup(positions) {
        const sortedPositions = positions.sort((nextPos, pos) => {
            if (nextPos.group.sort_order < pos.group.sort_order) {
                return -1;
            }
            if (nextPos.group.sort_order > pos.group.sort_order) {
                return 1;
            }
            return 0;
        });
        return sortedPositions;
    }

    showSnackBar(msg) {
        this.setState({
            snackBarOpen: true,
            snackBarMessage: msg,
        });
    }

    checkPosition(position) {
        const requiredFields = ['selectedGroup', 'name', 'selectedUnit', 'amount'];
        const requiredFieldsNames = ['группа', 'наименование', 'единица измерения', 'объем на неделю'];
        for (let i = 0; i < requiredFields.length; i += 1) {
            const name = requiredFields[i];
            if (position[name] === null || position[name] === '') {
                this.showError('Ошибка при сохранении позиции', `Нужно заполнить поле "${requiredFieldsNames[i]}"`);
                return null;
            }
        }
        if (!/\d+-\d+/.test(position.amount) && !/>\d+/.test(position.amount) && !/<\d+/.test(position.amount) && !/\d+/.test(position.amount)) {
            this.showError('Ошибка при сохранении позиции',
                'В поле "объем на неделю" должны быть записаны значения от и до либо одно число, < или >, например, 10-20, <12 или >20');
            return null;
        }

        let packFrom = 0;
        let packTo = 0;
        if (position.pack === '') {
            packTo = null;
        } else {
            if (!/\d+-\d+/.test(position.pack) && !/>\d+/.test(position.pack) && !/<\d+/.test(position.pack)) {
                this.showError('Ошибка при сохранении позиции',
                    'В поле "упаковка" должны быть записаны значения от и до, < или >, например, 10-20, <12, >20 или оставьте поле пустым');
                return null;
            }
            if (position.pack.indexOf('-') !== -1) {
                const packStr = position.pack.split('-');
                packFrom = parseFloat(packStr[0]);
                packTo = parseFloat(packStr[1]);
            } else if (position.pack.indexOf('>') !== -1) {
                packFrom = position.pack.replace('>', '');
                packTo = null;
            } else if (position.pack.indexOf('<') !== -1) {
                packFrom = 0;
                packTo = position.pack.replace('<', '');
            }
        }

        let amountFrom = 0;
        let amountTo = 0;
        if (position.amount.indexOf('-') !== -1) {
            const amountStr = position.amount.split('-');
            amountFrom = parseFloat(amountStr[0]);
            amountTo = parseFloat(amountStr[1]);
        } else if (position.amount.indexOf('>') !== -1) {
            amountFrom = position.amount.replace('>', '');
            amountTo = null;
        } else if (position.amount.indexOf('<') !== -1) {
            amountFrom = 0;
            amountTo = position.amount.replace('<', '');
        } else {
            amountFrom = position.amount;
            amountTo = null;
        }
        const { demandId } = this.state;
        return {
            id: position.id,
            demand: demandId,
            group: position.selectedGroup.id,
            name: position.name,
            mark: position.mark,
            unit: position.selectedUnit.id,
            comment: position.comment,
            amount_from: amountFrom,
            amount_to: amountTo,
            package_from: packFrom,
            package_to: packTo,
            is_zero_frequency: position.is_zero_frequency,
        };
    }


    loadDemandIfReady() {
        const { dateFormatted, selectedRestaurant } = this.state;
        if (dateFormatted && selectedRestaurant) {
            this.onLoadDemand();
        }
    }

    render() {
        const {
            demandId, positions, groups, units, demandFrequencies, deliveryDates, snackBarOpen, snackBarMessage, importType,
            addRestaurantDialogOpen, errorDialogOpen, errorText, errorTitle,
            chooseDialogOpen, chooseDialogParams, filterDialogOpen, filtersText, filtersTitle,
            restaurants, selectedRestaurant, date, link, loading, sortingByGroup, settings,
        } = this.state;
        return (
            <Fragment>
                <App />
                <div style={styles.pageBlock}>
                    <Snackbar
                        open={snackBarOpen}
                        message={snackBarMessage}
                        onClose={(event, reason) => this.closeSnackbar(event, reason)}
                        autoHideDuration={4000}
                    />
                    <InfoDialog
                        open={errorDialogOpen}
                        dialogText={errorText}
                        dialogTitle={errorTitle}
                        onClose={() => this.setState({ errorDialogOpen: false })}
                    />
                    <ChooseDialog
                        open={chooseDialogOpen}
                        dialogParams={chooseDialogParams}
                        onCancel={() => this.setState({
                            chooseDialogOpen: false,
                        })}
                    />
                    <ImportFilterDialog
                        open={filterDialogOpen}
                        filterText={filtersText}
                        filterTitle={filtersTitle}
                        type={DEMAND_TYPE}
                        importType={importType}
                        showError={this.showError}
                        onContinue={this.onImport}
                        onCancel={() => this.setState({
                            filterDialogOpen: false,
                            chooseDialogOpen: true,
                            selectedFilters: [],
                        })}
                    />
                    <AddRestaurandDialog
                        open={addRestaurantDialogOpen}
                        onClose={() => this.setState({ addRestaurantDialogOpen: false })}
                        addRestaurant={this.addRestaurant}
                    />
                    <Typography
                        style={styles.pageHeader}
                        variant="h4"
                    >
                        Спрос ресторанов
                    </Typography>
                    <div style={styles.pageSettings}>
                        <RestaurantSelect
                            restaurants={restaurants}
                            selectedRestaurant={selectedRestaurant}
                            onAddRestaurant={() => this.setState({ addRestaurantDialogOpen: true })}
                            onRestaurantSelected={this.onRestaurantSelected}
                            disableButton={loading}
                        />
                        <DatePicker
                            tableName="demand"
                            salesman={selectedRestaurant}
                            date={date}
                            onDateSelected={this.onDateSelected}
                            showError={this.showError}
                        />
                        <FormControlLabel
                            style={{ margin: '0 0 0 -5px', maxWidth: '180px' }}
                            control={(
                                <Checkbox
                                    color="primary"
                                    checked={sortingByGroup}
                                    onChange={this.toggleSorting}
                                    value="autoDistributeSwitcher"
                                />
                            )}
                            label="Сортировка по группам"
                        />
                    </div>
                </div>
                {demandId !== null || loading
                    ? (
                        <div
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <ImportExportControl
                                onImport={this.onImport}
                                handleExport={link => this.onHandleExport(link)}
                                handleLinkChange={link => this.handleLinkChange(link)}
                                saveLink={link => this.saveLink(link)}
                                link={link}
                                defaultLink={settings.default_import_link}
                                tableId={`demands/${demandId}`}
                                disableButton={loading}
                                errorDialog={(title, msg) => this.showError(title, msg)}
                            />
                            {loading
                                ? (
                                    <CircularProgress style={styles.circularProgress} />
                                )
                                : (
                                    <DemandTabs
                                        positions={positions}
                                        groups={groups}
                                        units={units}
                                        frequencies={demandFrequencies}
                                        deliveryDates={deliveryDates}
                                        addPosition={this.onAddPosition}
                                        addFrequency={this.onAddFrequency}
                                        editPosition={this.onEditPosition}
                                        editFrequency={this.onEditFrequency}
                                        deletePosition={this.onDeletePosition}
                                        deleteFrequency={this.onDeleteFrequency}
                                        toggleDeliveryDay={this.toggleDeliveryDay}
                                        onSaveDeliveryDates={this.onSaveDeliveryDates}
                                    />
                                )}
                        </div>
                    )
                    : (
                        <Paper
                            style={{
                                marginTop: '40px',
                                maxWidth: '300px',
                                padding: '15px',
                                textAlign: 'center',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            Выберите ресторан и дату
                        </Paper>
                    )
                }
            </Fragment>
        );
    }
}

export default withRouter(withAuth(Demand));
