import React from 'react';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    // DialogContentText,
    DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import SalesmanEmail from './SalesmanEmail';

function SalesmanEmailsModal({
    open, salesmen, changeEmail, saveEmail, onContinue, onCancel,
}) {
    return (
        <Dialog
            open={open}
            onBackdropClick={onCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>Почты поставщиков</DialogTitle>
            <DialogContent
                // style={{ width: '200px' }}
                className="dialog"
            >
                {/* <DialogContentText>
                    text
                </DialogContentText> */}
                {salesmen.map(
                    salesman => (
                        <SalesmanEmail
                            key={salesman.id}
                            salesman={salesman}
                            changeEmail={changeEmail}
                            saveEmail={saveEmail}
                        />
                    ),
                )}
            </DialogContent>
            <DialogActions
                style={{ flexWrap: 'wrap', justifyContent: 'space-between' }}
            >
                <div />
                {/* {onSecondaryOption
                    ? (
                        <div>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={onSecondaryOption}
                            >
                                {secondaryButtonTitle}
                            </Button>
                        </div>
                    )
                    : <div />
                } */}
                <div>
                    <Button
                        style={{ marginRight: '7px' }}
                        variant="contained"
                        color="primary"
                        onClick={onContinue}
                    >
                        Продолжить
                    </Button>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={onCancel}
                    >
                        Отмена
                    </Button>
                </div>
            </DialogActions>
        </Dialog>
    );
}

export default (SalesmanEmailsModal);

SalesmanEmailsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    salesmen: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeEmail: PropTypes.func.isRequired,
    saveEmail: PropTypes.func.isRequired,
    // dialogParams: PropTypes.shape({
    //     dialogTitle: PropTypes.string.isRequired,
    //     dialogText: PropTypes.string,
    //     primaryButtonTitle: PropTypes.string,
    //     secondaryButtonTitle: PropTypes.string,
    //     onPrimaryOption: PropTypes.func,
    //     onSecondaryOption: PropTypes.func,
    // }).isRequired,
    onContinue: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
