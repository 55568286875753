import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { FormHelperText } from '@material-ui/core';

export default function SortingSelect({ selectedSorting, onSortingSelected }) {
    const SortingSelect = (sorting) => {
        onSortingSelected(sorting);
    };

    const getSortingSelected = () => {
        if (!selectedSorting) {
            return null;
        }
        return {
            value: selectedSorting.value,
            label: selectedSorting.label,
        };
    };

    const options = [
        { value: 'default', label: 'По умолчанию' },
        { value: 'alphabet', label: 'По алфавиту' },
        { value: 'groups', label: 'По группам' },
        { value: 'salesmen', label: 'По поставщикам' },
    ];

    return (
        <div style={{
            width: '250px', marginRight: 'auto', marginTop: '5px', marginBottom: '10px',
        }}
        >
            <Select
                placeholder="Тип сортировки"
                isSearchable={false}
                options={options}
                value={getSortingSelected()}
                onChange={SortingSelect}
                menuPortalTarget={document.querySelector('body')}
            />
            <FormHelperText>Тип сортировки позиций</FormHelperText>
        </div>
    );
}

SortingSelect.defaultProps = {
    selectedSorting: null,
};

SortingSelect.propTypes = {
    selectedSorting: PropTypes.shape({
        value: PropTypes.string,
        label: PropTypes.string,
    }),
    onSortingSelected: PropTypes.func.isRequired,
};
