import React, { PureComponent } from 'react';
import { TableRow, TextField, Button } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import styles from '../styles';

const GROUP = { name: 'Группа', id: 0 };
const POSITION = { name: 'Блюдо', id: 1 };

export default class AddFrequencyRow extends PureComponent {
    state = {
        selectedType: GROUP,
        selectedGroup: null,
        selectedPosition: { id: null, name: '' },
        types: [GROUP, POSITION],
        frequency: '',
    };

    static defaultProps = {
        disabled: false,
        frequency: null,
    };

    static propTypes = {
        disabled: PropTypes.bool,
        frequency: PropTypes.shape({
            selectedType: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            selectedGroup: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
            }),
            name: PropTypes.string.isRequired,
            frequency: PropTypes.number,
        }),
        positions: PropTypes.arrayOf(PropTypes.object).isRequired,
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        addFrequency: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { frequency } = this.props;
        if (frequency) {
            if (frequency.position) {
                this.setState({
                    selectedType: POSITION,
                    selectedPosition: {
                        id: frequency.id,
                        name: frequency.name,
                    },
                    frequency: frequency.frequency,
                });
            } else {
                this.setState({
                    selectedType: GROUP,
                    selectedGroup: {
                        id: frequency.id,
                        name: frequency.name,
                    },
                    frequency: frequency.frequency,
                });
            }
        }
    }

    getSelectedType() {
        const { selectedType } = this.state;
        if (selectedType === null) {
            return null;
        }
        return {
            value: selectedType.id,
            label: selectedType.name,
        };
    }

    getTypesOptions() {
        const { types } = this.state;
        if (types === null) {
            return null;
        }
        return types.map(type => ({
            value: type.id,
            label: type.name,
        }));
    }

    getSelectedGroup() {
        const { selectedGroup } = this.state;
        if (selectedGroup === null) {
            return null;
        }
        return {
            value: selectedGroup.id,
            label: selectedGroup.name,
        };
    }

    getPositionsOptions() {
        const { selectedType } = this.state;
        const { groups, positions } = this.props;
        if (selectedType === null) {
            return [];
        }
        let result;
        if (selectedType.id === 0) {
            if (groups === null) {
                return [];
            }
            result = groups.map(group => ({
                value: group.id,
                label: group.name,
            }));
        }
        if (selectedType.id === 1) {
            if (positions === null) {
                return [];
            }
            result = positions.map(position => ({
                value: position.id,
                label: position.name,
            }));
        }
        return result;
    }

    selectType = (selected) => {
        this.setState({
            selectedType: {
                id: selected.value,
                name: selected.label,
            },
            selectedGroup: null,
        });
    };

    selectPosition = (selected) => {
        const { selectedType, selectedPosition } = this.state;
        if (selectedType.id === GROUP.id) {
            this.setState({
                selectedGroup: {
                    id: selected.value,
                    name: selected.label,
                },
            });
        } else {
            this.setState({
                selectedPosition: {
                    id: selectedPosition.id || 0,
                    name: selected.target.value,
                },
            });
        }
    };

    add = async () => {
        const {
            selectedType, selectedGroup, selectedPosition, frequency,
        } = this.state;

        const { addFrequency } = this.props;
        if (selectedGroup === null && selectedPosition.id === null) {
            return;
        }

        let result;
        if (selectedType.id === POSITION.id) {
            result = await addFrequency('position', selectedPosition, frequency);
        } else {
            result = await addFrequency('group', { id: selectedGroup.id, name: null }, frequency);
        }
        if (result === 'ERROR') return;
        this.setState({ selectedGroup: null });
    };

    render() {
        const { selectedPosition, frequency, selectedType } = this.state;
        const { disabled } = this.props;
        return (
            <TableRow style={{ height: '50px' }}>
                <TableCell>
                    <Select
                        isDisabled={disabled}
                        placeholder="Тип"
                        value={this.getSelectedType()}
                        options={this.getTypesOptions()}
                        onChange={this.selectType}
                        menuPortalTarget={document.querySelector('body')}
                        menuPlacement="top"
                    />
                </TableCell>
                <TableCell>
                    {selectedType.id === GROUP.id
                        ? (
                            <Select
                                isDisabled={disabled}
                                placeholder="Наименование"
                                value={this.getSelectedGroup()}
                                options={this.getPositionsOptions()}
                                onChange={this.selectPosition}
                                menuPortalTarget={document.querySelector('body')}
                                menuPlacement="top"
                                isSearchable
                            />
                        )
                        : (
                            <TextField
                                placeholder="Наименование"
                                fullWidth
                                value={selectedPosition.name}
                                onChange={e => this.selectPosition(e)}
                                required
                            />
                        )
                    }
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Частота"
                        fullWidth
                        value={frequency}
                        onChange={e => this.setState({ frequency: e.target.value })}
                        required
                    />
                </TableCell>
                <TableCell>
                    <Button
                        style={styles.tableButton}
                        variant="contained"
                        color="primary"
                        onClick={this.add}
                    >
                        {disabled ? 'Сохранить' : 'Добавить'}
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}
