import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadSalesmen = async (cancelToken) => {
    const res = await axios({
        url: '/api/salesmen/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addSalesman = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/salesmen/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editSalesman = async (data, cancelToken) => {
    const res = await axios({
        method: 'put',
        url: `/api/salesmen/${data.id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};
