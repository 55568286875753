import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Badge, Button, IconButton } from '@material-ui/core';
import { Settings } from '@material-ui/icons';
import axios from 'axios';
import PropTypes from 'prop-types';
import NavLink from './common/NavLink';
import { loadEditedCheckLists } from './API_requests/CheckListsAPI';

import AuthService from './AuthService';
import withAuth from './withAuth';

const Auth = new AuthService();

class App extends Component {
    signal = axios.CancelToken.source();

    state = {
        editedCheckListsAmount: 0,
    }

    static propTypes = {
        role: PropTypes.string.isRequired,
        username: PropTypes.string.isRequired,
    };

    componentDidMount() {
        this.getEditedCheckListsAmount();
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    getEditedCheckListsAmount = async () => {
        try {
            const res = await loadEditedCheckLists(this.signal.token);
            this.setState({ editedCheckListsAmount: res.data.length });
            // eslint-disable-next-line no-empty
        } catch { }
    }

    handleLogout = () => {
        Auth.logout();
    }

    render() {
        const { editedCheckListsAmount } = this.state;
        const { role, username } = this.props;
        return (
            <div>
                <nav className="navbar navbar-expand-md navbar-light bg-light">
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navbarSupportedContent"
                        aria-controls="navbarSupportedContent"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon" />
                    </button>

                    <div
                        className="collapse navbar-collapse"
                        id="navbarSupportedContent"
                        style={{ maxWidth: '900px', marginLeft: 'auto', marginRight: 'auto' }}
                    >
                        {role === 'admin'
                            ? (
                                <ul className="navbar-nav mr-auto">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Основное
                                        </a>
                                        <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                            <NavLink className="nav-link" to="/units">Единицы</NavLink>
                                            <NavLink className="nav-link" to="/salesmen">Поставщики</NavLink>
                                            <NavLink className="nav-link" to="/restaurants">Рестораны</NavLink>
                                            <NavLink className="nav-link" to="/groups">Группы</NavLink>
                                        </div>
                                    </li>
                                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <Badge
                                            color="primary"
                                            badgeContent={editedCheckListsAmount}
                                            max={9}
                                        >
                                            <NavLink className="nav-link" to="/check_lists">Чек-листы</NavLink>
                                        </Badge>
                                    </li>
                                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <NavLink className="nav-link" to="/price_analysis">Анализ цен</NavLink>
                                    </li>
                                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <NavLink className="nav-link" to="/offer">Предложение</NavLink>
                                    </li>
                                    <li className="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show">
                                        <NavLink className="nav-link" to="/demand">Спрос</NavLink>
                                    </li>
                                </ul>
                            ) : null
                        }
                        <div
                            style={{ alignItems: 'center', marginLeft: 'auto', marginRight: '0' }}
                            className="navbar-nav"
                        >
                            <div>
                                <span
                                    className="navbar-text"
                                    style={{ margin: '4px' }}
                                >
                                    {username}
                                </span>
                                {role === 'admin'
                                    ? (
                                        <IconButton
                                            style={{ marginLeft: '-5px' }}
                                            color="primary"
                                            component={Link}
                                            to="/settings"
                                        >
                                            <Settings />
                                        </IconButton>
                                    )
                                    : null
                                }
                            </div>
                            <Button
                                variant="contained"
                                size="small"
                                component={Link}
                                to="/login"
                                onClick={this.handleLogout}
                            >
                                Выйти
                            </Button>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withAuth(App);
