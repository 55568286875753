import React, { Component } from 'react';
import axios from 'axios';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    TextField,
    Button,
    Paper,
    Snackbar,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import App from '../App';
import InfoDialog from '../common/InfoDialog';
import ChooseDialog from '../common/ChooseDialog';
import parseErrors from '../common/ErrorParsing';
import {
    loadData, addEl, updateEl, deleteEl,
} from '../API_requests/BaseObjectsAPI';
import { CONTINUE } from '../common/constants';
import styles from '../styles';
import withAuth from '../withAuth';
import RestaurantSettings from './RestaurantSettings';

class Restaurants extends Component {
    signal = axios.CancelToken.source()

    state = {
        restaurants: [],
        newName: '',
        updatingEl: {},
        isUpdate: false,
        snackBarOpen: false,
        snackBarMessage: '',
        errorDialogOpen: false,
        errorText: '',
        errorTitle: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        chooseText: '',
        chooseTitle: '',
        elementId: '',
    };


    componentDidMount() {
        this.onLoadData();
    }

    componentWillUnmount() {
        this.signal.cancel();
    }


    onDelete(elId, elName, title) {
        const chooseDialogParams = {
            dialogTitle: 'При удалении ресторана, с ним удалятся все позиции, без возможности восстановления.',
            dialogText: `Вы уверены, что хотите удалить ${elName}?`,
            primaryButtonTitle: CONTINUE,
            onPrimaryOption: this.onDeleteEl,
        };
        this.setState({
            chooseDialogOpen: true,
            chooseDialogParams,
            elementId: elId,
        });
    }

    changeElementValue = (e, field) => {
        const { updatingEl } = this.state;
        if (field === 'name') {
            updatingEl[field] = e.target.value;
        } if (field === 'active') {
            updatingEl[field] = e.target.checked;
        } else {
            const value = parseInt(e.target.value);
            if (Number.isNaN(value)) {
                updatingEl[field] = 0;
            } else {
                updatingEl[field] = value;
            }
        }

        this.setState({ updatingEl });
    };

    onLoadData = async () => {
        try {
            const restaurants = await loadData('restaurants', this.signal.token);
            const chains = await loadData('chains', this.signal.token);
            this.setState({
                restaurants: restaurants.data,
                chains: chains.data,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке данных', err);
        }
    };

    onAddEl = async () => {
        try {
            const { newName, restaurants } = this.state;
            if (newName === '') {
                this.setState({
                    snackBarOpen: true,
                    snackBarMessage: 'Имя не должно быть пустым!',
                });
                return;
            }
            const res = await addEl('restaurants', { name: newName }, this.signal.token);
            restaurants.push(res.data);
            this.setState({
                restaurants,
                newName: '',
                snackBarOpen: true,
                snackBarMessage: 'Запись добавлена!',
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании', err);
        }
    };

    onUpdateEl = async () => {
        try {
            const {
                updatingEl: {
                    id, name, sort_order, active
                },
            } = this.state;
            if (name === '') {
                this.setState({
                    snackBarOpen: true,
                    snackBarMessage: 'Имя не должно быть пустым!',
                });
                return;
            }
            const objectData = {
                name,
                sort_order,
                active,
            };
            await updateEl('restaurants', id, objectData, this.signal.token);
            this.setState({
                snackBarOpen: true,
                snackBarMessage: 'Запись обновлена!',
                isUpdate: false,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при редактировании', err);
        }
    };

    onDeleteEl = async () => {
        try {
            const { elementId } = this.state;
            this.setState({
                chooseDialogOpen: false,
            });
            await deleteEl('restaurants', elementId, this.signal.token);
            const { restaurants } = this.state;
            const newData = restaurants.filter(item => item.id !== elementId);
            this.setState({
                restaurants: newData,
                snackBarOpen: true,
                snackBarMessage: 'Запись удалена!',
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при удалении', err);
        }
    }

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    }

    onChainSelected = (chain) => {
        const { selectedChain } = this.state;
        if (chain === selectedChain) {
            return;
        }
        let { updatingEl } = this.state;
        updatingEl.chain = chain.id;
        this.setState({
            selectedChain: chain,
            updatingEl: updatingEl
        });
    }

    render() {
        const {
            newName, isUpdate, updatingEl, restaurants, chains, snackBarOpen, snackBarMessage,
            errorDialogOpen, errorText, errorTitle, chooseDialogOpen, chooseDialogParams, chooseTitle, chooseText,
        } = this.state;
        return (
            <div style={{
                maxWidth: '900px', display: 'block', marginLeft: 'auto', marginRight: 'auto',
            }}
            >
                <App />
                <Typography
                    style={styles.pageHeader}
                    variant="h4"
                >
                    Рестораны
                </Typography>
                <Snackbar
                    open={snackBarOpen}
                    message={snackBarMessage}
                    onClose={() => {
                        this.setState({
                            snackBarOpen: false,
                            snackBarMessage: '',
                        });
                    }}
                    autoHideDuration={4000}
                />
                <InfoDialog
                    open={errorDialogOpen}
                    dialogText={errorText}
                    dialogTitle={errorTitle}
                    onClose={() => this.setState({ errorDialogOpen: false })}
                />
                <ChooseDialog
                    open={chooseDialogOpen}
                    dialogParams={chooseDialogParams}
                    onCancel={() => this.setState({ chooseDialogOpen: false })}
                />
                <RestaurantSettings
                    open={isUpdate}
                    updatingEl={updatingEl}
                    dialogTitle={chooseTitle}
                    dialogText={chooseText}
                    changeElementValue={this.changeElementValue}
                    chains={chains}
                    onSave={this.onUpdateEl}
                    onCancel={() => this.setState({
                        isUpdate: false,
                    })}
                    onChainSelected={this.onChainSelected}
                />
                <div>
                    <TextField
                        style={{ marginBottom: '15px', marginRight: '15px' }}
                        value={newName}
                        onChange={e => this.setState({ newName: e.target.value })}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onAddEl}
                    >
                        Добавить
                    </Button>
                </div>
                <Paper style={{ maxWidth: '400px', marginTop: '15px' }}>
                    <List>
                        {restaurants !== undefined && restaurants.map((el, i, arr) => ([
                            <ListItem
                                key={el.id}
                                className="projects-list-item"
                            >
                                <ListItemText
                                    primary={`${el.sort_order}, ${el.name}`}
                                    style={{ paddingRight: '60px' }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => this.onDelete(el.id, el.name, 'Рестораны')}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={() => this.setState({
                                            isUpdate: true,
                                            updatingEl: el,
                                            chooseTitle: 'Изменение параметров позиции',
                                            chooseText: '',
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>,
                            i === arr.length - 1 ? null : <Divider key={`${el.id}_divider`} component="li" />,
                        ]))}
                    </List>
                </Paper>
            </div>
        );
    }
}

export default withAuth(Restaurants);
