import React from 'react';
import {
    Button, Paper, Table, TableBody, TableHead, TableRow, TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import DemandDeliveryRow from './DemandDeliveryRow';
import styles from '../styles';

export default function DemandDeliveryWeekdays({ deliveryDates, toggleDeliveryDay, onSaveDeliveryDates }) {
    return (
        <Paper style={{
            ...styles.positionsTable,
            maxWidth: '900px',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
        }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell style={{ width: '75px', padding: '0 0 0 10px' }}>Частота</TableCell>
                        {deliveryDates.table_head.map(day => (
                            <TableCell
                                style={styles.deliveriesTableCell}
                                key={day.id}
                            >
                                {day.name}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {deliveryDates.table_body.map(frequency => (
                        <DemandDeliveryRow
                            key={frequency.frequency}
                            frequency={frequency}
                            toggleDeliveryDay={toggleDeliveryDay}
                        />
                    ))}
                </TableBody>
            </Table>
            <Button
                style={{
                    margin: '5px 0',
                }}
                variant="contained"
                color="primary"
                onClick={onSaveDeliveryDates}
            >
                    Сохранить
            </Button>
        </Paper>
    );
}

DemandDeliveryWeekdays.propTypes = {
    deliveryDates: PropTypes.shape({
        table_head: PropTypes.array,
        table_body: PropTypes.array,
    }).isRequired,
    toggleDeliveryDay: PropTypes.func.isRequired,
    onSaveDeliveryDates: PropTypes.func.isRequired,
};
