import React, { Component } from 'react';
import {
    Dialog,
    TextField,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default class AddSalesmanDialog extends Component {
    state = {
        name: '',
    };

    static propTypes = {
        open: PropTypes.bool.isRequired,
        addSalesman: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    save = () => {
        const { name } = this.state;
        const { addSalesman, onClose } = this.props;
        addSalesman(name);
        this.setState({
            name: '',
        });
        onClose();
    };

    render() {
        const { name } = this.state;
        const { open, onClose } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                onBackdropClick={onClose}
                onEscapeKeyDown={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Добавить нового поставщика</DialogTitle>
                <DialogContent className="dialog">
                    <DialogContentText>
                        Введите имя поставщика
                    </DialogContentText>
                    <TextField
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                        autoFocus
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.save}
                    >
                        Сохранить
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={onClose}
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
