import React, { Component, Fragment } from 'react';
import axios from 'axios/index';
import {
    Button, CircularProgress, Paper, Snackbar, Typography, Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import App from '../App';
import InfoDialog from '../common/InfoDialog';
import ChooseDialog from '../common/ChooseDialog';
import LinksDialog from '../common/LinksDialog';
import parseErrors from '../common/ErrorParsing';
import RestaurantSelect from '../common/RestaurantSelect';
import DatePicker from '../common/DatePicker';
import ImportExportControl from '../common/ImportExportControl';
import PriceAnalysisTable from './PriceAnalysisTable';
import { loadRestaurants } from '../API_requests/RestaurantsAPI';
import {
    getDocumentsForSalesman, handleExport, calculateCheckList, recalculateCheckList, calculateCheckListChain,
} from '../API_requests/CheckListsAPI';
import { saveOrder } from '../API_requests/OrdersAPI';
import {
    CONTINUE, SAVE_AND_GO_TO_CHEF, GO_TO_CHEF_WITHOUT_SAVING,
} from '../common/constants';
import styles from '../styles';

class PriceAnalysis extends Component {
    signal = axios.CancelToken.source();

    state = {
        restaurants: null,
        selectedRestaurant: null,
        date: null,
        dateFormatted: null,
        link: '',
        order: null,
        checkListId: null,
        restaurantCheckLists: [],
        chainCheckList: null,
        snackBarOpen: false,
        snackBarMessage: '',
        errorDialogOpen: false,
        errorTitle: '',
        errorText: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        linksDialogOpen: false,
        linksTitle: '',
        loading: false,
        salesmenGsheetLinks: [],
    };

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const restaurantId = parseInt(params.get('restaurant'));
        const dateFormatted = params.get('date');
        this.loadRestaurants(restaurantId);
        this.setDateFromParams(dateFormatted);
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    onSaveCheckList = async () => {
        this.setState({ chooseDialogOpen: false });
        try {
            const { order, checkListId } = this.state;
            const data = {
                order_id: order.id,
                check_list_id: checkListId,
                is_viewed: false,
            };
            await saveOrder(data, this.signal.token);
            this.showSnackBar('Чек-лист сохранен');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении чек-листа', err);
        }
    };

    setDateFromParams(dateFormatted) {
        if (!dateFormatted) {
            dateFormatted = localStorage.getItem('priceAnalysisDate');
        }
        const storageValues = {};
        if (dateFormatted !== null) {
            storageValues.dateFormatted = dateFormatted;
            storageValues.date = moment(dateFormatted);
        }
        if (Object.keys(storageValues).length > 0 && storageValues.date.isValid()) {
            this.setState(storageValues, () => this.loadPriceAnalysisIfReady());
        }
    }

    onRestaurantSelected = (restaurant) => {
        const { selectedRestaurant } = this.state;
        if (restaurant === selectedRestaurant) {
            return;
        }

        this.setState({
            selectedRestaurant: restaurant,
        }, () => {
            localStorage.setItem('priceAnalysisSelectedRestaurant', restaurant.id);
            this.loadPriceAnalysisIfReady();
        });
    };

    onDateSelected = (e) => {
        const { date } = this.state;
        if (!e || date === e) {
            return;
        }

        const dateFormatted = e.format('YYYY-MM-DD');
        this.setState({
            date: e,
            dateFormatted,
        }, () => {
            localStorage.setItem('priceAnalysisDate', dateFormatted);
            this.loadPriceAnalysisIfReady();
        });
    };

    openSavePriceAnalysisDialog = () => {
        const { selectedRestaurant, dateFormatted } = this.state;
        const chooseDialogParams = {
            dialogTitle: `Прошлый сохраненный чек-лист для ресторана ${selectedRestaurant.name} за 
            ${dateFormatted} будет удален`,
            dialogText: 'Вы уверены, что хотите заменить чек-лист на новый?',
            primaryButtonTitle: CONTINUE,
            onPrimaryOption: () => this.onSaveCheckList(),
        };
        this.setState({
            chooseDialogOpen: true,
            chooseDialogParams,
        });
    }

    openSendPriceAnalysisDialog = () => {
        const { selectedRestaurant, dateFormatted } = this.state;
        const chooseDialogParams = {
            dialogTitle: `При нажатии кнопки "Сохранить и перейти" старый чек-лист ресторана
            ${selectedRestaurant.name} за ${dateFormatted} будет удален и заменен на текущий`,
            dialogText: 'Перейти к чек-листу предварительно сохранив его?',
            primaryButtonTitle: SAVE_AND_GO_TO_CHEF,
            secondaryButtonTitle: GO_TO_CHEF_WITHOUT_SAVING,
            onPrimaryOption: () => this.onSaveAndSendCheckList(),
            onSecondaryOption: () => this.sendCheckListWithoutSaving(),
        };
        this.setState({
            chooseDialogOpen: true,
            chooseDialogParams,
        });
    }

    getDocumentsForSalesmen = async () => {
        this.setState({ loading: true });
        try {
            const { order, selectedRestaurant } = this.state;
            const data = {
                orders: order.week_order, restaurant: selectedRestaurant, document_type: 'price_analysis', with_excel: false,
            };
            const res = await getDocumentsForSalesman(data, this.signal.token);
            this.showLinks('Ссылки на документы для поставщиков', res.data.links);
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании документов', err);
        }
        this.setState({ loading: false });
    }

    onSaveAndSendCheckList = async () => {
        const {
            order, checkListId, selectedRestaurant, dateFormatted,
        } = this.state;
        const { history } = this.props;
        const data = {
            order_id: order.id,
            check_list_id: checkListId,
            is_viewed: false,
        };
        await saveOrder(data, this.signal.token);
        history.replace({
            pathname: '/chef',
            search: `?restaurant=${selectedRestaurant.id}&date=${dateFormatted}`,
        });
    };

    sendCheckListWithoutSaving = async () => {
        const { selectedRestaurant, dateFormatted } = this.state;
        const { history } = this.props;

        history.replace({
            pathname: '/chef',
            search: `?restaurant=${selectedRestaurant.id}&date=${dateFormatted}`,
        });
    }

    handleExport = async (link) => {
        try {
            const { selectedRestaurant, dateFormatted, order } = this.state;
            const data = {
                sheet: link,
                restaurant_id: selectedRestaurant.id,
                week: dateFormatted,
                orders: order.week_order,
            };
            this.setState({
                loading: true,
            });
            await handleExport(data, this.signal.token);
            this.setState({
                snackBarOpen: true,
                snackBarMessage: 'Данные успешно экспортированы!',
                loading: false,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при экспорте чек-листа', err);
            this.setState({ loading: false });
        }
    };

    loadRestaurants = async (restaurantId) => {
        try {
            const res = await loadRestaurants(this.signal.token);
            this.setState({
                restaurants: res.data,
            }, () => {
                const { restaurants } = this.state;
                if (restaurantId) {
                    const selectedRestaurant = restaurants.find(x => x.id === restaurantId);
                    if (selectedRestaurant) {
                        this.setState({ selectedRestaurant }, () => this.loadPriceAnalysisIfReady());
                    }
                } else {
                    const selectedRestaurant = localStorage.getItem('priceAnalysisSelectedRestaurant');
                    if (selectedRestaurant) {
                        this.setState({
                            selectedRestaurant: restaurants.find(x => x.id === parseInt(selectedRestaurant)),
                        }, () => this.loadPriceAnalysisIfReady());
                    }
                }
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке ресторанов', err);
        }
    }

    onCalculateCheckList = async () => {
        try {
            const { selectedRestaurant, dateFormatted } = this.state;
            const { history } = this.props;
            const queryParams = new URLSearchParams({
                restaurant: selectedRestaurant.id,
                date: dateFormatted,
            }).toString();
            history.push(`/price_analysis?${queryParams}`);
            const params = {
                restaurant_id: selectedRestaurant.id,
                week: dateFormatted,
            };
            const res = await calculateCheckList(params, this.signal.token);
            this.setState({
                checkListId: res.data.id,
                order: this.filterCheckLists(res.data.order),
                link: res.data.gsheet_link,
                loading: false,

            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при анализе цен', err);
            this.setState({
                checkListId: null,
                order: { day_orders: [], week_order: [], not_purchased_positions: { no_packs: [], no_salesman: [] } },
                link: '',
                loading: false,
            });
        }
    }

    onCalculateCheckListChain = async () => {
        try {
            const { dateFormatted } = this.state;
            const params = {
                week: dateFormatted,
            };
            const res = await calculateCheckListChain(params, this.signal.token);
            const restCheckLists = res.data.restaurants.map(data => ({
                checkListId: data.id,
                order: this.filterCheckLists(data.order),
                link: data.gsheet_link,
                restaurant: data.restaurant,
            }));

            const chainCheckList = {
                checkListId: res.data.chain.id,
                order: this.filterCheckLists(res.data.chain.order),
                link: res.data.chain.gsheet_link,
            };

            this.setState({
                checkListId: res.data.chain.id,
                order: this.filterCheckLists(res.data.chain.order),
                link: res.data.gsheet_link,
                chainCheckList,
                loading: false,
                restaurantCheckLists: restCheckLists,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при анализе цен', err);
            this.setState({
                checkListId: null,
                order: { day_orders: [], week_order: [], not_purchased_positions: { no_packs: [], no_salesman: [] } },
                link: '',
                loading: false,
            });
        }
    }

    onRecalculateCheckList = async (salesman, date) => {
        try {
            const { order, selectedRestaurant, dateFormatted } = this.state;
            this.setState({ loading: true });
            const data = {
                salesman,
                restaurant_id: selectedRestaurant.id,
                week: dateFormatted,
                order_id: order.id,
                date,
            };
            const res = await recalculateCheckList(data, this.signal.token);
            this.setState({ order: res.data.order, loading: false });
            if (res.data.errors.length !== 0) {
                parseErrors(this.showError, 'Не удалось выполнить некоторые варианты перерасчета', res.data.errors);
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при пересчете чек-листа', err);
            this.setState({ loading: false });
        }
    }

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorTitle: title,
            errorText: msg,
        });
    }

    selectCheckList = (id) => {
        if (id) {
            const { restaurantCheckLists } = this.state;
            const res = restaurantCheckLists.find(checkList => checkList.restaurant.id === id);
            this.setState({
                checkListId: res.checkListId,
                order: res.order,
                link: res.gsheet_link,
            });
        } else {
            const { chainCheckList } = this.state;
            this.setState({
                checkListId: chainCheckList.checkListId,
                order: chainCheckList.order,
                link: chainCheckList.gsheet_link,
            });
        }
    }

    getRestaurantsButtons = () => {
        const { restaurantCheckLists } = this.state;
        const buttons = restaurantCheckLists.map(checkList => (
            <Button
                style={styles.wideButton}
                onClick={() => { this.selectCheckList(checkList.restaurant.id); }}
                variant="contained"
                color="primary"
                key={checkList.restaurant.id}
            >
                {checkList.restaurant.name}
            </Button>
        ));
        if (buttons.length > 0) {
            buttons.push(
                <Button
                    style={styles.wideButton}
                    onClick={() => { this.selectCheckList(); }}
                    variant="contained"
                    color="primary"
                >
                    Показать для сети
                </Button>
            );
        }
        return buttons;
    }

    drawTable = (order, link, checkListId, selectedRestaurant, dateFormatted) => (
        <Fragment>
            {order
                ? (
                    <Fragment>
                        {(order.day_orders[0]
                            && order.day_orders[0].length !== 0)
                            || (order.not_purchased_positions.no_packs[0]
                                || order.not_purchased_positions.no_salesman[0])
                            ? (
                                <Fragment>
                                    <div style={styles.pageBlock}>
                                        <div>
                                            <ImportExportControl
                                                link={link}
                                                tableId={`check_lists/${checkListId}`}
                                                handleExport={link => this.handleExport(link)}
                                                handleLinkChange={link => this.setState({ link })}
                                                errorDialog={(title, msg) => this.showError(title, msg)}
                                                disableButton={this.disableButton()}
                                            />
                                            <Tooltip
                                                title="Сохранить данную версию чек-листа для отправки Шефу"
                                                aria-label="Save check-list"
                                            >
                                                <Button
                                                    style={styles.wideButton}
                                                    onClick={this.openSavePriceAnalysisDialog}
                                                    disabled={this.disableButton()}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Сохранить
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Получить ссылки на таблицы для отправки поставщикам"
                                                aria-label="Send to salesmen"
                                            >
                                                <Button
                                                    style={styles.wideButton}
                                                    onClick={this.getDocumentsForSalesmen}
                                                    disabled={this.disableButton()}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Отправить поставщикам
                                                </Button>
                                            </Tooltip>
                                            <Tooltip
                                                title="Сохранить и перейти к чек-листу"
                                                aria-label="Go to check-list"
                                            >
                                                <Button
                                                    style={styles.wideButton}
                                                    onClick={this.openSendPriceAnalysisDialog}
                                                    disabled={this.disableButton()}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    Перейти к чек-листу
                                                </Button>
                                            </Tooltip>
                                        </div>
                                    </div>
                                    <PriceAnalysisTable
                                        order={order}
                                        recalculate={this.onRecalculateCheckList}
                                    />
                                </Fragment>
                            )
                            : (
                                <Paper
                                    style={{
                                        marginTop: '40px',
                                        maxWidth: '800px',
                                        padding: '15px',
                                        textAlign: 'center',
                                        display: 'block',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    Для ресторана {
                                        selectedRestaurant.name
                                    } за {dateFormatted} нет данных.
                                </Paper>
                            )
                        }
                    </Fragment>
                )
                : (
                    <Paper
                        style={{
                            marginTop: '40px',
                            maxWidth: '300px',
                            padding: '15px',
                            textAlign: 'center',
                            display: 'block',
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        Выберите ресторан и дату
                    </Paper>
                )
            }
        </Fragment>
    )

    loadPriceAnalysisIfReady() {
        const { dateFormatted, selectedRestaurant, loading } = this.state;
        if (dateFormatted && selectedRestaurant && !loading) {
            this.setState({
                loading: true,
            });
            this.onCalculateCheckList();
        }
    }

    filterCheckLists(order) {
        const filteredPositions = order.day_orders.filter(order => order.length !== 0);
        order.day_orders = filteredPositions;
        return order;
    }

    disableButton() {
        const { loading } = this.state;
        if (loading) {
            return true;
        }
        return false;
    }

    showSnackBar(msg) {
        this.setState({
            snackBarOpen: true,
            snackBarMessage: msg,
        });
    }


    showLinks(title, links) {
        this.setState({
            linksDialogOpen: true,
            linksTitle: title,
            salesmenGsheetLinks: links,
        });
    }

    render() {
        const {
            selectedRestaurant, restaurants, date, dateFormatted, loading, order, checkListId, link,
            snackBarOpen, snackBarMessage, errorDialogOpen, errorText, errorTitle, chooseDialogOpen, chooseDialogParams,
            linksDialogOpen, linksTitle, salesmenGsheetLinks, restaurantCheckLists, chainCheckList,
        } = this.state;

        return (
            <Fragment>
                <App />
                {/*<Button*/}
                {/*    style={styles.wideButton}*/}
                {/*    onClick={this.onCalculateCheckListChain}*/}
                {/*    variant="contained"*/}
                {/*    color="primary"*/}
                {/*>*/}
                {/*    Посчитать для сети*/}
                {/*</Button>*/}
                {/*{this.getRestaurantsButtons()}*/}
                <div style={styles.pageBlock}>
                    <Snackbar
                        open={snackBarOpen}
                        message={snackBarMessage}
                        onClose={() => {
                            this.setState({ snackBarOpen: false, snackBarMessage: '' });
                        }}
                        autoHideDuration={4000}
                    />
                    <InfoDialog
                        open={errorDialogOpen}
                        dialogText={errorText}
                        dialogTitle={errorTitle}
                        onClose={() => this.setState({ errorDialogOpen: false })}
                    />
                    <ChooseDialog
                        open={chooseDialogOpen}
                        dialogParams={chooseDialogParams}
                        onCancel={() => this.setState({ chooseDialogOpen: false })}
                    />
                    <LinksDialog
                        open={linksDialogOpen}
                        dialogTitle={linksTitle}
                        links={salesmenGsheetLinks}
                        onCancel={() => this.setState({ linksDialogOpen: false })}
                    />
                    <Typography
                        style={styles.pageHeader}
                        variant="h4"
                    >
                        Анализ цен
                    </Typography>
                    <div style={{ ...styles.pageSettings, width: '900px' }}>
                        <RestaurantSelect
                            restaurants={restaurants}
                            selectedRestaurant={selectedRestaurant}
                            onRestaurantSelected={this.onRestaurantSelected}
                            disableButton
                        />
                        <DatePicker
                            tableName="price_analysis"
                            salesman={selectedRestaurant}
                            date={date}
                            onDateSelected={this.onDateSelected}
                            showError={this.showError}
                        />
                    </div>
                </div>
                {loading
                    ? (
                        <CircularProgress
                            style={{
                                position: 'absolute',
                                top: '0',
                                right: '0',
                                bottom: '0',
                                left: '0',
                                display: 'block',
                                margin: 'auto',
                            }}
                        />
                    )
                    : (
                        this.drawTable(order, link, checkListId, selectedRestaurant, dateFormatted)
                    )
                }
            </Fragment>
        );
    }
}

export default withRouter(PriceAnalysis);
