import React, { PureComponent } from 'react';
import { TableRow, TextField, Button, Checkbox } from '@material-ui/core';
import Select from 'react-select';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import styles from '../styles';

export default class AddPositionRow extends PureComponent {
    state = {
        id: null,
        selectedGroup: null,
        name: '',
        mark: '',
        selectedUnit: null,
        amount: '',
        pack: '',
        comment: '',
        is_zero_frequency: false
    };


    static defaultProps = {
        disabled: false,
        position: null,
    };

    static propTypes = {
        disabled: PropTypes.bool,
        position: PropTypes.shape({
            id: PropTypes.number,
            amount_from: PropTypes.number,
            amount_to: PropTypes.number,
            comment: PropTypes.string,
            group: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                sort_order: PropTypes.number,
            }),
            mark: PropTypes.string,
            name: PropTypes.string,
            offer: PropTypes.number,
            package_from: PropTypes.number,
            package_to: PropTypes.number,
            unit: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                sort_order: PropTypes.number,
            }),
            is_zero_frequency: PropTypes.bool,
        }),
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        units: PropTypes.arrayOf(PropTypes.object).isRequired,
        addPosition: PropTypes.func.isRequired,
    };


    componentDidMount() {
        const { position } = this.props;
        if (position) {
            const amount = position.amount_to !== null
                ? `${position.amount_from}-${position.amount_to}`
                : `${position.amount_from}`;

            const pack = position.package_to !== null
                ? `${position.package_from}-${position.package_to}`
                : `>${position.package_from}`;
            this.setState({
                id: position.id,
                selectedGroup: {
                    id: position.group.id,
                    name: position.group.name,
                },
                name: position.name,
                mark: position.mark,
                selectedUnit: {
                    id: position.unit.id,
                    name: position.unit.name,
                },
                amount,
                pack,
                comment: position.comment,
                is_zero_frequency: position.is_zero_frequency,
            });
        }
    }

    getGroupsOptions() {
        const { groups } = this.props;
        if (groups === null) {
            return [];
        }
        return groups.map(group => ({
            value: group.id,
            label: group.name,
        }));
    }

    getSelectedGroup() {
        const { selectedGroup } = this.state;
        if (selectedGroup === null) {
            return [];
        }
        return {
            value: selectedGroup.id,
            label: selectedGroup.name,
        };
    }

    getUnitsOptions() {
        const { units } = this.props;
        if (units === null) {
            return [];
        }
        return units.map(unit => ({
            value: unit.id,
            label: unit.name,
        }));
    }

    getSelectedUnit() {
        const { selectedUnit } = this.state;
        if (selectedUnit === null) {
            return [];
        }
        return {
            value: selectedUnit.id,
            label: selectedUnit.name,
        };
    }

    selectGroup = (selected) => {
        this.setState({
            selectedGroup: {
                id: selected.value,
                name: selected.label,
            },
        });
    };

    selectUnit = (selected) => {
        this.setState({
            selectedUnit: {
                id: selected.value,
                name: selected.label,
            },
        });
    };

    save = () => {
        const { addPosition } = this.props;
        addPosition(this.state);
    };

    handleTextChange = name => (e) => {
        this.setState({
            [name]: e.target.value,
        });
    };

    handleCheckboxChange = name => (e) => {
        this.setState({
            [name]: e.target.checked,
        });
    };

    render() {
        const {
            name, mark, amount, pack, comment, is_zero_frequency
        } = this.state;
        const { disabled } = this.props;
        return (
            <TableRow style={{ height: '50px' }}>
                <TableCell>
                    <Select
                        isDisabled={disabled}
                        placeholder="Группа"
                        value={this.getSelectedGroup()}
                        options={this.getGroupsOptions()}
                        onChange={this.selectGroup}
                        menuPortalTarget={document.querySelector('body')}
                        menuPlacement="top"
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Наименование"
                        disabled={disabled}
                        value={name}
                        onChange={this.handleTextChange('name')}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Марка"
                        value={mark}
                        onChange={this.handleTextChange('mark')}
                    />
                </TableCell>
                <TableCell>
                    <Select
                        placeholder="ед. изм."
                        value={this.getSelectedUnit()}
                        options={this.getUnitsOptions()}
                        onChange={this.selectUnit}
                        menuPortalTarget={document.querySelector('body')}
                        menuPlacement="top"
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Объем на неделю"
                        value={amount}
                        onChange={this.handleTextChange('amount')}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Упаковка"
                        value={pack}
                        onChange={this.handleTextChange('pack')}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        placeholder="Примечание поставщика"
                        value={comment}
                        onChange={this.handleTextChange('comment')}
                    />
                </TableCell>
                <TableCell style={{textAlign: 'center'}}>
                    <Checkbox
                        color="primary"
                        checked={is_zero_frequency}
                        onChange={this.handleCheckboxChange('is_zero_frequency')}
                        value="autoDistributeSwitcher"
                    />
                </TableCell>
                <TableCell style={{ minWidth: '100px' }}>
                    <Button
                        style={styles.tableButton}
                        variant="contained"
                        color="primary"
                        onClick={this.save}
                    >
                        {disabled ? 'Сохранить' : 'Добавить'}
                    </Button>
                </TableCell>
            </TableRow>
        );
    }
}
