import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import styles from '../styles';


export default function NavLink({ children, style, ...rest }) {
    return (
        <RouterNavLink activeStyle={{ ...styles.navLink, ...style }} {...rest}>
            {children}
        </RouterNavLink>
    );
}

NavLink.defaultProps = {
    children: null,
    style: {},
};

NavLink.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(PropTypes.string, PropTypes.number),
    ]),
    style: PropTypes.objectOf(PropTypes.string),
};
