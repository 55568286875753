import React from 'react';

const NotFound = () => (
    <div>
        <h3>404 страница не найдена</h3>
        <p>Извините, запрашиваемая страница не существует</p>
    </div>
);

export default NotFound;
