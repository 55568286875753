import React, { Component, Fragment } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';
import {
    Checkbox, Button, FormControlLabel, Paper, Tab, Tabs, Table, TableBody, TableRow, Typography, Tooltip, TableHead,
} from '@material-ui/core';
import { Error } from '@material-ui/icons/';
import moment from 'moment';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import withAuth from '../withAuth';
import ChefDayTab from './ChefDayTab';
import ChefPositionsSorting from './ChefPositionsSorting';
import {
    NEW, SENT, EDITED, APPROVED, SAVE_CHECK_LIST_TYPE, EXPORT_SORTING_TYPE,
} from '../common/constants';
import { round2, round4 } from '../common/utils';
import styles from '../styles';
import SortingSelect from './SortingSelect';

class ChefTable extends Component {
    signal = axios.CancelToken.source();

    state = {
        order: null,
        autoDistribute: true,
        sortingOpen: false,
        selectedSorting: null,
    };

    static propTypes = {
        order: PropTypes.shape({
            day_orders: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number,
                data: PropTypes.array,
                date: PropTypes.string,
                order: PropTypes.number,
                status: PropTypes.string,
            })),
            not_purchased_positions: PropTypes.objectOf(PropTypes.array),
            week_order: PropTypes.shape({
                data: PropTypes.array,
                date: PropTypes.string,
                order: PropTypes.number,
            }),
        }),
        selectedOrderDay: PropTypes.number.isRequired,
        checkListTime: PropTypes.string.isRequired,
        sortingPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
        role: PropTypes.string.isRequired,
        disabled: PropTypes.bool.isRequired,
        showSnackBar: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
        changeSelectedOrder: PropTypes.func.isRequired,
        chooseDialogOpen: PropTypes.func.isRequired,
        updateCheckList: PropTypes.func.isRequired,
        recalculate: PropTypes.func.isRequired,
        anotherOffers: PropTypes.arrayOf(PropTypes.object),
        salesmanChanged: PropTypes.func.isRequired,
        shouldUpdateTable: PropTypes.bool.isRequired,
        resetShouldUpdateTable: PropTypes.func.isRequired,
        setNeedSave: PropTypes.func.isRequired,
    };

    static defaultProps = {
        order: null,
        anotherOffers: [],
    };

    componentDidMount() {
        this.reloadPriceAnalysis();
    }

    componentDidUpdate = (prevProps) => {
        const { order, resetShouldUpdateTable } = this.props;
        const { shouldUpdateTable } = this.props;
        if (prevProps.order !== order || shouldUpdateTable) {
            this.reloadPriceAnalysis();
            resetShouldUpdateTable();
        }
    }

    componentWillUnmount() {
        this.signal.cancel('Запрос отменен');
    }

    onSaveCheckList(params) {
        const { chooseDialogOpen } = this.props;

        chooseDialogOpen(SAVE_CHECK_LIST_TYPE, params);
    }

    onSortingSelected = (sorting) => {
        this.setState({ selectedSorting: sorting });
    }

    getNextStatus(currentStatus) {
        if ([SENT, APPROVED].includes(currentStatus)) return EDITED;
        return currentStatus;
    }

    getDocumentButtons() {
        const { disabled, role, chooseDialogOpen } = this.props;

        if (role === 'admin') {
            return (
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Tooltip
                        title="Получить ссылки на таблицы для отправки поставщикам всего чек-листа"
                        aria-label="Send whole check-list to salesmen"
                    >
                        <div>
                            <Button
                                style={styles.wideButton}
                                onClick={
                                    () => chooseDialogOpen(EXPORT_SORTING_TYPE, { documentType: 'price_analysis' })
                                }
                                disabled={disabled}
                                variant="contained"
                                color="primary"
                            >
                                Таблицы всей закупки
                            </Button>
                        </div>
                    </Tooltip>

                    <Tooltip
                        title="Получить ссылки на таблицы по выбранному дню для конечной отправки поставщикам"
                        aria-label="Send final check-list for day to salesmen"
                    >
                        <div>
                            <Button
                                style={styles.wideButton}
                                onClick={() => chooseDialogOpen(EXPORT_SORTING_TYPE, { documentType: 'chef' })}
                                disabled={disabled}
                                variant="contained"
                                color="primary"
                            >
                                Таблицы по дням
                            </Button>
                        </div>
                    </Tooltip>
                </div>
            );
        }
        return '';
    }

    getSaveButton(order) {
        const {
            disabled, role,
        } = this.props;

        const currentDayOrder = this.getCurrentDayOrder();
        if (!currentDayOrder) return null;
        if (role === 'chef' && ![SENT, EDITED, APPROVED].includes(currentDayOrder.status)) return null;
        const status = this.getNextStatus(currentDayOrder.status);

        const params = {
            id: currentDayOrder.id,
            order,
            status,
            callback: this.reloadPriceAnalysis.bind(this),
        };

        if (currentDayOrder) {
            return (
                <Tooltip
                    title="Сохранить чек-лист. Сохранение вернет чек-листу статус 'Отредактирован'"
                    aria-label="Save check-list"
                >
                    <div>
                        <Button
                            style={styles.wideButton}
                            onClick={() => this.onSaveCheckList(params)}
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                        >
                            Сохранить
                        </Button>
                    </div>
                </Tooltip>
            );
        }

        return null;
    }

    getActionButton(order) {
        const {
            updateCheckList, disabled, role,
        } = this.props;

        const currentDayOrder = this.getCurrentDayOrder();

        if (!currentDayOrder) {
            return (
                <Tooltip
                    key="Error"
                    title="Выберите другие дни недели в настройках спроса или выберите другой чек-лист"
                    aria-label="Day order error"
                >
                    <div style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '300px',
                        justifyContent: 'space-between',
                        margin: '0 10px 10px',
                    }}
                    >
                        <Error />
                        <Typography variant="h6">Нет подходящего дня заказа</Typography>
                    </div>
                </Tooltip>
            );
        }

        if (currentDayOrder.status === NEW && role === 'admin') {
            return (
                <Tooltip
                    key="Send"
                    title="Отправить чек-лист за следующий день Шефу для редактирования"
                    aria-label="Send to Chef"
                >
                    <div>
                        <Button
                            style={styles.wideButton}
                            onClick={() => updateCheckList(currentDayOrder.id, order, SENT, this.reloadPriceAnalysis.bind(this))}
                            disabled={disabled}
                            variant="contained"
                            color="primary"
                        >
                            Отправить шеф-повару
                        </Button>
                    </div>
                </Tooltip>
            );
        }

        if (currentDayOrder.status === EDITED && role === 'admin') {
            return (
                <Tooltip
                    key="Approve"
                    title="Подтвердить изменения внесенные Шефом"
                    aria-label="Approve changes"
                >
                    <div>
                        <Button
                            style={styles.wideButton}
                            color="primary"
                            variant="contained"
                            onClick={() => updateCheckList(currentDayOrder.id, order, APPROVED, this.reloadPriceAnalysis.bind(this))}
                            disabled={disabled}
                        >
                            Подтвердить
                        </Button>
                    </div>
                </Tooltip>
            );
        }
        return null;
    }

    getSortingButtons() {
        const { order, sortingOpen } = this.state;
        const {
            role, disabled, chooseDialogOpen, showSnackBar, showError,
        } = this.props;

        const sortingButton = (
            <Button
                style={styles.wideButton}
                onClick={this.openSorting}
                disabled={disabled}
                variant="contained"
                color="primary"
            >
                Сортировка
            </Button>
        );

        if (sortingOpen) {
            return (
                <Fragment>
                    {sortingButton}
                    <ChefPositionsSorting
                        orderId={order.id}
                        chooseDialogOpen={chooseDialogOpen}
                        showSnackBar={showSnackBar}
                        showError={showError}
                    />
                </Fragment>
            );
        }
        if (role !== 'chef') {
            return null;
        }
        return sortingButton;
    }

    getDeadline() {
        const { checkListTime } = this.props;

        const time = checkListTime.split(':', 2);
        const hours = time[0];
        const minutes = time[1];
        const deadline = moment()
            .set('hour', hours)
            .set('minute', minutes)
            .set('second', 0);

        return deadline;
    }

    getCurrentDayOrder() {
        const { order } = this.state;

        const nextDay = moment();
        const deadline = this.getDeadline();
        if (nextDay.isAfter(deadline)) {
            nextDay.add(1, 'days');
        }
        const startOfDay = moment(nextDay, 'YYYY-MM-DD').startOf('day');
        const endOfDay = moment(nextDay, 'YYYY-MM-DD').endOf('day');

        let currentOrderDay = order.day_orders.filter(dayOrder => (
            moment(dayOrder.date).isBetween(startOfDay, endOfDay, null, [])
        ));

        if (currentOrderDay.length === 0) {
            currentOrderDay = order.day_orders.filter(dayOrder => (
                moment(dayOrder.date).isSameOrAfter(startOfDay)
            ));
        }

        return currentOrderDay[0];
    }

    getValueEqualToPack(position, value) {
        if (this.notEqualToPack(position.pack, value)) {
            if (this.isVolumeIncreased(position.volume.edited, value)) {
                const packCounter = Math.ceil(value / position.pack);
                value = packCounter * position.pack;
            } else {
                const packCounter = Math.floor(value / position.pack);
                value = packCounter * position.pack;
            }
        }

        return value;
    }

    reloadPriceAnalysis = () => {
        const { order } = this.props;
        this.setState({ order: cloneDeep(order) });
    }


    toggleDistribution = () => {
        const { autoDistribute } = this.state;
        if (!autoDistribute) {
            this.reloadPriceAnalysis();
        }
        this.setState({ autoDistribute: !autoDistribute });
    }

    openSorting = () => {
        const { sortingOpen } = this.state;
        this.setState({ sortingOpen: !sortingOpen });
    }

    changeVolume = (position, orderIndex, salesmanName, value) => {
        const { autoDistribute, order } = this.state;
        const { setNeedSave } = this.props;

        const salesman = order.day_orders[orderIndex].data.filter(salesman => salesman.name === salesmanName)[0];

        if (autoDistribute) {
            const { showSnackBar } = this.props;
            if (position.frequency === 0 && position.volume.edited !== parseFloat(value)) {
                showSnackBar('Позиция с частотой 0, для редактирования снимите галочку "Сохранять суммарный объем"');
            }

            const change = position.volume.edited - value;
            if (change === 0) return;

            const result = this.distributeVolumes(
                order, position, orderIndex, value, salesman,
            );

            if (result !== 'OK') {
                showSnackBar(result);
            }

            this.setState({ order });
        } else {
            const newTotal = salesman.total - (position.volume_price) + (value * position.price);
            // this.changeWeekPosition(order.week_order.data, position, value, salesman);

            let newValue;
            if (value > position.volume.edited) {
                newValue = Math.ceil(value / position.pack) * position.pack;
            } else {
                newValue = Math.floor(value / position.pack) * position.pack;
            }
            this.changePos(position, newValue);

            salesman.total = newTotal;
            this.setState({ order });
        }
        setNeedSave()
    };

    changeWeekVolume = (position, orderIndex, salesmanIndex, value) => {
        this.setState({ autoDistribute: false });
        const { order } = this.state;
        const salesman = order.week_order[salesmanIndex];

        value = parseFloat(value) || 0;
        const newTotal = salesman.total - position.volume_price + (value * position.price);

        this.changePos(position, value);
        salesman.total = newTotal;

        this.setState({ order });
    }

    distributeVolumes(order, position, orderIndex, value, salesman) {
        value = this.getValueEqualToPack(position, value);
        const change = round4(position.volume.edited - value);

        let sumVolume = change;
        let maxVolume = position.volume.initial;
        let dayCount = 0;
        // let error = null;
        order.day_orders.filter((day, idx) => {
            if (idx !== orderIndex) {
                day.data.map((sm) => {
                    if (sm.name === salesman.name) {
                        sm.positions.map((pos) => {
                            if (pos.name === position.name) {
                                sumVolume += pos.volume.edited;
                                maxVolume += pos.volume.initial;
                                dayCount += 1;
                            }
                            return pos;
                        });
                    }
                    // else if (sm.name !== '') {
                    //     sm.positions.map((pos) => {
                    //         if (pos.name === position.name && !error) {
                    //             error = 'Данная позиция в некоторые дни закупается у других поставщиков';
                    //         }
                    //         return pos;
                    //     });
                    // }
                    return sm;
                });
            }
            return day;
        });

        if (dayCount === 0) return 'Нельзя изменять объем позиции, если она одна';
        if (sumVolume < 0) {
            value = maxVolume;
            sumVolume = 0;
        }
        const newTotal = round4(salesman.total - (position.volume_price) + (value * position.price));

        const changesDict = {};
        const otherPacks = round4(Math.abs(sumVolume / position.pack));
        const packsForOne = Math.floor(otherPacks / dayCount);
        let sparePacks = otherPacks % dayCount;

        order.day_orders.filter((day, idx) => {
            if (idx !== orderIndex) {
                changesDict[idx] = packsForOne * position.pack;
                if (sparePacks > 0) {
                    changesDict[idx] += position.pack;
                    sparePacks -= 1;
                }
            }
            return day;
        });

        order.day_orders.filter((day, idx) => {
            if (idx !== orderIndex) {
                day.data.map((sm) => {
                    if (sm.name === salesman.name) {
                        sm.positions.map((pos) => {
                            if (pos.name === position.name) {
                                const newVolume = changesDict[idx];
                                const oldPos = { ...pos };
                                this.changePos(pos, newVolume);
                                const newPositionTotal = sm.total - oldPos.volume_price + pos.volume_price;
                                sm.total = Math.round(newPositionTotal);
                            }
                            return pos;
                        });
                    }
                    return sm;
                });
            }
            return day;
        });

        this.changePos(position, value);
        salesman.total = Math.round(newTotal);
        return 'OK';
        // return error;
    }

    notEqualToPack(pack, value) {
        const packRounded = Math.round((pack) * 10000) / 10000;
        const epsilon = 0.000001;
        if (value % packRounded > 0 - epsilon && value % packRounded < 0 + epsilon) {
            return false;
        }
        if ((value % packRounded > packRounded - epsilon && value % packRounded < packRounded + epsilon)) {
            return false;
        }
        return true;
    }

    changePos(position, value) {
        const { role } = this.props;
        position.volume.edited = round4(value);
        position.volume_price = round4(value * position.price);
        position.last_edit = role;
    }

    getMessage = (PreferredInfo, UniqueInfo) => {
        let preferredStr = '';
        let uniqueStr = '';
        if (PreferredInfo.length === 1) {
            preferredStr = `Содержит приоритетную позицию ${PreferredInfo}. `
        } else if (PreferredInfo.length > 1) {
            preferredStr = `Содержит приоритетные позиции ${PreferredInfo.join(', ')}. `
        }

        if (UniqueInfo.length === 1) {
            uniqueStr = `Содержит уникальную позицию ${UniqueInfo}. `
        } else if (PreferredInfo.length > 1) {
            uniqueStr = `Содержит уникальные позиции ${UniqueInfo.join(', ')}. `
        }

        return preferredStr + uniqueStr
    };

    // changeWeekPosition(weekOrder, position, value, salesman) {
    //     const change = value - position.volume.edited;
    //     weekOrder.map((sm) => {
    //         if (sm.name === salesman.name) {
    //             sm.positions.map((pos) => {
    //                 if (pos.name === position.name) {
    //                     pos.volume.edited += change;
    //                 }
    //                 return pos;
    //             });
    //         }
    //         return sm;
    //     });
    // }

    render() {
        const { order, autoDistribute, selectedSorting } = this.state;
        const {
            role, selectedOrderDay, checkListTime, sortingPositions, changeSelectedOrder, disabled, recalculate, anotherOffers, salesmanChanged,
        } = this.props;
        const buttons = (
            order && (
                <div
                    style={{
                        maxWidth: '900px',
                        display: 'flex',
                        flexWrap: 'wrap',
                        alignItems: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                    }}
                >
                    {this.getDocumentButtons()}
                    {this.getSaveButton(order)}
                    {this.getActionButton(order)}
                    <FormControlLabel
                        control={(
                            <Checkbox
                                color="primary"
                                checked={autoDistribute}
                                onChange={this.toggleDistribution}
                                value="autoDistributeSwitcher"
                                disabled={disabled}
                            />
                        )}
                        label="Сохранять суммарный объем"
                    />
                    {this.getSortingButtons()}
                    <SortingSelect
                        style={{ display: 'inline' }}
                        selectedSorting={selectedSorting}
                        onSortingSelected={this.onSortingSelected}
                    />
                </div>
            )
        );
        return (
            <Fragment>
                {order
                    && (
                        <Fragment>
                            {buttons}
                            {order.day_orders[0]
                                && order.day_orders[0].length !== 0
                                && order.day_orders.length > 1
                                && (
                                    <Tabs
                                        value={selectedOrderDay}
                                        onChange={changeSelectedOrder}
                                        indicatorColor="primary"
                                        textColor="primary"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                    >
                                        <Tab
                                            style={{ display: 'block', margin: '0 auto' }}
                                            value={0}
                                            label="Закупка на неделю"
                                        />

                                        {order.day_orders
                                            .map((dayOrder, idx) => (
                                                <Tab
                                                    style={{ display: 'block', margin: '0 auto' }}
                                                    key={dayOrder.id}
                                                    value={idx + 1}
                                                    label={dayOrder.date}
                                                />
                                            ))
                                        }
                                    </Tabs>
                                )
                            }
                            {order.week_order.data[0]
                                && order.week_order.data[0].length !== 0
                                && selectedOrderDay === 0
                                && (
                                    <ChefDayTab
                                        role={role}
                                        tabType="week"
                                        dayOrder={order.week_order}
                                        orderIndex={-1}
                                        checkListTime={checkListTime}
                                        sortingPositions={sortingPositions}
                                        changeVolume={this.changeWeekVolume}
                                        recalculate={recalculate}
                                        sortType={selectedSorting && selectedSorting.value}
                                        anotherOffers={anotherOffers}
                                        salesmanChanged={salesmanChanged}
                                    />
                                )
                            }
                            {order.day_orders
                                .filter((dayOrder, index) => (index + 1) === selectedOrderDay)
                                .map((dayOrder, orderIndex) => (
                                    <ChefDayTab
                                        key={dayOrder.id}
                                        role={role}
                                        tabType="day"
                                        dayOrder={dayOrder}
                                        orderIndex={selectedOrderDay - 1}
                                        checkListTime={checkListTime}
                                        sortingPositions={sortingPositions}
                                        changeVolume={this.changeVolume}
                                        recalculate={recalculate}
                                        sortType={selectedSorting && selectedSorting.value}
                                        anotherOffers={anotherOffers}
                                        salesmanChanged={salesmanChanged}
                                    />
                                ))
                            }
                            {role === 'admin'
                                && order.day_orders[0].data
                                && (
                                    <Paper style={styles.positionsTable}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={styles.boldCell}>Итог за неделю:</TableCell>
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell />
                                                    <TableCell style={styles.boldCell}>
                                                        {round2(order.day_orders.reduce((total, current) => {
                                                            const sum = current.data.reduce((total, current) => total + current.total, 0);
                                                            return total + sum;
                                                        }, 0))}
                                                    </TableCell>
                                                    <TableCell />
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                )
                            }
                            {order.not_passed_min_price && order.not_passed_min_price.by_grand_total.length !== 0
                            && (
                                <Fragment>
                                    <h5 style={{ margin: '15px 0 0 10px' }}>
                                        Поставщики, которые не могут преодолеть порог
                                    </h5>
                                    <Paper style={styles.positionsTable}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> Поставщик </TableCell>
                                                    <TableCell> Дата заказа </TableCell>
                                                    <TableCell> Дополнительно </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {order.not_passed_min_price.by_grand_total.map((msg, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{msg.name}</TableCell>
                                                        <TableCell>{msg.date}</TableCell>
                                                        <TableCell>
                                                            {this.getMessage(msg.preferred, msg.unique)}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Fragment>
                            )}
                            {order.not_passed_min_price && order.not_passed_min_price.actual.length !== 0
                            && (
                                <Fragment>
                                    <h5 style={{ margin: '15px 0 0 10px' }}>
                                        Поставщики, которым не удалось преодолеть порог
                                    </h5>
                                    <Paper style={styles.positionsTable}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell> Поставщик </TableCell>
                                                    <TableCell> Дата заказа </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {order.not_passed_min_price.actual.map((msg, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>{msg.name}</TableCell>
                                                        <TableCell>{msg.date}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </Paper>
                                </Fragment>
                            )}

                            {order.not_purchased_positions.no_salesman.length !== 0
                                && (
                                    <Fragment>
                                        <h5 style={{ margin: '15px 0 0 10px' }}>
                                            Позиции отсутствующие у продавцов:
                                        </h5>
                                        <Paper style={styles.positionsTable}>
                                            <Table>
                                                <TableBody>
                                                    {order.not_purchased_positions.no_salesman.map((position, index) => (
                                                        <TableRow key={index}>
                                                            <TableCell>{position}</TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Fragment>
                                )
                            }
                            {order.not_purchased_positions.no_packs.length !== 0
                                && (
                                    <Fragment>
                                        <h5 style={{ margin: '15px 0 0 10px' }}>
                                            Позиции без подходящих упаковок у продавцов:
                                        </h5>
                                        <Paper style={styles.positionsTable}>
                                            <Table>
                                                <TableBody>
                                                    {order.not_purchased_positions.no_packs
                                                        .map((position, index) => (
                                                            <TableRow key={index}>
                                                                <TableCell>{position}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                </TableBody>
                                            </Table>
                                        </Paper>
                                    </Fragment>
                                )
                            }
                        </Fragment>
                    )
                }
                {buttons}
            </Fragment>
        );
    }
}

export default withAuth(ChefTable);
