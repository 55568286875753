import React, { Component, Fragment } from 'react';
import { Button, TextField, TableRow } from '@material-ui/core';
import { ArrowUpward, ArrowDownward } from '@material-ui/icons';
import moment from 'moment';
// import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import withAuth from '../withAuth';
import TableCell from '../common/TableCell';
import { SENT, EDITED, APPROVED } from '../common/constants';
import styles from '../styles';
import SelectSalesman from './SelectSalesman';

class ChefRow extends Component {
    state = {
        editing: false,
        error: false,
        editedVolume: 0,
        editingWeek: false,
    };

    static propTypes = {
        position: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            volume: PropTypes.oneOfType([
                PropTypes.shape({
                    initial: PropTypes.number,
                    edited: PropTypes.number,
                }),
                PropTypes.arrayOf(PropTypes.shape({
                    initial: PropTypes.number,
                    edited: PropTypes.number,
                })),
            ]),
            unit_price: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.arrayOf(PropTypes.number),
            ]),
            volume_price: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.arrayOf(PropTypes.number),
            ]),
            salesman_name: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.arrayOf(PropTypes.string),
            ]),
        }).isRequired,
        orderIndex: PropTypes.number.isRequired,
        changeVolume: PropTypes.func.isRequired,
        date: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        checkListTime: PropTypes.string.isRequired,
        role: PropTypes.string.isRequired,
        anotherOffers: PropTypes.arrayOf(PropTypes.object),
        salesmanChanged: PropTypes.func.isRequired,
    };

    static defaultProps = {
        anotherOffers: [],
    }

    componentDidMount() {
        const { position } = this.props;
        this.setState({ editedVolume: position.volume.edited });
    }

    onEditButtonClick = () => {
        const { editing, editedVolume } = this.state;
        const { date } = this.props;
        if (date !== 'Закупка на неделю') {
            if (editing) {
                const { position, orderIndex, changeVolume } = this.props;
                changeVolume(position, orderIndex, position.salesman_name, editedVolume);
                this.setState({ editing: false });
            } else {
                this.setState({ editing: true });
            }
        } else {
            const { editingWeek } = this.state;
            this.setState({ editingWeek: !editingWeek });
        }
    }

    getPositionVolume(volume, lastEdit) {
        let asterisk = '';
        if (lastEdit === 'admin') {
            asterisk = ' *';
        }
        if (volume.edited !== volume.initial) {
            return `(${volume.initial}) ${volume.edited}${asterisk}`;
        }
        return volume.edited + asterisk;
    }

    getDeadline() {
        const { date, checkListTime } = this.props;

        const time = checkListTime.split(':', 2);
        const hours = time[0];
        const minutes = time[1];
        const deadline = moment(date, 'YYYY-MM-DD')
            .set('hour', hours)
            .set('minute', minutes)
            .set('second', 0);

        return deadline;
    }

    getArrow(position) {
        let arrow = '';
        let arrowStyle = { minWidth: '30px', width: '30px' };
        if (position.volume.initial < position.volume.edited) {
            arrow = <ArrowUpward style={{ width: '30px' }} />;
            arrowStyle = { ...styles.redIcon, ...arrowStyle };
        }
        if (position.volume.initial > position.volume.edited) {
            arrow = <ArrowDownward style={{ width: '30px' }} />;
            arrowStyle = { ...styles.greenIcon, ...arrowStyle };
        }

        return <TableCell style={arrowStyle}>{arrow}</TableCell>;
    }

    getPacksAmount(position) {
        if (position.pack === 0) return '';
        const packAmount = position.volume.edited / position.pack;
        return packAmount;
    }

    canEdit(status, salesmanName) {
        const { role } = this.props;
        const deadline = this.getDeadline();
        if (![SENT, EDITED, APPROVED].includes(status)) {
            return false;
        }
        if (salesmanName === '') {
            return false;
        }
        if (role !== 'admin' && moment().isAfter(deadline)) {
            return false;
        }
        if (moment().isAfter(deadline.endOf('day'))) {
            return false;
        }
        return true;
    }

    canEditWeek(salesmanName) {
        const { role, date } = this.props;
        const deadline = this.getDeadline();
        if (date !== 'Закупка на неделю') {
            return false;
        }
        if (salesmanName === '') {
            return false;
        }
        if (role !== 'admin' && moment().isAfter(deadline)) {
            return false;
        }
        if (moment().isAfter(deadline.endOf('day'))) {
            return false;
        }
        return true;
    }

    render() {
        const {
            editing, error, editedVolume, editingWeek,
        } = this.state;
        const {
            position, status, role, anotherOffers, salesmanChanged,
        } = this.props;
        const multiple = typeof (position.salesman_name) !== 'string';
        let volumeString;
        if (multiple) {
            const volumes = position.volume.map((volume, i) => this.getPositionVolume(volume, position.last_edit[i]));
            volumeString = volumes.join(', ');
        }
        
        return (
            <Fragment>
                <TableRow>
                    <TableCell>{position.id}</TableCell>
                    <TableCell>{position.group.name}</TableCell>
                    <TableCell>{position.name}</TableCell>
                    <TableCell>{position.mark}</TableCell>
                    <TableCell>{position.unit.name}</TableCell>
                    <TableCell>
                        {
                            multiple
                                ? position.pack.join(', ')
                                : position.pack
                        }
                    </TableCell>
                    <TableCell style={{ width: '140px' }}>
                        {
                            multiple
                                ? volumeString
                                : editing
                                    ? (
                                        <TextField
                                            autoFocus
                                            type="number"
                                            error={error}
                                            inputProps={{ min: '0', step: `${position.pack}` }}
                                            value={editedVolume}
                                            onChange={e => this.setState({ editedVolume: e.target.value })}
                                        />
                                    )
                                    : this.getPositionVolume(position.volume, position.last_edit)
                        }
                    </TableCell>
                    {this.getArrow(position)}
                    <TableCell>{position.frequency}</TableCell>
                    <TableCell>
                        {
                            multiple
                            ? position.salesman_name.join(', ')
                            : (editing || editingWeek) && role === 'admin'
                                    ? (
                                        <SelectSalesman
                                            positions={anotherOffers}
                                            selectedSalesman={position.salesman_name}
                                            onSalesmanSelected={salesmanChanged}
                                        />
                                    )
                                    : position.salesman_name
                        }
                    </TableCell>
                    {role === 'admin'
                        && (
                            <TableCell>
                                {
                                    multiple
                                        ? position.unit_price.join(', ')
                                        : position.unit_price > 0 && position.unit_price
                                }
                            </TableCell>
                        )
                    }
                    <TableCell>
                        {(this.canEdit(status, position.salesman_name) || this.canEditWeek(position.salesman_name) && !multiple)
                            ? (
                                <Button
                                    style={{ minWidth: '151px' }}
                                    color={editing || editingWeek ? 'primary' : 'default'}
                                    variant="contained"
                                    onClick={this.onEditButtonClick}
                                >
                                    {editing || editingWeek ? 'Завершить' : 'Редактировать'}
                                </Button>
                            )
                            : ''
                        }
                    </TableCell>
                </TableRow>
            </Fragment>
        );
    }
}

export default withAuth(ChefRow);
