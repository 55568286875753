import React, { memo } from 'react';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from '../styles';

function RestaurantSelect({
    restaurants, selectedRestaurant, disableButton, onRestaurantSelected, onAddRestaurant,
}) {
    function getRestaurantsOptions() {
        if (restaurants === null) {
            return [];
        }
        return restaurants.map(restaurant => ({
            value: restaurant.id,
            label: restaurant.name,
        }));
    }

    function getSelectedRestaurant() {
        const restaurant = selectedRestaurant;
        if (restaurant === null) {
            return null;
        }
        return {
            value: restaurant.id,
            label: restaurant.name,
        };
    }

    const selectRestaurant = (selected) => {
        onRestaurantSelected({
            id: selected.value,
            name: selected.label,
        });
    };


    return (
        <div
            style={{
                display: 'flex', flexWrap: 'wrap', alignItems: 'center',
            }}
        >
            <Select
                styles={styles.selectField}
                placeholder="Выберите ресторан"
                value={getSelectedRestaurant()}
                options={getRestaurantsOptions()}
                onChange={selectRestaurant}
            />
            {onAddRestaurant
                ? (
                    <Button
                        style={styles.regularButton}
                        disabled={disableButton}
                        variant="contained"
                        color="primary"
                        onClick={onAddRestaurant}
                    >
                        Добавить
                    </Button>
                )
                : null}
        </div>
    );
}

RestaurantSelect.defaultProps = {
    restaurants: [],
    selectedRestaurant: null,
    disableButton: false,
    onAddRestaurant: null,
};

RestaurantSelect.propTypes = {
    restaurants: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    })),
    selectedRestaurant: PropTypes.shape({
        value: PropTypes.number,
        label: PropTypes.string,
    }),
    disableButton: PropTypes.bool,
    onRestaurantSelected: PropTypes.func.isRequired,
    onAddRestaurant: PropTypes.func,
};

export default memo(RestaurantSelect);
