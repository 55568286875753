import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    IconButton, TableRow, TableCell,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddPreferredSalesmenRow from './AddPreferredSalesmenRow';

export default class PreferredSalesmenRow extends Component {
    state = {
        editing: false,
    };

    static propTypes = {
        position: PropTypes.shape({
            salesman_name: PropTypes.string,
            salesman: PropTypes.number,
            position: PropTypes.string,
            id: PropTypes.number,
        }).isRequired,
        deletePosition: PropTypes.func.isRequired,
        editPosition: PropTypes.func.isRequired,
        salesmen: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            min_price: PropTypes.number,
            sort_order: PropTypes.number,
        })).isRequired,
        offerPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
    }

    editPosition = async (position) => {
        const { editPosition } = this.props;
        const result = await editPosition(position);
        if (result === 'ERROR') return;
        this.setState({ editing: false });
    };

    render() {
        const { editing } = this.state;
        const { position, deletePosition, salesmen, offerPositions } = this.props;
        return (
            <Fragment>
                {
                    editing
                        ? (
                            <AddPreferredSalesmenRow
                                editing
                                salesmen={salesmen}
                                addPosition={this.editPosition}
                                position={position}
                                offerPositions={offerPositions}
                            />
                        )
                        : (
                            <TableRow>
                                <TableCell>
                                    {position.salesman_name}
                                </TableCell>
                                <TableCell>
                                    {position.position}
                                </TableCell>
                                <TableCell>
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => deletePosition(position)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => this.setState({ editing: true })}
                                        aria-label="Edit"
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )
                }
            </Fragment>
        );
    }
}
