import React, { Fragment } from 'react';
import {
    Paper, Table, TableBody, TableHead, TableRow,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import AddPositionRow from './AddPositionRow';
import DemandPositionRow from './DemandPositionRow';
import TableCell from '../common/TableCell';
import styles from '../styles';

export default function DemandPositions({
    positions, groups, units, addPosition, editPosition, deletePosition,
}) {
    return (
        <Fragment>
            <Paper style={styles.positionsTable}>

                <Table>
                    <TableBody>
                        <AddPositionRow
                            groups={groups}
                            units={units}
                            addPosition={addPosition}
                        />
                    </TableBody>
                    <TableHead>
                        <TableRow>
                            <TableCell styles={styles.positionsTableCell}>Группа</TableCell>
                            <TableCell>Наименование</TableCell>
                            <TableCell>Марка</TableCell>
                            <TableCell>ед. изм.</TableCell>
                            <TableCell>Объем на неделю</TableCell>
                            <TableCell>Упаковка</TableCell>
                            <TableCell>Примечание поставщика</TableCell>
                            <TableCell style={{textAlign: 'center'}}>Нулевая частота</TableCell>
                            <TableCell style={{ minWidth: '100px' }} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {positions.map(position => (
                            <DemandPositionRow
                                key={position.id}
                                position={position}
                                groups={groups}
                                units={units}
                                editPosition={editPosition}
                                deletePosition={deletePosition}
                            />
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Fragment>
    );
}

DemandPositions.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.object).isRequired,
    groups: PropTypes.arrayOf(PropTypes.object).isRequired,
    units: PropTypes.arrayOf(PropTypes.object).isRequired,
    addPosition: PropTypes.func.isRequired,
    editPosition: PropTypes.func.isRequired,
    deletePosition: PropTypes.func.isRequired,
};
