/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
if (!Array.prototype.flatMap) {
    Array.prototype.flatMap = function () {
        return Array.prototype.map.apply(this, arguments).flat(1);
    };
}

if (!Array.prototype.flat) {
    Array.prototype.flat = function (depth) {
        const flattend = [];
        (function flat(array, depth) {
            for (const el of array) {
                if (Array.isArray(el) && depth > 0) {
                    flat(el, depth - 1);
                } else {
                    flattend.push(el);
                }
            }
        }(this, Math.floor(depth) || 1));
        return flattend;
    };
}
