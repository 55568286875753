import axios from 'axios';
import Cookies from 'js-cookie';
import history from './history';

export default class AuthService {
    constructor() {
        this.login = this.login.bind(this);
        this.getUsername = this.getUsername.bind(this);
    }

    async login(email, password) {
        const csrfToken = Cookies.get('csrftoken');
        axios.defaults.xsrfCookieName = 'csrftoken';
        axios.defaults.xsrfHeaderName = 'X-CSRFToken';
        const data = {
            email,
            password,
        };
        const res = await axios({
            method: 'post',
            url: '/api-token-auth/',
            data,
            headers: {
                'X-CSRFToken': csrfToken,
            },
        });
        this.setToken(res.data.token);
        this.setUsername(res.data.username);
        const role = res.data.isRestaurantAdmin === true ? 'admin' : 'chef';
        this.setRole(role);
        return Promise.resolve(res);
    }

    loggedIn() {
        const token = this.getToken();
        return !!token;
    }

    setToken(token) {
        localStorage.setItem('token', token);
    }

    setUsername(username) {
        localStorage.setItem('username', username);
    }

    setRole(role) {
        localStorage.setItem('role', role);
    }

    getToken() {
        return localStorage.getItem('token');
    }

    getUsername() {
        return localStorage.getItem('username');
    }

    getRole() {
        return localStorage.getItem('role');
    }

    logout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        localStorage.removeItem('username');
        history.replace('/login');
    }
}
