import React from 'react';
import {
    Checkbox, TableRow, TableCell,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import styles from '../styles';

function DemandDeliveryRow({ frequency, toggleDeliveryDay }) {
    return (
        <TableRow>
            <TableCell style={{ width: '75px', padding: '0 0 0 10px' }}>
                {frequency.frequency}
            </TableCell>
            {frequency.weekdays.map(day => (
                <TableCell
                    style={styles.deliveriesTableCell}
                    key={day.name}
                >
                    <Checkbox
                        checked={day.is_active}
                        onChange={() => toggleDeliveryDay(day)}
                    />
                </TableCell>
            ))}
        </TableRow>
    );
}

DemandDeliveryRow.propTypes = {
    frequency: PropTypes.shape({
        frequency: PropTypes.number,
        restaurant: PropTypes.number,
        weekdays: PropTypes.array,
    }).isRequired,
    toggleDeliveryDay: PropTypes.func.isRequired,
};

export default DemandDeliveryRow;
