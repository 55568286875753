import React, { Component, Fragment } from 'react';
import { IconButton, TableRow } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import AddPositionRow from './AddPositionRow';
import TableCell from '../common/TableCell';

export default class DemandPositionRow extends Component {
    state = {
        editing: false,
    };

    static propTypes = {
        position: PropTypes.shape({
            id: PropTypes.number,
            amount_from: PropTypes.number,
            amount_to: PropTypes.number,
            comment: PropTypes.string,
            group: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                sort_order: PropTypes.number,
            }),
            mark: PropTypes.string,
            name: PropTypes.string,
            offer: PropTypes.number,
            package_from: PropTypes.number,
            package_to: PropTypes.number,
            unit: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                sort_order: PropTypes.number,
            }),
            is_zero_frequency: PropTypes.bool,
        }).isRequired,
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        units: PropTypes.arrayOf(PropTypes.object).isRequired,
        editPosition: PropTypes.func.isRequired,
        deletePosition: PropTypes.func.isRequired,
    };

    shouldComponentUpdate = (nextProps, nextState) => {
        const { editing } = this.state;
        const { position } = this.props;
        if (isEqual(position, nextProps.position) && editing === nextState.editing) {
            return false;
        }
        return true;
    }


    edit = (position) => {
        const { editPosition } = this.props;
        const result = editPosition(position);
        if (result === 'ERROR') return;
        this.setState({ editing: false });
    };

    render() {
        const { editing } = this.state;
        const {
            position, groups, units, deletePosition,
        } = this.props;
        return (
            <Fragment>
                {editing
                    ? (
                        <AddPositionRow
                            disabled
                            position={position}
                            groups={groups}
                            units={units}
                            addPosition={this.edit}
                        />
                    )
                    : (
                        <TableRow style={{ height: '50px' }}>
                            <TableCell>{position.group.name}</TableCell>
                            <TableCell>{position.name}</TableCell>
                            <TableCell>{position.mark}</TableCell>
                            <TableCell>{position.unit.name}</TableCell>
                            <TableCell>
                                {
                                    position.amount_to !== null
                                        ? `${position.amount_from}-${position.amount_to}`
                                        : `${position.amount_from}`
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    position.package_to !== null
                                        ? `${position.package_from}-${position.package_to}`
                                        : `>${position.package_from}`
                                }
                            </TableCell>
                            <TableCell>{position.comment}</TableCell>
                            <TableCell style={{textAlign: 'center'}}>{position.is_zero_frequency && 'да'}</TableCell>
                            <TableCell style={{ minWidth: '100px' }}>
                                <IconButton
                                    aria-label="Delete"
                                    onClick={() => deletePosition(position)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                                <IconButton
                                    aria-label="Edit"
                                    onClick={() => this.setState({ editing: true })}
                                >
                                    <EditIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    )
                }
            </Fragment>
        );
    }
}
