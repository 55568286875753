import React, { Component, Fragment } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import {
    Paper, Snackbar, CircularProgress, Typography, FormControlLabel, Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import App from '../App';
import withAuth from '../withAuth';
import AddSalesmanDialog from './AddSalesmanDialog';
import OfferSalesman from './OfferSalesman';
import OfferPositionsTable from './OfferPositionsTable';
import DatePicker from '../common/DatePicker';
import ImportExportControl from '../common/ImportExportControl';
import InfoDialog from '../common/InfoDialog';
import ChooseDialog from '../common/ChooseDialog';
import ImportFilterDialog from '../common/ImportFilterDialog';
import parseErrors from '../common/ErrorParsing';
import { OFFER_TYPE, CONTINUE, ADD_FILTERS } from '../common/constants';
import loadUnits from '../API_requests/UnitsAPI';
import loadGroups from '../API_requests/GroupsAPI';
import { loadSalesmen, addSalesman } from '../API_requests/SalesmenAPI';
import {
    loadOffer, addPosition, editPosition, deletePosition, handleImport, handleExport,
} from '../API_requests/OffersAPI';
import styles from '../styles';

class Offer extends Component {
    signal = axios.CancelToken.source()

    state = {
        link: '',
        addSalesmanDialogOpen: false,
        selectedSalesman: null,
        date: null,
        dateFormatted: null,
        salesmen: [],
        positions: [],
        units: [],
        groups: [],
        offerId: null, // we show positions table only when salesman and date are selected and offer is identified
        snackBarOpen: false,
        snackBarMessage: '',
        errorDialogOpen: false,
        errorText: '',
        errorTitle: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        filterDialogOpen: false,
        filtersText: '',
        filtersTitle: '',
        loading: false,
        selectedFilters: [],
        sortingByGroup: false,
    };


    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
    };

    componentDidMount() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const salesmanId = parseInt(params.get('salesman'));
        const dateFormatted = params.get('date');
        this.onLoadSalesmen(salesmanId);
        this.onLoadUnits();
        this.onLoadGroups();
        this.setDateFromParamsOrStorage(dateFormatted);
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    async onLoadOffer() {
        this.setState({ loading: true });
        const { selectedSalesman, dateFormatted, sortingByGroup } = this.state;
        const { history } = this.props;
        const params = new URLSearchParams({ salesman: selectedSalesman.id, date: dateFormatted }).toString();
        history.push(`/offer?${params}`);
        try {
            const data = { salesman: selectedSalesman.id, week: dateFormatted };
            const res = await loadOffer(data, this.signal.token);
            this.setState({
                loading: false,
                offerId: res.data.id,
                positions: this.sortPositions(res.data.offer_positions, sortingByGroup),
                link: res.data.gsheet_link,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке предложения', err);
            this.setState({
                loading: false,
                offerId: null,
                positions: [],
            });
        }
    }

    async onLoadSalesmen(salesmanId) {
        try {
            const res = await loadSalesmen(this.signal.token);
            this.setState({
                salesmen: res.data,
            }, () => {
                const { salesmen } = this.state;
                if (salesmanId) {
                    const selectedSalesman = salesmen.find(x => x.id === salesmanId);
                    if (selectedSalesman) {
                        this.setState({ selectedSalesman }, () => this.loadOfferIfReady());
                    }
                } else {
                    const selectedSalesman = localStorage.getItem('offerSelectedSalesman');
                    if (selectedSalesman) {
                        this.setState({
                            selectedSalesman: salesmen.find(x => x.id === parseInt(selectedSalesman)),
                        }, () => this.loadOfferIfReady());
                    }
                }
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке поставщиков', err);
        }
    }

    async onLoadUnits() {
        try {
            const res = await loadUnits(this.signal.token);
            this.setState({ units: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке единиц', err);
        }
    }

    async onLoadGroups() {
        try {
            const res = await loadGroups(this.signal.token);
            this.setState({
                groups: res.data,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке групп', err);
        }
    }

    onImport = (importType, filters = []) => {
        /**
         * Opens a modal window
         * @param {string} importType Type of import(used in demands)
         * @param {array} filters Filters array
         */

        if (filters.length > 0) {
            filters = filters.map(filter => ({ id: filter.value, name: filter.label, type: OFFER_TYPE }));
        }
        const chooseDialogParams = {
            dialogTitle: 'При импорте все позиции будут заменены на импортируемые',
            dialogText: 'Вы уверены, что хотите удалить все текущие позиции?',
            primaryButtonTitle: CONTINUE,
            secondaryButtonTitle: ADD_FILTERS,
            onPrimaryOption: this.onHandleImport,
            onSecondaryOption: this.addImportFilters,
        };
        this.setState({
            filterDialogOpen: false,
            chooseDialogOpen: true,
            chooseDialogParams,
            selectedFilters: filters,
        });
    }

    onSalesmanSelected(salesman) {
        const { selectedSalesman } = this.state;
        if (salesman === selectedSalesman) {
            return;
        }

        this.setState({
            selectedSalesman: salesman,
        }, () => {
            localStorage.setItem('offerSelectedSalesman', salesman.id);
            this.loadOfferIfReady();
        });
    }

    onDateSelected = (e) => {
        const { date } = this.state;
        if (!e || date === e) {
            return;
        }

        const dateFormatted = e.format('YYYY-MM-DD');
        this.setState({
            date: e,
            dateFormatted,
        }, () => {
            localStorage.setItem('offerDate', dateFormatted);
            this.loadOfferIfReady();
        });
    };

    onAddSalesman = async (name) => {
        try {
            const res = await addSalesman({ name }, this.signal.token);
            const { salesmen } = this.state;
            salesmen.push(res.data);
            this.setState({
                salesmen,
                selectedSalesman: {
                    id: res.data.id,
                    name: res.data.name,
                },
            });
            this.loadOfferIfReady();
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании поставщика', err);
        }
    }

    onHandleImport = async () => {
        const {
            link, offerId, selectedFilters, sortingByGroup,
        } = this.state;
        this.setState({
            chooseDialogOpen: false,
            filterDialogOpen: false,
            loading: true,
        });

        const data = {
            offer: offerId,
            sheet: link,
            filters: selectedFilters,
        };
        try {
            const res = await handleImport(data, this.signal.token);
            if (res.data.errors.length !== 0) {
                parseErrors(this.showError, 'Некоторые позиции не удалось импортировать', { response: res });
            }
            this.setState({
                positions: this.sortPositions(res.data.positions, sortingByGroup),
            }, () => this.showSnackBar('Данные успешно импортированы!'));
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при импорте', err);
        }
        this.setState({
            loading: false,
        });
    };

    onHandleExport = async (link) => {
        const { offerId } = this.state;
        const data = {
            offer: offerId,
            sheet: link,
        };
        this.setState({
            loading: true,
        });
        try {
            await handleExport(data, this.signal.token);
            this.showSnackBar('Данные успешно экспортированы!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при экспорте', err);
        }
        this.setState({
            loading: false,
        });
    };

    onAddPosition = async (position) => {
        const offerPosition = this.checkPosition(position);
        if (offerPosition === 'ERROR') return;
        try {
            const res = await addPosition(offerPosition, this.signal.token);
            const { positions } = this.state;
            positions.push(res.data);
            this.setState({
                positions,
            });
            this.showSnackBar('Позиция добавлена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Не удалось добавить позицию', err);
        }
    };

    onEditPosition = async (position) => {
        const offerPosition = this.checkPosition(position);
        if (offerPosition === 'ERROR') return 'ERROR';
        try {
            const res = await editPosition(offerPosition, this.signal.token);
            const { positions } = this.state;
            const newPositions = positions.map((pos) => {
                if (pos.id === res.data.id) {
                    return res.data;
                }
                return pos;
            });
            this.setState({
                positions: newPositions,
            });
            this.showSnackBar('Позиция сохранена');
            return 'OK';
        } catch (err) {
            if (axios.isCancel(err)) {
                return 'ERROR';
            }
            parseErrors(this.showError, 'Не удалось сохранить позицию', err);
            return 'ERROR';
        }
    };

    setDateFromParamsOrStorage(dateFormatted) {
        if (!dateFormatted) {
            dateFormatted = localStorage.getItem('offerDate');
        }
        const storageValues = {};
        if (dateFormatted !== null) {
            storageValues.dateFormatted = dateFormatted;
            storageValues.date = moment(dateFormatted);
        }
        if (Object.keys(storageValues).length > 0 && storageValues.date.isValid()) {
            this.setState(storageValues, () => this.loadOfferIfReady());
        }
    }

    setAddSalesmanDialog(open) {
        this.setState({
            addSalesmanDialogOpen: open,
        });
    }

    handleLinkChange = (link) => {
        this.setState({
            link,
        });
    };

    handlePriceChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value,
        });
    };

    closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ snackBarOpen: false, snackBarMessage: '' });
    };

    onDeletePosition = async (position) => {
        try {
            await deletePosition(position.id, this.signal.token);
            const { positions } = this.state;
            const newPositions = positions.filter(pos => pos.id !== position.id);
            this.setState({
                positions: newPositions,
            });
            this.showSnackBar('Позиция удалена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Не удалось удалить позицию', err);
        }
    };

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    }

    addImportFilters = () => {
        this.setState({
            chooseDialogOpen: false,
            filterDialogOpen: true,
            filtersText: '',
            filtersTitle: 'Позиции содержащие данные подстроки не будут импортированы',
        });
    }

    toggleSorting = () => {
        const { sortingByGroup, positions } = this.state;
        const sortedPositions = this.sortPositions(positions, !sortingByGroup);
        this.setState({ positions: sortedPositions, sortingByGroup: !sortingByGroup });
    }

    sortPositions(positions, sortingByGroup) {
        if (sortingByGroup) {
            return this.sortByGroup(positions);
        }
        return this.sortById(positions);
    }

    sortById(positions) {
        const sortedPositions = positions.sort((nextPos, pos) => {
            if (nextPos.id < pos.id) {
                return -1;
            }
            if (nextPos.id > pos.id) {
                return 1;
            }
            return 0;
        });
        return sortedPositions;
    }

    sortByGroup(positions) {
        const sortedPositions = positions.sort((nextPos, pos) => {
            if (nextPos.group.sort_order < pos.group.sort_order) {
                return -1;
            }
            if (nextPos.group.sort_order > pos.group.sort_order) {
                return 1;
            }
            return 0;
        });
        return sortedPositions;
    }

    loadOfferIfReady() {
        const { selectedSalesman, dateFormatted } = this.state;
        if (selectedSalesman && dateFormatted) {
            this.onLoadOffer();
        }
    }

    showSnackBar(msg) {
        this.setState({
            snackBarOpen: true,
            snackBarMessage: msg,
        });
    }

    checkPosition(position) {
        const requiredFields = ['selectedGroup', 'name', 'selectedUnit', 'unitSize', 'pack', 'unitPrice'];
        const requiredFieldsNames = ['группа', 'наименование', 'единица измерения', 'Размер ТЕ', 'упаковка',
            'цена за ТЕ'];
        for (let i = 0; i < requiredFields.length; i += 1) {
            const name = requiredFields[i];
            if (position[name] === null || position[name] === '') {
                this.showError('Ошибка при сохранении позиции', `Нужно заполнить поле "${requiredFieldsNames[i]}"`);
                return 'ERROR';
            }
        }
        const pack = position.pack.split(',');
        const unitPrice = position.unitPrice.split(',');
        if (parseFloat(position.unitSize) <= 0
            || pack.some(item => parseFloat(item) <= 0)
            || unitPrice.some(item => parseFloat(item) <= 0)) {
            this.showError('Ошибка при сохранении позиции',
                'Поля "Размер ТЕ", "Цена за ТЕ" и "Упаковка " не могут быть равны 0');
            return 'ERROR';
        }
        if (
            (pack.length
                !== unitPrice.length)) {
            this.showError("Кол-во элементов в полях 'Упаковка' и 'Цена за товарную единицу' не совпадает!");
            return 'ERROR';
        }
        const { offerId } = this.state;
        return {
            id: position.id,
            offer: offerId,
            group: position.selectedGroup.id,
            name: position.name,
            mark: position.mark,
            unit: position.selectedUnit.id,
            unit_size: position.unitSize,
            pack,
            unit_price: unitPrice,
            comment: position.comment,
            status: position.status,
        };
    }

    render() {
        const {
            offerId, positions, groups, units, snackBarOpen, snackBarMessage,
            addSalesmanDialogOpen, errorDialogOpen, errorText, errorTitle,
            chooseDialogOpen, chooseDialogParams, filterDialogOpen, filtersText, filtersTitle,
            salesmen, selectedSalesman, date, link, loading, sortingByGroup,
        } = this.state;
        return (
            <Fragment>
                <App />
                <div style={styles.pageBlock}>
                    <Snackbar
                        open={snackBarOpen}
                        message={snackBarMessage}
                        onClose={(event, reason) => this.closeSnackbar(event, reason)}
                        autoHideDuration={4000}
                    />
                    <AddSalesmanDialog
                        open={addSalesmanDialogOpen}
                        addSalesman={name => this.onAddSalesman(name)}
                        onClose={() => this.setAddSalesmanDialog(false)}
                    />
                    <InfoDialog
                        open={errorDialogOpen}
                        dialogText={errorText}
                        dialogTitle={errorTitle}
                        onClose={() => this.setState({ errorDialogOpen: false })}
                    />
                    <ChooseDialog
                        open={chooseDialogOpen}
                        dialogParams={chooseDialogParams}
                        onCancel={() => this.setState({ chooseDialogOpen: false })}
                    />
                    <ImportFilterDialog
                        open={filterDialogOpen}
                        filterText={filtersText}
                        filterTitle={filtersTitle}
                        type={OFFER_TYPE}
                        showError={this.showError}
                        onContinue={this.onImport}
                        onCancel={() => this.setState({
                            filterDialogOpen: false,
                            chooseDialogOpen: true,
                            selectedFilters: [],
                        })}
                    />
                    <Typography
                        style={styles.pageHeader}
                        variant="h4"
                    >
                        Предложения поставщиков
                    </Typography>
                    <div style={styles.pageSettings}>
                        <OfferSalesman
                            salesmen={salesmen}
                            selectedSalesman={selectedSalesman}
                            onAddSalesman={() => this.setAddSalesmanDialog(true)}
                            onSalesmanSelected={salesman => this.onSalesmanSelected(salesman)}
                            disableButton={loading}
                        />
                        <DatePicker
                            tableName="offer"
                            salesman={selectedSalesman}
                            date={date}
                            onDateSelected={this.onDateSelected}
                            showError={this.showError}
                        />
                        <FormControlLabel
                            style={{ margin: '0 0 0 -5px', maxWidth: '180px' }}
                            control={(
                                <Checkbox
                                    color="primary"
                                    checked={sortingByGroup}
                                    onChange={this.toggleSorting}
                                    value="autoDistributeSwitcher"
                                />
                            )}
                            label="Сортировка по группам"
                        />
                    </div>
                </div>
                {offerId !== null || loading
                    ? (
                        <div
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            <ImportExportControl
                                link={link}
                                tableId={`offers/${offerId}`}
                                onImport={this.onImport}
                                handleExport={link => this.onHandleExport(link)}
                                handleLinkChange={link => this.handleLinkChange(link)}
                                disableButton={loading}
                                errorDialog={(title, msg) => this.showError(title, msg)}
                            />
                            {loading
                                ? (
                                    <CircularProgress style={styles.circularProgress} />
                                )
                                : (
                                    <OfferPositionsTable
                                        positions={positions}
                                        groups={groups}
                                        units={units}
                                        addPosition={this.onAddPosition}
                                        editPosition={this.onEditPosition}
                                        deletePosition={this.onDeletePosition}
                                    />
                                )
                            }
                        </div>
                    )
                    : (
                        <Paper
                            style={{
                                marginTop: '40px',
                                maxWidth: '300px',
                                padding: '15px',
                                textAlign: 'center',
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                            }}
                        >
                            Выберите поставщика и дату
                        </Paper>
                    )
                }
            </Fragment>
        );
    }
}

export default withRouter(withAuth(Offer));
