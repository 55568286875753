import React from 'react';
import withAuth from '../withAuth';
import BaseObject from '../common/BaseObject';

function Chains() {
    return (
        <BaseObject
            baseUrl="chains"
            title="Сети ресторанов"
        />
    );
}

export default withAuth(Chains);
