import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    TextField,
    Checkbox,
    FormControlLabel,
} from '@material-ui/core';


function BaseObjectSettings({
    open, updatingEl, dialogTitle, dialogText, changeElementValue, onSave, onCancel,
}) {
    return (
        <Dialog
            open={open}
            onBackdropClick={onCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent className="dialog">
                <DialogContentText>
                    {dialogText}
                </DialogContentText>
                <TextField
                    style={{ marginRight: '20px' }}
                    helperText="Наименование"
                    value={updatingEl.name}
                    onChange={e => changeElementValue(e, 'name')}
                />
                <TextField
                    helperText="Порядок сортировки"
                    value={updatingEl.sort_order}
                    onChange={e => changeElementValue(e, 'sort_order')}
                />
                {updatingEl.min_price >= 0
                    && (
                        <Fragment>
                            <TextField
                                style={{ marginRight: '20px' }}
                                helperText="Цена минимального заказа"
                                value={updatingEl.min_price}
                                onChange={e => changeElementValue(e, 'min_price')}
                            />
                            <TextField
                                helperText="Цена доставки"
                                value={updatingEl.delivery_price}
                                onChange={e => changeElementValue(e, 'delivery_price')}
                            />
                            <TextField
                                style={{ marginRight: '20px' }}
                                helperText="email"
                                type="email"
                                value={updatingEl.email}
                                onChange={e => changeElementValue(e, 'email')}
                            />
                            <TextField
                                helperText="Частота"
                                value={updatingEl.frequency}
                                onChange={e => changeElementValue(e, 'frequency')}
                            />
                            <FormControlLabel
                                control={
                                    (
                                        <Checkbox
                                            color="primary"
                                            checked={updatingEl.must_pass_min_price}
                                            onChange={e => changeElementValue(e, 'must_pass_min_price')}
                                        />
                                    )
                                }
                                label="Должен всегда проходить порог"
                            />
                        </Fragment>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={onSave}
                >
                    Сохранить
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default BaseObjectSettings;

BaseObjectSettings.defaultProps = {
    open: false,
    updatingEl: null,
    dialogText: '',
};

BaseObjectSettings.propTypes = {
    open: PropTypes.bool,
    updatingEl: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        sort_order: PropTypes.number,
        min_price: PropTypes.number,
        delivery_price: PropTypes.number,
        must_pass_min_price: PropTypes.bool,
    }),
    dialogTitle: PropTypes.string.isRequired,
    dialogText: PropTypes.string,
    changeElementValue: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};
