import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadPreferredSalesmen = async (cancelToken) => {
    const res = await axios({
        url: '/api/preferred_salesmen/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addPreferredSalesman = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/preferred_salesmen/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editPreferredSalesman = async (data, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/preferred_salesmen/${data.id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deletePreferredSalesman = async (id, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/preferred_salesmen/${id}/`,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
