import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
} from '@material-ui/core';
import Select from 'react-select';
import RE from '../common/RFC 2822';
import styles from '../styles';

export default function EditChefDialog({
    open, restaurants, editingEl, dialogTitle, changeElementValue, showSnackBar, editChef, onCancel,
}) {
    function getRestaurantsOptions() {
        if (restaurants === null) {
            return [];
        }

        return restaurants.map(restaurant => ({
            value: restaurant.id,
            label: restaurant.name,
        }));
    }

    const getSelectedRestaurant = () => {
        const { restaurant } = editingEl;
        if (restaurant === null) {
            return null;
        }

        return {
            value: restaurant.id,
            label: restaurant.name,
        };
    };

    const validateFields = (e) => {
        const { email, password, passwordConfirm } = editingEl;
        if (email === '') {
            showSnackBar('Почта не должна быть пустой!');
            return;
        }
        if (!RE.test(email)) {
            showSnackBar('Неправильный формат почты!');
            return;
        }
        if (password) {
            if (password !== passwordConfirm) {
                showSnackBar('Пароли не совпадают');
                return;
            }
            // if (password.length < 8) {
            //     showSnackBar('Пароль слишком короткий!');
            //     return;
            // }
        }
        editChef(e);
    };

    return (
        <Dialog
            open={open}
            onBackdropClick={onCancel}
            onEscapeKeyDown={onCancel}
            aria-labelledby="responsive-dialog-title"
        >
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent
                className="dialog"
                style={{ height: '350px' }}
            >
                <TextField
                    style={{ marginRight: '20px', marginBottom: '15px' }}
                    helperText="Имя"
                    value={editingEl.name}
                    onChange={e => changeElementValue(e, 'name')}
                />
                <TextField
                    style={{ marginBottom: '15px' }}
                    helperText="Почта"
                    type="email"
                    value={editingEl.email}
                    onChange={e => changeElementValue(e, 'email')}
                />
                <TextField
                    style={{ marginRight: '20px', marginBottom: '15px' }}
                    helperText="Пароль"
                    type="password"
                    onChange={e => changeElementValue(e, 'password')}
                />
                <TextField
                    style={{ marginBottom: '15px' }}
                    helperText="Повторите пароль"
                    type="password"
                    onChange={e => changeElementValue(e, 'passwordConfirm')}
                />
                <Select
                    styles={styles.selectField}
                    maxMenuHeight={170}
                    isClearable
                    placeholder="Ресторан"
                    value={getSelectedRestaurant()}
                    options={getRestaurantsOptions()}
                    onChange={e => changeElementValue(e, 'restaurant')}
                    required
                />
                <Typography style={{ marginTop: '20px' }} variant="body2" gutterBottom>
                    Оставьте поле пароля пустым, если не хотите его изменять.<br />
                    Минимальная длина пароля - 8 символов. <br />
                    Пароль не может состоять только из цифр. <br />
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={validateFields}
                >
                    Сохранить
                </Button>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={onCancel}
                >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const restaurant = PropTypes.shape({
    chef: PropTypes.number,
    id: PropTypes.number,
    name: PropTypes.string,
    sort_order: PropTypes.number,
});

EditChefDialog.defaultProps = {
    open: false,
    restaurants: null,
};

EditChefDialog.propTypes = {
    open: PropTypes.bool,
    restaurants: PropTypes.arrayOf(restaurant),
    editingEl: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        email: PropTypes.string,
        restaurant,
    }).isRequired,
    dialogTitle: PropTypes.string.isRequired,
    changeElementValue: PropTypes.func.isRequired,
    showSnackBar: PropTypes.func.isRequired,
    editChef: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,

};
