import React, { Component, Fragment } from 'react';
import {
    CircularProgress, Paper, Snackbar, Typography,
} from '@material-ui/core';
import axios from 'axios';
import PropTypes from 'prop-types';
import moment from 'moment';
import App from '../App';
import ChooseDialog from '../common/ChooseDialog';
import InfoDialog from '../common/InfoDialog';
import LinksDialog from '../common/LinksDialog';
import parseErrors from '../common/ErrorParsing';
import RestaurantSelect from '../common/RestaurantSelect';
import ChefTable from './ChefTable';
import withAuth from '../withAuth';
import SelectCheckList from './SelectCheckList';
import CheckListStatuses from './CheckListStatuses';
import { loadSettings } from '../API_requests/SettingsAPI';
import {
    saveCheckList, loadChefCheckLists, getDocumentsForSalesman, recalculateCheckList, changePosition,
} from '../API_requests/CheckListsAPI';
import { saveOrder } from '../API_requests/OrdersAPI';
import { updateDayOrdersData } from '../API_requests/DayOrdersAPI';
import { loadRestaurantForCheckLists } from '../API_requests/RestaurantsAPI';
import { loadSalesmen, editSalesman } from '../API_requests/SalesmenAPI';
import { loadSortingPositions, importCheckList } from '../API_requests/SortingPositionsAPI';
import {
    WEEK, EDITED, SAVE_CHECK_LIST_TYPE, IMPORT_SORTING_TYPE, EXPORT_SORTING_TYPE, CONTINUE,
    EXPORT_TO_GOOGLE, EXPORT_TO_EXCEL,
} from '../common/constants';
import styles from '../styles';
import SalesmanEmailsModal from './SalesmanEmailsModal';
import { loadOffers } from '../API_requests/OffersAPI';
import {Prompt} from "react-router-dom"


class Chef extends Component {
    signal = axios.CancelToken.source();

    state = {
        checkLists: [],
        order: { day_orders: [], not_purchased_positions: {}, week_order: [] },
        orderStatus: '',
        checkListId: null,
        checkListIsViewed: false,
        restaurants: null,
        selectedRestaurant: null,
        checkListSalesmen: [],
        dateFormatted: null,
        snackBarOpen: false,
        snackBarMessage: '',
        errorDialogOpen: false,
        errorTitle: '',
        errorText: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        linksDialogOpen: false,
        linksTitle: '',
        salesmenGsheetLinks: [],
        salesmenEmailsDialogOpen: false,
        salesmenEmailsParams: { documentType: '' },
        selectedOrderDay: 0,
        loading: false,
        checkListTime: '00:00:00',
        sortingPositions: [],
        salesmen: [],
        offers: [],
        shouldUpdateTable: false,
        needSave: false,
    };

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func,
        }).isRequired,
        location: PropTypes.shape({
            search: PropTypes.string,
        }).isRequired,
        role: PropTypes.string.isRequired,
    };

    componentDidMount() {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const restaurantId = parseInt(params.get('restaurant'));
        this.onLoadSettings();
        this.onLoadRestaurants(restaurantId);
    }

    componentWillMount() {
        onbeforeunload = e => {
            const { needSave } = this.state;
            if (needSave) {
                e.preventDefault();
                e.returnValue = '';
            }
        }
    }

    componentWillUnmount() {
        this.signal.cancel();
        onbeforeunload = null
    }

    async onLoadSalesmenAndOffers(params) {
        try {
            const res = await loadSalesmen(this.signal.token);
            this.setState({
                salesmen: res.data,
            }, () => this.onLoadOffers(params));
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке поставщиков', err);
        }
    }

    onLoadOffers = async (params) => {
        try {
            const res = await loadOffers(params, this.signal.token);
            const { salesmen } = this.state;
            const flatData = res.data.flatMap((offer) => {
                const salesman = salesmen.find(e => e.id === offer.salesman);
                const newPositions = offer.offer_positions.map(position => ({ ...position, salesman: offer.salesman, salesman_name: salesman.name, frequency: salesman.frequency }));
                return newPositions;
            });
            this.setState({ offers: flatData });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке предложений поставщиков', err);
        }
    }

    onSalesmanChanged = async (newPos) => {
        const {
            selectedOrderDay, order, selectedRestaurant, dateFormatted,
        } = this.state;
        const type = selectedOrderDay !== 0 ? 'day' : 'week';
        const curOrder = type === 'week' ? order : order.day_orders[selectedOrderDay - 1];
        const week_order = type === 'day' ? order.week_order : null;
        const data = {
            restaurant_id: selectedRestaurant.id,
            week: dateFormatted,
            order_type: type,
            order: curOrder,
            week_order: week_order,
            new_pos: newPos,
        };
        try {
            const res = await changePosition(data, this.signal.token);
            const { new_day_order, new_week_order } = res.data;
            if (type === 'day') {
                order.day_orders.forEach((_order, index) => {
                    if (_order.id === new_day_order.id) {
                        order.day_orders[index] = new_day_order;
                    }
                });
            } else {
                order.day_orders = new_day_order;
            }
            order.week_order = new_week_order;
            this.setState({ order, shouldUpdateTable: true, needSave: true });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при замене поставщика', err);
        }
    }

    onLoadSettings = async () => {
        try {
            const res = await loadSettings(this.signal.token);
            this.setState({ checkListTime: res.data[0].check_list_time });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.setState({ checkListTime: '00:00:00' });
            parseErrors(this.showError, 'Произошла ошибка при загрузке дней недели', err);
        }
    }

    async onLoadRestaurants(restaurantId) {
        try {
            const res = await loadRestaurantForCheckLists(this.signal.token);
            const { role } = this.props;
            if (role === 'chef') {
                if (res.data.length === 0) {
                    this.showError('Не назначен ресторан', 'Вам не назначен ресторан');
                    return;
                }
                this.setState({ restaurants: res.data, selectedRestaurant: res.data[0] },
                    () => this.onLoadChefCheckLists(res.data[0].id));
                this.onLoadSortingPositions();
            } else {
                if (res.data.length === 0) {
                    this.showError(
                        'Отсутствуют рестораны',
                        'Вы можете добавить их во вкладке "Основное" - "Рестораны"',
                    );
                    return;
                }
                this.setState({
                    restaurants: res.data,
                }, () => {
                    const { restaurants } = this.state;
                    if (restaurantId) {
                        const selectedRestaurant = restaurants.find(x => x.id === restaurantId);
                        if (selectedRestaurant) {
                            this.setState(
                                { selectedRestaurant }, () => this.onLoadChefCheckLists(selectedRestaurant.id),
                            );
                        }
                    }
                });
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке ресторанов', err);
        }
    }

    onLoadChefCheckLists = async (restaurantId) => {
        const { location: { search } } = this.props;
        const params = new URLSearchParams(search);
        const dateFormatted = params.get('date');
        try {
            const data = {
                restaurant_id: restaurantId,
            };
            const res = await loadChefCheckLists(data, this.signal.token);
            this.setState({ checkLists: res.data }, () => this.onCheckListSelected(dateFormatted));
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке чек-листов', err);
            this.setState({
                checkLists: [],
                order: { day_orders: [], not_purchased_positions: {}, week_order: {} },
                orderStatus: '',
                checkListId: null,
                checkListIsViewed: false,
                dateFormatted: null,
            });
        }
    }

    onLoadSortingPositions = async () => {
        try {
            const res = await loadSortingPositions(this.signal.token);
            this.setState({ sortingPositions: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке сортировочных позиций', err);
            this.setState({ sortingPositions: [] });
        }
    }

    onSetViewed = async () => {
        try {
            const { checkListId, checkListIsViewed, orderStatus } = this.state;
            const { role } = this.props;
            if (checkListIsViewed && orderStatus === EDITED) {
                const data = { check_list_id: checkListId, is_viewed: false };
                await saveCheckList(data, this.signal.token);
            }
            if (role === 'admin' && !checkListIsViewed && orderStatus === EDITED) {
                const data = { check_list_id: checkListId, is_viewed: true };
                await saveCheckList(data, this.signal.token);
            }
        } catch (err) {
            parseErrors(this.showError, 'Произошла ошибка при обновлении статуса', err);
        }
    }

    onSaveCheckList = async (params) => {
        this.setState({ chooseDialogOpen: false });
        try {
            const { checkListId } = this.state;
            const {
                id, order, status, callback,
            } = params;
            const data = {
                order_id: order.id,
                check_list_id: checkListId,
                is_viewed: false,
            };
            await saveOrder(data, this.signal.token);
            this.updateCheckList(id, order, status, callback);
            this.abortNeedSave()
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении чек-листа', err);
        }
    }

    setNeedSave = () => {
        this.setState({ needSave: true });
    };

    abortNeedSave = () => {
        this.setState({ needSave: false });
    };

    handleSortingPositionsImport = async (link) => {
        this.setState({ loading: true, chooseDialogOpen: false });
        const { order, selectedRestaurant } = this.state;
        try {
            const data = { sheet: link, order: order.id };
            await importCheckList(data, this.signal.token);
            await this.onLoadSortingPositions();
            await this.onLoadChefCheckLists(selectedRestaurant.id);
            this.showSnackBar('Данные для сортировки успешно импортированы');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при импорте', err);
        }
        this.setState({ loading: false });
    }

    onGetChefDocuments = async (documentType, withExcel = false) => {
        try {
            this.setState({ loading: true, chooseDialogOpen: false, salesmenEmailsDialogOpen: false });
            const { order, selectedRestaurant, selectedOrderDay } = this.state;

            let orders;
            if (documentType === 'price_analysis') {
                orders = order.week_order;
            } else {
                orders = selectedOrderDay === 0
                    ? order.week_order
                    : order.day_orders[selectedOrderDay - 1];
            }

            const data = {
                orders,
                restaurant: selectedRestaurant,
                document_type: documentType,
                with_excel: withExcel,
            };
            const res = await getDocumentsForSalesman(data, this.signal.token);
            this.showLinks('Ссылки на документы для поставщиков', res.data.links);
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании документов', err);
        }
        this.setState({ loading: false });
    }

    updateDayOrderStatus = (order, status, id) => {
        const { selectedOrderDay, orderStatus } = this.state;
        let newOrderStatus = orderStatus;
        const updatedDayOrders = order.day_orders.map((dayOrder, idx) => {
            if (dayOrder.id === id) {
                if (idx === selectedOrderDay - 1) {
                    newOrderStatus = status;
                }
                dayOrder.status = status;
            }
            return dayOrder;
        });

        order.day_orders = updatedDayOrders;
        this.setState({ order, orderStatus: newOrderStatus });
    }

    changeSelectedOrder = (event, value) => {
        const { order } = this.state;
        let orderStatus = '';
        if (value !== 0) {
            orderStatus = order.day_orders[value - 1].status;
        } else {
            orderStatus = WEEK;
        }

        this.setState({
            selectedOrderDay: value,
            orderStatus,
        }, () => this.onSetViewed());
    };

    onRestaurantSelected = (restaurant) => {
        const { selectedRestaurant } = this.state;
        if (restaurant === selectedRestaurant) {
            return;
        }

        const { history } = this.props;
        const params = new URLSearchParams({ restaurant: restaurant.id }).toString();
        history.push(`/chef?${params}`);

        this.setState({
            selectedRestaurant: restaurant,
            dateFormatted: null,
            checkLists: [],
        }, () => this.onLoadChefCheckLists(restaurant.id));
    };

    onCheckListSelected = async (selectedDate) => {
        const { selectedRestaurant, checkLists } = this.state;

        if (!selectedDate) {
            return;
        }

        const { history } = this.props;
        const params = new URLSearchParams({ restaurant: selectedRestaurant.id, date: selectedDate }).toString();
        history.push(`/chef?${params}`);

        const selectedCheckList = checkLists.filter(item => this.findCheckList(selectedDate, item.week))[0];
        if (selectedCheckList) {
            let selectedOrderDay;
            let orderStatus;
            if (selectedCheckList.order.day_orders.length > 1) {
                selectedOrderDay = 0;
                orderStatus = WEEK;
            } else {
                selectedOrderDay = 1;
                orderStatus = selectedCheckList.order.day_orders[0].status;
            }

            this.setState({
                dateFormatted: selectedDate,
                selectedOrderDay,
                order: this.filterCheckLists(selectedCheckList.order),
                orderStatus,
                checkListId: selectedCheckList.id,
                checkListIsViewed: selectedCheckList.is_viewed,
                checkListSalesmen: await this.onLoadCheckListSalesmen(selectedCheckList.order.week_order.data),
            }, () => {
                this.onSetViewed();
                this.onLoadSalesmenAndOffers({ week: selectedDate });
            });
        }
    };

    onRecalculateCheckList = async (salesman, date) => {
        try {
            const { selectedRestaurant, dateFormatted, order } = this.state;
            this.setState({ loading: true });
            const data = {
                salesman,
                restaurant_id: selectedRestaurant.id,
                week: dateFormatted,
                order_id: order.id,
                date,
            };
            const res = await recalculateCheckList(data, this.signal.token);
            this.setState({ order: res.data.order, loading: false, needSave: true });
            if (res.data.errors.length !== 0) {
                parseErrors(this.showError, 'Не удалось выполнить некоторые варианты перерасчета', res.data.errors);
            }
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при пересчете чек-листа', err);
            this.setState({ loading: false });
        }
    }

    saveEmail = async (data) => {
        try {
            await editSalesman(data);
            this.showSnackBar('Ссылка сохранена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении ссылки', err);
            this.setState({ loading: false });
        }
    }

    changeEmail = (email, id) => {
        const { checkListSalesmen } = this.state;
        checkListSalesmen.map((salesman) => {
            if (salesman.id === id) {
                salesman.email = email;
            }
            return salesman;
        });
        this.setState({ checkListSalesmen });
    }

    resetShouldUpdateTable = () => {
        this.setState({ shouldUpdateTable: false });
    }

    onLoadCheckListSalesmen = async (weekOrder) => {
        const { role } = this.props;
        if (role !== 'admin') return [];
        try {
            const res = await loadSalesmen(this.signal.token);
            const checkListSalesmen = this.getCheckListSalesmen(res.data, weekOrder);
            return checkListSalesmen;
        } catch (err) {
            if (axios.isCancel(err)) {
                return [];
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке поставщиков', err);
            this.setState({ loading: false });
        }
        return [];
    }

    getCheckListSalesmen = (allSalesmen, weekOrder) => {
        const orderSalesmenNames = weekOrder.map(s => s.name);
        const checkListSalesmen = allSalesmen.filter(salesman => orderSalesmenNames.includes(salesman.name));
        return checkListSalesmen;
    }

    onChooseDialogOpen = (type, params) => {
        /**
         * Opens a modal window
         * @param {string} type Type of import
         * @param {object} params An object for saving import parameters
         */

        if (type === SAVE_CHECK_LIST_TYPE) {
            const { selectedRestaurant, dateFormatted } = this.state;
            const chooseDialogParams = {
                dialogTitle: `Прошлый сохраненный чек-лист для ресторана ${selectedRestaurant.name} за 
                ${dateFormatted} будет удален`,
                dialogText: 'Вы уверены, что хотите заменить чек-лист на новый?',
                primaryButtonTitle: CONTINUE,
                onPrimaryOption: () => this.onSaveCheckList(params),
            };

            this.setState({
                chooseDialogOpen: true,
                chooseDialogParams,
            });
            return;
        }
        if (type === IMPORT_SORTING_TYPE) {
            const chooseDialogParams = {
                dialogTitle: 'Прошлая сортировка будет удалена',
                dialogText: 'Вы уверены, что хотите импортировать сортировку?',
                primaryButtonTitle: CONTINUE,
                onPrimaryOption: () => this.handleSortingPositionsImport(params.link),
            };
            this.setState({
                chooseDialogOpen: true,
                chooseDialogParams,
            });
        }
        if (type === EXPORT_SORTING_TYPE) {
            const chooseDialogParams = {
                dialogTitle: 'Выберите тип экспорта',
                dialogText: '',
                primaryButtonTitle: EXPORT_TO_GOOGLE,
                secondaryButtonTitle: EXPORT_TO_EXCEL,
                onPrimaryOption: () => this.onGetChefDocuments(params.documentType),
                onSecondaryOption: () => this.setState({
                    salesmenEmailsDialogOpen: true,
                    chooseDialogOpen: false,
                    salesmenEmailsParams: { documentType: params.documentType },
                }),
            };
            this.setState({
                chooseDialogOpen: true,
                chooseDialogParams,
            });
        }
    };

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorTitle: title,
            errorText: msg,
        });
    }

    updateCheckList = async (currentDayOrderId, order, status, callback) => {
        const data = {
            id: currentDayOrderId,
            order,
            status,
        };

        try {
            await updateDayOrdersData(data, this.signal.token);
            this.showSnackBar('Чек-лист успешно обновлен');
            this.updateDayOrderStatus(order, status, data.id);
            callback();
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при обновлении чек-листа', err);
        }
    }

    findCheckList(date, week) {
        const dateMoment = moment(date, 'YYYY-MM-DD');
        const weekMoment = moment(week.week_start, 'YYYY-MM-DD');
        const endWeekMoment = moment(week.week_end, 'YYYY-MM-DD');
        if (dateMoment.isBetween(weekMoment, endWeekMoment, null, '[]')) {
            return true;
        }
        return false;
    }

    filterCheckLists(order) {
        const filteredPositions = order.day_orders.filter(order => order.data.length !== 0);
        order.day_orders = filteredPositions;
        return order;
    }

    disableButton() {
        const { loading } = this.state;
        if (loading) {
            return true;
        }
        return false;
    }

    showSnackBar(msg) {
        this.setState({
            snackBarOpen: true,
            snackBarMessage: msg,
        });
    }

    showLinks(title, links) {
        this.setState({
            linksDialogOpen: true,
            linksTitle: title,
            salesmenGsheetLinks: links,
        });
    }

    render() {
        const {
            checkLists, order, restaurants, checkListSalesmen, selectedRestaurant, dateFormatted, snackBarOpen, snackBarMessage,
            errorDialogOpen, errorText, errorTitle, chooseDialogOpen, chooseDialogParams,
            linksDialogOpen, linksTitle, salesmenEmailsDialogOpen, salesmenEmailsParams,
            // customDialogOpen, customDialogTitle, customDialogText, customDialogActions,
            salesmenGsheetLinks, selectedOrderDay, orderStatus, checkListIsViewed, loading, checkListTime,
            sortingPositions, offers, shouldUpdateTable, needSave
        } = this.state;
        const { role } = this.props;
        const restaurantName = selectedRestaurant ? selectedRestaurant.name : '';
        return (
            <Fragment>
                <App />
                <Prompt when={needSave} message={"Внесенные изменения будут потеряны. Продолжить?"}/>
                <Snackbar
                    open={snackBarOpen}
                    message={snackBarMessage}
                    onClose={() => {
                        this.setState({ snackBarOpen: false, snackBarMessage: '' });
                    }}
                    autoHideDuration={4000}
                />
                <InfoDialog
                    open={errorDialogOpen}
                    dialogText={errorText}
                    dialogTitle={errorTitle}
                    onClose={() => this.setState({ errorDialogOpen: false })}
                />
                <ChooseDialog
                    open={chooseDialogOpen}
                    dialogParams={chooseDialogParams}
                    onCancel={() => this.setState({ chooseDialogOpen: false })}
                />
                <LinksDialog
                    open={linksDialogOpen}
                    dialogTitle={linksTitle}
                    links={salesmenGsheetLinks}
                    onCancel={() => this.setState({ linksDialogOpen: false })}
                />
                <SalesmanEmailsModal
                    open={salesmenEmailsDialogOpen}
                    salesmen={checkListSalesmen || []}
                    changeEmail={this.changeEmail}
                    saveEmail={this.saveEmail}
                    onContinue={() => this.onGetChefDocuments(salesmenEmailsParams.documentType, true)}
                    onCancel={() => this.setState({ salesmenEmailsDialogOpen: false, chooseDialogOpen: true })}
                />
                {/* <CustomizableDialog
                    open={customDialogOpen}
                    dialogTitle={customDialogTitle}
                    dialogText={customDialogText}
                    DialogActions={customDialogActions}
                /> */}
                <div
                    style={styles.pageBlock}
                >
                    <Typography style={styles.pageHeader} variant="h4">
                        Чек-лист{restaurantName && dateFormatted && `
                         для ресторана ${restaurantName} за ${dateFormatted}`}
                    </Typography>
                    <div style={{ display: 'flex', flexWrap: 'wrap', width: '900px' }}>
                        {role === 'admin'
                            && (
                                <RestaurantSelect
                                    restaurants={restaurants}
                                    selectedRestaurant={selectedRestaurant}
                                    onRestaurantSelected={this.onRestaurantSelected}
                                />

                            )
                        }
                        <SelectCheckList
                            restaurant={selectedRestaurant}
                            dateFormatted={dateFormatted}
                            checkLists={checkLists}
                            onCheckListSelected={this.onCheckListSelected}
                        />
                        {order && role === 'admin'
                            && (
                                <CheckListStatuses
                                    orderStatus={orderStatus}
                                    isViewed={checkListIsViewed}
                                />
                            )
                        }
                    </div>
                </div>
                {loading
                    ? (
                        <CircularProgress style={styles.circularProgress} />
                    )
                    : (
                        <Fragment>
                            {(selectedRestaurant === null || dateFormatted === null)
                                ? (
                                    <Paper
                                        style={{
                                            marginTop: '40px',
                                            maxWidth: '800px',
                                            padding: '15px',
                                            textAlign: 'center',
                                            display: 'block',
                                            marginLeft: 'auto',
                                            marginRight: 'auto',
                                        }}
                                    >
                                        Ресторан или дата не выбраны
                                    </Paper>
                                )
                                : (
                                    <Fragment>
                                        {(order.day_orders[0]
                                            && order.day_orders[0].length !== 0)
                                            || order.not_purchased_positions.no_packs[0]
                                            || order.not_purchased_positions.no_salesman[0]
                                            ? (
                                                <ChefTable
                                                    order={order}
                                                    selectedOrderDay={selectedOrderDay}
                                                    checkListTime={checkListTime}
                                                    sortingPositions={sortingPositions}
                                                    disabled={this.disableButton()}
                                                    showSnackBar={msg => this.showSnackBar(msg)}
                                                    showError={((title, msg) => this.showError(title, msg))}
                                                    chooseDialogOpen={
                                                        (type, params) => this.onChooseDialogOpen(type, params)
                                                    }
                                                    updateCheckList={this.updateCheckList}
                                                    changeSelectedOrder={this.changeSelectedOrder}
                                                    recalculate={this.onRecalculateCheckList}
                                                    anotherOffers={offers}
                                                    salesmanChanged={this.onSalesmanChanged}
                                                    shouldUpdateTable={shouldUpdateTable}
                                                    resetShouldUpdateTable={this.resetShouldUpdateTable}
                                                    setNeedSave={this.setNeedSave}
                                                />
                                            )
                                            : (
                                                <Paper style={{
                                                    marginTop: '40px',
                                                    maxWidth: '800px',
                                                    padding: '15px',
                                                    textAlign: 'center',
                                                    display: 'block',
                                                    marginLeft: 'auto',
                                                    marginRight: 'auto',
                                                }}
                                                >
                                                    Для ресторана {restaurantName} нет данных за {dateFormatted}
                                                </Paper>
                                            )
                                        }
                                    </Fragment>
                                )}
                        </Fragment>
                    )
                }
            </Fragment>
        );
    }
}

export default withAuth(Chef);
