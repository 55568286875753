// import axios from '../common/api_config';
import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadDemand = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/demands/get_or_create/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const saveLink = async (demandId, link, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/demands/${demandId}/`,
        data: { gsheet_link: link },
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadDemandFrequencies = async (data, cancelToken) => {
    const res = await axios({
        url: '/api/demand_frequencies/',
        method: 'get',
        params: data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};


export const addPosition = async (demandPosition, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/demand_positions/',
        data: demandPosition,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editPosition = async (demandPosition, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/demand_positions/${demandPosition.id}/`,
        data: demandPosition,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deletePosition = async (positionId, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/demand_positions/${positionId}/`,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addFrequency = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: 'api/demand_frequencies/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const editFrequency = async (data, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `api/demand_frequencies/${data.id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deleteFrequency = async (frequencyId, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/demand_frequencies/${frequencyId}/`,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const handleImport = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/demands/import_sheet/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const handleExport = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/demands/export_sheet/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
