import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const loadData = async (baseUrl, cancelToken) => {
    const res = await axios({
        url: `/api/${baseUrl}/`,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const addEl = async (baseUrl, data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: `/api/${baseUrl}/`,
        cancelToken,
        data,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const updateEl = async (baseUrl, id, data, cancelToken) => {
    const res = await axios({
        method: 'patch',
        url: `/api/${baseUrl}/${id}/`,
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};

export const deleteEl = async (baseUrl, id, cancelToken) => {
    const res = await axios({
        method: 'delete',
        url: `/api/${baseUrl}/${id}/`,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
    return res;
};
