import React, { Component } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
    Button, CircularProgress, TextField, Tooltip,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircle';
import parseErrors from '../common/ErrorParsing';
import { loadChefInfo, editChef } from '../API_requests/ChefsAPI';
import { exportCheckList } from '../API_requests/SortingPositionsAPI';
import { IMPORT_SORTING_TYPE } from '../common/constants';
import styles from '../styles';

class ChefPositionsSorting extends Component {
    signal = axios.CancelToken.source();

    state = {
        link: '',
        chefId: null,
        loading: false,
        success: false,
    }

    static propTypes = {
        orderId: PropTypes.number.isRequired,
        chooseDialogOpen: PropTypes.func.isRequired,
        showSnackBar: PropTypes.func.isRequired,
        showError: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.onLoadLink();
    }

    componentWillUnmount() {
        this.signal.cancel();
    }

    onLoadLink = async () => {
        try {
            const res = await loadChefInfo(this.signal.token);
            this.setState({ link: res.data.sort_link, chefId: res.data.id });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { showError } = this.props;
            parseErrors(showError, 'Произошла ошибка при загрузке ссылки', err);
        }
    }

    saveLink = async () => {
        const { link, chefId } = this.state;
        try {
            this.setState({
                success: false,
                loading: true,
            });
            const data = {
                sort_link: link,
            };

            await editChef(data, chefId, this.signal.token);
            this.timer = setTimeout(() => {
                this.setState({
                    loading: false,
                    success: true,
                });
            }, 1000);
            this.timer = setTimeout(() => {
                this.setState({
                    success: false,
                });
            }, 3000);
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            const { showError } = this.props;
            parseErrors(showError, 'Произошла ошибка при сохранении ссылки', err);
            this.setState({ loading: false });
        }
    };


    handleLinkChange = (e) => {
        const { value: link } = e.target;
        this.setState({ link });
    }

    onImport = (link) => {
        const { chooseDialogOpen } = this.props;
        const params = { link };
        chooseDialogOpen(IMPORT_SORTING_TYPE, params);
    }

    handleExport = async (link) => {
        const { orderId, showSnackBar, showError } = this.props;
        try {
            const data = { sheet: link, order: orderId };
            await exportCheckList(data, this.signal.token);
            showSnackBar('Данные для сортировки успешно экспортированы');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(showError, 'Произошла ошибка при экспорте', err);
        }
    }

    handleImportClick = () => {
        const { link } = this.state;
        const { showError } = this.props;
        if (link === '') {
            showError('При импорте произошла ошибка', 'Вы не ввели ссылку на таблицу');
            return;
        }
        this.onImport(link);
    }

    handleExportClick = () => {
        const { link } = this.state;
        const { showError } = this.props;
        if (link === '') {
            showError('При экспорте произошла ошибка', 'Вы не ввели ссылку на таблицу');
            return;
        }
        this.handleExport(link);
    }

    render() {
        const {
            link, loading, success,
        } = this.state;
        return (
            <div
                style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    maxWidth: '900px',
                    alignItems: 'center',
                }}
            >
                <TextField
                    style={{ margin: '0 10px 10px 0' }}
                    value={link}
                    onChange={this.handleLinkChange}
                    onBlur={this.saveLink}
                    helperText="Ссылка на таблицу с открытым доступом для редактирования"
                // disabled={disableButton}
                />
                {success
                    && (
                        <CheckIcon style={{ margin: '0px 3px 0 -5px', width: '27px' }} color="primary" />
                    )}
                {loading
                    && (
                        <CircularProgress
                            style={{ margin: '0px 5px 0 0px', width: '20px' }}
                            size={20}
                        />
                    )}
                <Tooltip
                    title="Экспортировать данные в Google таблицу"
                    aria-label="Export to Google sheets"
                >
                    <div>
                        <Button
                            // disabled={disableButton}
                            style={styles.wideButton}
                            onClick={this.handleExportClick}
                            variant="contained"
                            color="primary"
                        >
                            Экспорт для сортировки
                        </Button>
                    </div>
                </Tooltip>
                <Tooltip
                    title="Импортировать данные из Google таблицы"
                    aria-label="Import from Google sheets"
                >
                    <div>
                        <Button
                            // disabled={disableButton}
                            style={styles.wideButton}
                            onClick={this.handleImportClick}
                            variant="contained"
                            color="primary"
                        >
                            Импорт для сортировки
                        </Button>
                    </div>
                </Tooltip>
            </div>
        );
    }
}

export default ChefPositionsSorting;
