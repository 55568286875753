import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import selectStyles from './selectStyles';

export default function SelectSalesman({ positions, selectedSalesman, onSalesmanSelected }) {
    function getSalesmanOptions() {
        return positions.map(position => ({
            value: position.id,
            label: `${position.salesman_name}: размер ТЕ: ${position.unit_size}, цена: ${position.unit_price}, упаковка: ${position.pack}`,
        }));
    }

    const selectSalesman = (selected) => {
        onSalesmanSelected({
            offer_position_id: selected.value,
            name: selected.label,
        });
    };

    const getSelectedSalesman = () => {
        if (!selectedSalesman) {
            return null;
        }
        return {
            label: selectedSalesman,
        };
    };

    return (
        <Select
            styles={selectStyles}
            placeholder="Поставщик"
            value={getSelectedSalesman()}
            options={getSalesmanOptions()}
            onChange={selectSalesman}
            menuPlacement="top"
            menuPortalTarget={document.querySelector('body')}
        />
    );
}

SelectSalesman.propTypes = {
    positions: PropTypes.arrayOf(PropTypes.object),
    selectedSalesman: PropTypes.string.isRequired,
    onSalesmanSelected: PropTypes.func.isRequired,
};

SelectSalesman.defaultProps = {
    positions: [],
};
