import React, { Component } from 'react';
import { Paper, Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import DemandPositions from './DemandPositions';
import DemandFrequency from './DemandFrequency';
import DemandDeliveryWeekdays from './DemandDeliveryWeekdays';

export default class DemandTabs extends Component {
    state = {
        selected: 0,
    };

    static propTypes = {
        positions: PropTypes.arrayOf(PropTypes.object).isRequired,
        groups: PropTypes.arrayOf(PropTypes.object).isRequired,
        units: PropTypes.arrayOf(PropTypes.object).isRequired,
        frequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
        deliveryDates: PropTypes.shape({
            table_head: PropTypes.array,
            table_body: PropTypes.array,
        }).isRequired,
        addPosition: PropTypes.func.isRequired,
        addFrequency: PropTypes.func.isRequired,
        editPosition: PropTypes.func.isRequired,
        editFrequency: PropTypes.func.isRequired,
        deletePosition: PropTypes.func.isRequired,
        deleteFrequency: PropTypes.func.isRequired,
        toggleDeliveryDay: PropTypes.func.isRequired,
        onSaveDeliveryDates: PropTypes.func.isRequired,
    };

    handleChange = (event, value) => {
        this.setState({
            selected: value,
        });
    };

    render() {
        const { selected } = this.state;
        const {
            positions, groups, units, frequencies, deliveryDates, addPosition, editPosition, deletePosition,
            addFrequency, editFrequency, deleteFrequency, toggleDeliveryDay, onSaveDeliveryDates,
        } = this.props;
        return (
            <div style={{ marginTop: '15px' }}>
                <Paper style={{ paddingBottom: '-10px' }}>
                    <Tabs
                        value={selected}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                    >
                        <Tab value={0} label="Меню" />
                        {/*<Tab value={1} label="Частота" />*/}
                        <Tab value={2} label="Дни заказа" />
                    </Tabs>
                    {selected === 0
                        && (
                            <DemandPositions
                                positions={positions}
                                groups={groups}
                                units={units}
                                addPosition={addPosition}
                                editPosition={editPosition}
                                deletePosition={deletePosition}
                            />
                        )}
                    {/*selected === 1
                        && (
                            <DemandFrequency
                                positions={positions}
                                frequencies={frequencies}
                                groups={groups}
                                addFrequency={addFrequency}
                                editFrequency={editFrequency}
                                deleteFrequency={deleteFrequency}
                            />
                        )*/}
                    {selected === 2
                        && (
                            <DemandDeliveryWeekdays
                                deliveryDates={deliveryDates}
                                toggleDeliveryDay={toggleDeliveryDay}
                                onSaveDeliveryDates={onSaveDeliveryDates}
                            />
                        )}
                </Paper>
            </div>
        );
    }
}
