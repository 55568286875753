import React, { Component } from 'react';
import axios from 'axios';
import {
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Divider,
    TextField,
    Button,
    Paper,
    Snackbar,
    Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import App from '../App';
import InfoDialog from './InfoDialog';
import ChooseDialog from './ChooseDialog';
import parseErrors from './ErrorParsing';
import BaseObjectSettings from './BaseObjectSettings';
import {
    loadData, addEl, updateEl, deleteEl,
} from '../API_requests/BaseObjectsAPI';
import { CONTINUE } from './constants';
import styles from '../styles';

class BaseObject extends Component {
    signal = axios.CancelToken.source()

    state = {
        data: [],
        newName: '',
        updatingEl: {},
        isUpdate: false,
        snackBarOpen: false,
        snackBarMessage: '',
        errorDialogOpen: false,
        errorText: '',
        errorTitle: '',
        chooseDialogOpen: false,
        chooseDialogParams: { dialogTitle: '', primaryButtonTitle: CONTINUE },
        chooseText: '',
        chooseTitle: '',
        elementId: '',
    };


    componentDidMount() {
        this.onLoadData();
    }

    componentWillUnmount() {
        this.signal.cancel();
    }


    onDelete(elId, elName, title) {
        let titleFormatted = '';
        switch (title) {
            case 'Поставщики':
                titleFormatted = 'поставщика, с ним';
                break;
            case 'Рестораны':
                titleFormatted = 'ресторана, с ним';
                break;
            case 'Группы товаров для закупок':
                titleFormatted = 'группы, с ней';
                break;
            default:
                titleFormatted = 'единицы, с ней';
        }
        const chooseDialogParams = {
            dialogTitle: `При удалении ${titleFormatted} удалятся все позиции, без возможности восстановления.`,
            dialogText: `Вы уверены, что хотите удалить ${elName}?`,
            primaryButtonTitle: CONTINUE,
            onPrimaryOption: this.onDeleteEl,
        };
        this.setState({
            chooseDialogOpen: true,
            chooseDialogParams,
            elementId: elId,
        });
    }

    changeElementValue = (e, field) => {
        const { updatingEl } = this.state;
        if (field === 'name' || field === 'email') {
            updatingEl[field] = e.target.value;
        } else if (field === 'must_pass_min_price') {
            updatingEl[field] = e.target.checked;
        } else {
            const value = parseInt(e.target.value);
            if (Number.isNaN(value)) {
                updatingEl[field] = 0;
            } else {
                updatingEl[field] = value;
            }
        }

        this.setState({ updatingEl });
    };

    onLoadData = async () => {
        try {
            const { baseUrl } = this.props;
            const res = await loadData(baseUrl, this.signal.token);
            this.setState({ data: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке данных', err);
        }
    };

    onAddEl = async () => {
        try {
            const { newName, data } = this.state;
            const { baseUrl } = this.props;
            if (newName === '') {
                this.setState({
                    snackBarOpen: true,
                    snackBarMessage: 'Имя не должно быть пустым!',
                });
                return;
            }
            const res = await addEl(baseUrl, { name: newName }, this.signal.token);
            data.push(res.data);
            this.setState({
                data,
                newName: '',
                snackBarOpen: true,
                snackBarMessage: 'Запись добавлена!',
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании', err);
        }
    };

    onUpdateEl = async () => {
        try {
            const {
                updatingEl: {
                    id, name, sort_order, min_price, delivery_price, must_pass_min_price, email, frequency,
                },
            } = this.state;
            if (name === '') {
                this.setState({
                    snackBarOpen: true,
                    snackBarMessage: 'Имя не должно быть пустым!',
                });
                return;
            }
            if (frequency > 7 || frequency < 1) {
                this.setState({
                    snackBarOpen: true,
                    snackBarMessage: 'Неправильно задана частота!',
                });
                return;
            }

            const { baseUrl } = this.props;
            const objectData = {
                name,
                sort_order,
                min_price,
                delivery_price,
                must_pass_min_price,
                email,
                frequency
            };
            await updateEl(baseUrl, id, objectData, this.signal.token);
            this.setState({
                snackBarOpen: true,
                snackBarMessage: 'Запись обновлена!',
                isUpdate: false,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при редактировании', err);
        }
    };

    onDeleteEl = async () => {
        try {
            const { elementId } = this.state;
            const { baseUrl } = this.props;
            this.setState({
                chooseDialogOpen: false,
            });
            await deleteEl(baseUrl, elementId, this.signal.token);
            const { data } = this.state;
            const newData = data.filter(item => item.id !== elementId);
            this.setState({
                data: newData,
                snackBarOpen: true,
                snackBarMessage: 'Запись удалена!',
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при удалении', err);
        }
    }

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    }

    render() {
        const {
            newName, isUpdate, updatingEl, data, snackBarOpen, snackBarMessage,
            errorDialogOpen, errorText, errorTitle, chooseDialogOpen, chooseDialogParams, chooseTitle, chooseText,
        } = this.state;
        const { title } = this.props;
        return (
            <div style={{
                maxWidth: '900px', display: 'block', marginLeft: 'auto', marginRight: 'auto',
            }}
            >
                <App />
                <Typography
                    style={styles.pageHeader}
                    variant="h4"
                >
                    {title}
                </Typography>
                <Snackbar
                    open={snackBarOpen}
                    message={snackBarMessage}
                    onClose={() => {
                        this.setState({
                            snackBarOpen: false,
                            snackBarMessage: '',
                        });
                    }}
                    autoHideDuration={4000}
                />
                <InfoDialog
                    open={errorDialogOpen}
                    dialogText={errorText}
                    dialogTitle={errorTitle}
                    onClose={() => this.setState({ errorDialogOpen: false })}
                />
                <ChooseDialog
                    open={chooseDialogOpen}
                    dialogParams={chooseDialogParams}
                    onCancel={() => this.setState({ chooseDialogOpen: false })}
                />
                <BaseObjectSettings
                    open={isUpdate}
                    updatingEl={updatingEl}
                    dialogTitle={chooseTitle}
                    dialogText={chooseText}
                    changeElementValue={this.changeElementValue}
                    onSave={this.onUpdateEl}
                    onCancel={() => this.setState({
                        isUpdate: false,
                    })}
                />
                <div>
                    <TextField
                        style={{ marginBottom: '15px', marginRight: '15px' }}
                        value={newName}
                        onChange={e => this.setState({ newName: e.target.value })}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onAddEl}
                    >
                        Добавить
                    </Button>
                </div>
                <Paper style={{ maxWidth: '400px', marginTop: '15px' }}>
                    <List>
                        {data !== undefined && data.map((el, i, arr) => ([
                            <ListItem
                                key={el.id}
                                className="projects-list-item"
                            >
                                <ListItemText
                                    primary={`${el.sort_order}, ${el.name}`}
                                    style={{ paddingRight: '60px' }}
                                />
                                <ListItemSecondaryAction>
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => this.onDelete(el.id, el.name, title)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={() => this.setState({
                                            isUpdate: true,
                                            updatingEl: el,
                                            chooseTitle: 'Изменение параметров позиции',
                                            chooseText: '',
                                        })}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>,
                            i === arr.length - 1 ? null : <Divider key={`${el.id}_divider`} component="li" />,
                        ]))}
                    </List>
                </Paper>
            </div>
        );
    }
}

export default BaseObject;


BaseObject.propTypes = {
    title: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
};
