import axios from '../common/api_config';
import { withAuthHeader } from '../Auth';

export const loadDates = async (salesmanId, tableName, cancelToken) => {
    const res = await axios({
        url: `api/dates/${tableName}/`,
        params: { data: salesmanId },
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
