import React from 'react';
import withAuth from '../withAuth';
import BaseObject from '../common/BaseObject';

function Salesmen() {
    return (
        <BaseObject
            baseUrl="salesmen"
            title="Поставщики"
        />
    );
}

export default withAuth(Salesmen);
