import axios from '../common/api_config';
import { withAuthHeader, withCsrf } from '../Auth';

export const saveOrder = async (data, cancelToken) => {
    await axios({
        method: 'patch',
        url: '/api/orders/save_order/',
        data,
        cancelToken,
        headers: withAuthHeader(withCsrf({})),
    });
};
