import React, { Fragment } from 'react';
import { Typography } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import PropTypes from 'prop-types';
import {
    NEW, SENT, EDITED, APPROVED, WEEK,
} from '../common/constants';

const iconStyle = { marginTop: '7px' };

function CheckListStatuses({ orderStatus, isViewed }) {
    function getStatus(status) {
        if (status === NEW) {
            return 'Доступен шефу для просмотра';
        } if (status === SENT) {
            return 'Доступен шефу для редактирования';
        } if (status === EDITED) {
            return 'Исправлен шефом';
        } if (status === APPROVED) {
            return 'Подтвержден';
        }
        if (status === WEEK) {
            return '';
        }
        return 'Нет статуса';
    }
    return (
        <Fragment>
            <Typography
                style={{ margin: '2px 8px 10px 0' }}
                variant="h6"
            >
                {getStatus(orderStatus)}
            </Typography>
            {isViewed
                ? <VisibilityIcon style={iconStyle} color="primary" />
                : <VisibilityOffIcon style={iconStyle} color="primary" />}

        </Fragment>
    );
}

CheckListStatuses.propTypes = {
    orderStatus: PropTypes.string.isRequired,
    isViewed: PropTypes.bool.isRequired,
};

export default CheckListStatuses;
