import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { Snackbar, Typography } from '@material-ui/core';
import App from '../App';
import withAuth from '../withAuth';
import InfoDialog from '../common/InfoDialog';
import parseErrors from '../common/ErrorParsing';
import AddChefDialog from './AddChefDialog';
import EditChefDialog from './EditChefDialog';
import { loadRestaurants } from '../API_requests/RestaurantsAPI';
import { loadSettings, updateSettings } from '../API_requests/SettingsAPI';
import {
    loadChefs, addChef, editChef, deleteChef,
} from '../API_requests/ChefsAPI';
import SettingsTabs from './SettingsTabs';
import styles from '../styles';
import { loadSalesmen } from '../API_requests/SalesmenAPI';
import {
    loadPreferredSalesmen, addPreferredSalesman, deletePreferredSalesman, editPreferredSalesman,
} from '../API_requests/PreferredSalesmen';
import { loadData } from '../API_requests/BaseObjectsAPI';

class Settings extends Component {
    signal = axios.CancelToken.source();

    state = {
        chefs: [],
        salesmen: [],
        preferredPositions: [],
        offerPositions: [],
        restaurants: null,
        errorDialogOpen: false,
        editingEl: { restaurant: null },
        settings: {},
        addChefDialogOpen: false,
        editChefDialogOpen: false,
        errorText: '',
        errorTitle: '',
        dialogTitle: '',
        snackBarOpen: false,
        snackBarMessage: '',
    };

    componentDidMount = () => {
        this.onLoadChefs();
        this.onLoadSalesmenAndPreferred();
        this.onLoadSettings();
        this.onLoadRestaurants();
        this.onloadPositions();
    };

    componentWillUnmount() {
        this.signal.cancel();
    }

    onLoadChefs = async () => {
        try {
            const res = await loadChefs(this.signal.token);
            this.setState({ chefs: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке Шефов', err);
        }
    };

    async onLoadSalesmenAndPreferred() {
        try {
            const res = await loadSalesmen(this.signal.token);
            this.setState({
                salesmen: res.data,
            }, () => this.onLoadPreferredSalesmen());
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке поставщиков', err);
        }
    }

    async onLoadPreferredSalesmen() {
        try {
            const res = await loadPreferredSalesmen(this.signal.token);
            const { salesmen } = this.state;
            const newData = res.data.map((element) => {
                const salesman = salesmen.find(e => e.id === element.salesman);
                element.salesman_name = salesman.name;
                return element;
            });
            this.setState({
                preferredPositions: newData,
            });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке приоритетных поставщиков', err);
        }
    }

    onloadPositions = async () => {
        try {
            const res = await loadData('offer_positions/get_distinct');
            this.setState({ offerPositions: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке позиций', err);
        }
    }

    onDeletePosition = async (position) => {
        try {
            await deletePreferredSalesman(position.id, this.signal.token);
            const { preferredPositions } = this.state;
            const newPositions = preferredPositions.filter(pos => pos.id !== position.id);
            this.setState({
                preferredPositions: newPositions,
            });
            this.showSnackBar('Позиция удалена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Не удалось удалить позицию', err);
        }
    };

    changeElementValue = (e, field) => {
        const { editingEl } = this.state;
        if (field === 'restaurant') {
            if (e === null) {
                editingEl.restaurant = null;
            } else {
                const { restaurants } = this.state;
                const newRestaurant = restaurants.find(item => item.id === e.value);
                editingEl.restaurant = newRestaurant;
            }

            this.setState({ editingEl });
            return;
        }
        editingEl[field] = e.target.value;
        this.setState({ editingEl });
    };

    showSnackBar = (msg) => {
        this.setState({
            snackBarOpen: true,
            snackBarMessage: msg,
        });
    };

    addChef = async (chef) => {
        try {
            const {
                name, email, password, passwordConfirm, selectedRestaurant,
            } = chef;
            const data = {
                name,
                email,
                password,
                password_confirm: passwordConfirm,
                restaurant: selectedRestaurant ? selectedRestaurant.id : null,
            };

            const res = await addChef(data, this.signal.token);
            const { chefs } = this.state;
            const newChefs = [...chefs, res.data];
            this.setState({ chefs: newChefs, addChefDialogOpen: false });
            this.showSnackBar('Шеф зарегистрирован!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при создании Шефа', err);
            // if (err.response.data) {
            //     this.parsePasswordErrors(err.response);
            // }
        }
    };

    onEditChef = async () => {
        const {
            editingEl: {
                id, name, email, restaurant, password, passwordConfirm,
            },
        } = this.state;

        const data = {
            name,
            email,
            restaurant: restaurant ? restaurant.id : null,
        };
        if (password && password.length !== 0) {
            data.password = password;
            data.password_confirm = passwordConfirm;
        }

        try {
            const res = await editChef(data, id, this.signal.token);
            const { chefs } = this.state;
            const newChefs = chefs.map((item) => {
                if (item.id === res.id) {
                    return res;
                }
                return item;
            });
            this.setState({ chefs: newChefs, editChefDialogOpen: false });
            this.showSnackBar('Шеф сохранен!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении Шефа', err);
            // if (err.response.data) {
            //     this.parsePasswordErrors(err.response);
            // }
        }
    };

    onDeleteChef = async (chef) => {
        try {
            await deleteChef(chef.id, this.signal.token);
            const { chefs } = this.state;
            const newChefs = chefs.filter(item => item.id !== chef.id);
            this.setState({ chefs: newChefs });
            this.showSnackBar('Шеф удален!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при удалении Шефа', err);
        }
    };

    onLoadRestaurants = async () => {
        try {
            const res = await loadRestaurants(this.signal.token);
            this.setState({ restaurants: res.data });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при загрузке ресторанов', err);
        }
    };

    onLoadSettings = async () => {
        try {
            const res = await loadSettings(this.signal.token);
            this.setState({ settings: res.data[0] });
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            this.setState({ settings: {} });
            parseErrors(this.showError, 'Произошла ошибка при загрузке настроек', err);
        }
    };

    onUpdateSettings = async () => {
        try {
            const { settings } = this.state;
            await updateSettings(settings, this.signal.token);
            this.showSnackBar('Настройки успешно сохранены!');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Произошла ошибка при сохранении настроек', err);
        }
    };

    changeSettingsValue = (value, field) => {
        const { settings } = this.state;
        settings[field] = value;
        this.setState({ settings });
    };

    onAddPosition = async (position) => {
        const { selectedSalesman, selectedPosition } = position;
        if (!(selectedPosition && selectedSalesman)) return;
        const sendData = {
            salesman: selectedSalesman.id,
            position: selectedPosition,
        };
        try {
            const res = await addPreferredSalesman(sendData, this.signal.token);
            const { preferredPositions } = this.state;
            const { data } = res;
            const { salesmen } = this.state;
            const salesman = salesmen.find(e => e.id === data.salesman);
            data.salesman_name = salesman.name;
            preferredPositions.push(data);
            this.setState({
                preferredPositions,
            });
            this.showSnackBar('Позиция добавлена');
        } catch (err) {
            if (axios.isCancel(err)) {
                return;
            }
            parseErrors(this.showError, 'Не удалось добавить позицию', err);
        }
    }


    onEditPosition = async (position) => {
        const sendData = {
            id: position.id,
            salesman: position.selectedSalesman.id,
            position: position.selectedPosition,
        };
        try {
            const res = await editPreferredSalesman(sendData, this.signal.token);
            const { preferredPositions } = this.state;
            const { data } = res;
            const newPositions = preferredPositions.map((pos) => {
                if (pos.id === data.id) {
                    const { salesmen } = this.state;
                    const salesman = salesmen.find(e => e.id === data.salesman);
                    data.salesman_name = salesman.name;
                    return data;
                }
                return pos;
            });
            this.setState({
                preferredPositions: newPositions,
            });
            this.showSnackBar('Позиция сохранена');
            return 'OK';
        } catch (err) {
            if (axios.isCancel(err)) {
                return 'ERROR';
            }
            parseErrors(this.showError, 'Не удалось сохранить позицию', err);
            return 'ERROR';
        }
    };

    showError = (title, msg) => {
        this.setState({
            errorDialogOpen: true,
            errorText: msg,
            errorTitle: title,
        });
    };

    parsePasswordErrors(response) {
        function sleep(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }

        if (response.data.password) {
            response.data.password.map(async (error) => {
                if (error === 'This password is too common.') {
                    this.showSnackBar('Пароль слишком распространенный!');
                }
                if (error === 'This password is entirely numeric.') {
                    this.showSnackBar('Пароль не может состоять только из цифр!');
                }
                await sleep(2000);
            });
            return;
        }
        this.showError('Произошла ошибка при регистрации Шефа', JSON.stringify(response));
    }

    render() {
        const {
            chefs,
            restaurants,
            settings,
            snackBarOpen,
            snackBarMessage,
            errorDialogOpen,
            addChefDialogOpen,
            editChefDialogOpen,
            editingEl,
            errorText,
            errorTitle,
            dialogTitle,
            salesmen,
            preferredPositions,
            offerPositions,
        } = this.state;
        return (
            <Fragment>
                <App />
                <div
                    style={{
                        maxWidth: '900px',
                        display: 'block',
                        margin: '15px auto 0 auto',
                    }}
                >
                    <Snackbar
                        open={snackBarOpen}
                        message={snackBarMessage}
                        onClose={() => this.setState({ snackBarOpen: false, snackBarMessage: '' })}
                        autoHideDuration={2000}
                    />
                    <InfoDialog
                        open={errorDialogOpen}
                        dialogText={errorText}
                        dialogTitle={errorTitle}
                        onClose={() => this.setState({ errorDialogOpen: false })}
                    />
                    <AddChefDialog
                        open={addChefDialogOpen}
                        restaurants={restaurants}
                        dialogTitle={dialogTitle}
                        showSnackBar={this.showSnackBar}
                        addChef={this.addChef}
                        onCancel={() => this.setState({ addChefDialogOpen: false })}
                    />
                    <EditChefDialog
                        open={editChefDialogOpen}
                        restaurants={restaurants}
                        editingEl={editingEl}
                        dialogTitle={dialogTitle}
                        changeElementValue={this.changeElementValue}
                        showSnackBar={this.showSnackBar}
                        editChef={this.onEditChef}
                        onCancel={() => this.setState({
                            editChefDialogOpen: false,
                        })
                        }
                    />
                    <Typography style={styles.pageHeader} variant="h4">
                        Настройки
                    </Typography>
                    <SettingsTabs
                        chefs={chefs}
                        settings={settings}
                        changeSettingsValue={this.changeSettingsValue}
                        onUpdateSettings={this.onUpdateSettings}
                        addChef={() => this.setState({
                            addChefDialogOpen: true,
                            dialogTitle: 'Регистрация нового Шефа',
                        })
                        }
                        editChef={chef => this.setState({
                            editChefDialogOpen: true,
                            editingEl: chef,
                            dialogTitle: 'Изменение параметров позиции',
                        })
                        }
                        deleteChef={this.onDeleteChef}
                        salesmen={salesmen}
                        preferredPositions={preferredPositions}
                        offerPositions={offerPositions}
                        addPosition={this.onAddPosition}
                        editPosition={this.onEditPosition}
                        deletePosition={this.onDeletePosition}
                    />
                </div>
            </Fragment>
        );
    }
}

export default withRouter(withAuth(Settings));
