import React, { Component } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';
import PropTypes from 'prop-types';

export default class AddRestaurandDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        };
    }

    save = () => {
        const { name } = this.state;
        const { addRestaurant, onClose } = this.props;
        addRestaurant(name);
        this.setState({
            name: '',
        });
        onClose();
    };

    render() {
        const { name } = this.state;
        const { open, onClose } = this.props;
        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>Добавить новый ресторан</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Введите название ресторана
                    </DialogContentText>
                    <TextField
                        value={name}
                        onChange={e => this.setState({ name: e.target.value })}
                        autoFocus
                        required
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.save}
                    >
                        Сохранить
                    </Button>
                    <Button
                        style={{ marginLeft: 10 }}
                        onClick={onClose}
                    >
                        Закрыть
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

AddRestaurandDialog.defaultProps = {
    open: false,
};

AddRestaurandDialog.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    addRestaurant: PropTypes.func.isRequired,
};
