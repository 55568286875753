import axios from '../common/api_config';
import { withAuthHeader } from '../Auth';

export const exportCheckList = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/sorting_positions/export_check_list/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const importCheckList = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/sorting_positions/import_check_list/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const loadSortingPositions = async (cancelToken) => {
    const res = await axios({
        url: '/api/sorting_positions/',
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
