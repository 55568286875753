export const WEEK = 'WK';
export const NEW = 'NW';
export const SENT = 'ST';
export const EDITED = 'ED';
export const APPROVED = 'AP';

export const DEMAND_TYPE = 'DE';
export const OFFER_TYPE = 'OF';

export const SAVE_CHECK_LIST_TYPE = 'saveCheckListType';
export const IMPORT_SORTING_TYPE = 'importSortingType';
export const EXPORT_SORTING_TYPE = 'exportSortingType';

export const CONTINUE = 'Продолжить';
export const ADD_FILTERS = 'Добавить фильтры';
export const SAVE_AND_GO_TO_CHEF = 'Сохранить и перейти';
export const GO_TO_CHEF_WITHOUT_SAVING = 'Перейти без сохранения';
export const EXPORT_TO_GOOGLE = 'Экспорт в google';
export const EXPORT_TO_EXCEL = 'Экспорт в excel';
