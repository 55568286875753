import React, { Fragment } from 'react';
import {
    Button, IconButton, Paper, Table, TableHead, TableBody, TableRow, Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';
import TableCell from '../common/TableCell';
import styles from '../styles';

function ChefSettings({
    chefs, addChef, editChef, deleteChef,
}) {
    return (
        <Fragment>
            <Typography variant="h6">Аккаунты шефов</Typography>
            <Paper style={styles.positionsTable}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell style={styles.settingsChefTableCell}>Почта</TableCell>
                            <TableCell style={styles.settingsChefTableCell}>Имя</TableCell>
                            <TableCell style={styles.settingsChefTableCell} align="right">Ресторан</TableCell>
                            <TableCell style={styles.settingsChefTableCell} />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chefs.map(chef => (
                            <TableRow key={chef.id}>
                                <TableCell style={styles.settingsChefTableCell}>
                                    {chef.email}
                                </TableCell>
                                <TableCell style={styles.settingsChefTableCell}>
                                    {chef.name}
                                </TableCell>
                                <TableCell style={styles.settingsChefTableCell} align="right">
                                    {chef.restaurant && chef.restaurant.name}
                                </TableCell>
                                <TableCell style={styles.settingsChefTableCell} align="right">
                                    <IconButton
                                        aria-label="Delete"
                                        onClick={() => deleteChef(chef)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                    <IconButton
                                        aria-label="Edit"
                                        onClick={() => editChef(chef)}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Button
                    style={{
                        display: 'block',
                        margin: '5px auto',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={addChef}
                >Добавить
                </Button>
            </Paper>
        </Fragment>
    );
}

ChefSettings.propTypes = {
    chefs: PropTypes.arrayOf(PropTypes.object).isRequired,
    addChef: PropTypes.func.isRequired,
    editChef: PropTypes.func.isRequired,
    deleteChef: PropTypes.func.isRequired,
};

export default ChefSettings;
