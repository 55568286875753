import React, { Component, Fragment } from 'react';
import { Tabs, Tab } from '@material-ui/core';
import PropTypes from 'prop-types';
import ChefSettings from './ChefSettings';
import GeneralSettings from './GeneralSettings';
import PreferredSalesmenTable from './PreferredSalesmenTable';

export default class SettingsTabs extends Component {
    state = {
        selected: 0,
    };

    static defaultProps = {
        settings: {},
    }

    static propTypes = {
        chefs: PropTypes.arrayOf(PropTypes.object).isRequired,
        settings: PropTypes.shape({
            id: PropTypes.number,
            first_day_of_the_week: PropTypes.number,
        }),
        changeSettingsValue: PropTypes.func.isRequired,
        onUpdateSettings: PropTypes.func.isRequired,
        addChef: PropTypes.func.isRequired,
        editChef: PropTypes.func.isRequired,
        deleteChef: PropTypes.func.isRequired,
        preferredPositions: PropTypes.arrayOf(
            PropTypes.shape({
                salesman_name: PropTypes.string,
                salesman: PropTypes.number,
                position: PropTypes.string,
                id: PropTypes.number,
            }),
        ).isRequired,
        offerPositions: PropTypes.arrayOf(PropTypes.object).isRequired,
        salesmen: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number,
            name: PropTypes.string,
            min_price: PropTypes.number,
            sort_order: PropTypes.number,
        })).isRequired,
        addPosition: PropTypes.func.isRequired,
        editPosition: PropTypes.func.isRequired,
        deletePosition: PropTypes.func.isRequired,
    };

    handleChange = (event, value) => {
        this.setState({
            selected: value,
        });
    };

    render() {
        const { selected } = this.state;
        const {
            chefs, settings, changeSettingsValue, onUpdateSettings, addChef, editChef, deleteChef,
            salesmen, preferredPositions, addPosition, editPosition, deletePosition, offerPositions,
        } = this.props;
        return (
            <Fragment>
                <Tabs
                    value={selected}
                    onChange={this.handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab value={0} label="Настройки Шефов" />
                    <Tab value={1} label="Общие настройки" />
                    <Tab value={2} label="Приоритетные поставщики" />
                </Tabs>
                {selected === 0
                        && (
                            <ChefSettings
                                chefs={chefs}
                                addChef={addChef}
                                editChef={editChef}
                                deleteChef={deleteChef}
                            />
                        )}
                {selected === 1
                        && (
                            <GeneralSettings
                                settings={settings}
                                changeSettingsValue={changeSettingsValue}
                                onUpdateSettings={onUpdateSettings}
                            />
                        )}
                {selected === 2
                        && (
                            <PreferredSalesmenTable
                                salesmen={salesmen}
                                preferredPositions={preferredPositions}
                                addPosition={addPosition}
                                editPosition={editPosition}
                                deletePosition={deletePosition}
                                offerPositions={offerPositions}
                            />
                        )}
            </Fragment>
        );
    }
}
