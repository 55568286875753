import axios from '../common/api_config';
import { withAuthHeader } from '../Auth';

export const loadFilterOptions = async (type, cancelToken) => {
    const res = await axios({
        url: '/api/filters/',
        params: { type },
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};

export const createFilter = async (data, cancelToken) => {
    const res = await axios({
        method: 'post',
        url: '/api/filters/',
        data,
        cancelToken,
        headers: withAuthHeader(),
    });
    return res;
};
