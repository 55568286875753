const styles = {
    loginTextField: {
        margin: '10px 0 10px',
    },
    pageHeader: {
        display: 'flex',
        width: '900px',
        marginBottom: '10px',
    },
    pageBlock: {
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '900px',
        justifyContent: 'flex-start',
        alignItems: 'baseline',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    pageSettings: {
        display: 'flex', flexWrap: 'wrap', alignItems: 'flex-end',
    },
    textField: {
        margin: '-10px 0 10px 10px',
        width: 169,
    },
    linkField: {
        marginLeft: 10,
        width: 500,
    },
    selectField: {
        container: provided => ({
            ...provided, width: 250, marginRight: 10, marginBottom: 10,
        }),
    },
    block: {
        width: 200,
        marginRight: 10,
        textAlign: 'center',
        display: 'inline-block',
    },
    minPriceOffer: {
        background: '#90ee90',
    },
    order: {
        background: '#ffae42',
    },
    error: {
        color: 'red',
        fontSize: 14,
    },
    progress: {
        position: 'relative',
    },
    refresh: {
        display: 'inline-block',
        position: 'relative',
    },
    menu: {
        width: 191,
        height: 250,
        marginRight: 20,
    },
    form: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    parent: {
        width: '100%',
        height: '100%',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
        overflow: 'auto',
    },
    positionsTable: {
        display: 'block',
        overflowX: 'auto',
        marginBottom: '10px',
    },
    redIcon: {
        color: 'red',
    },
    greenIcon: {
        color: 'green',
    },
    boldCell: {
        fontWeight: 'bold',
    },
    greenCell: {
        background: '#94e98e',
    },
    redCell: {
        background: '#e95b62',
    },
    yellowCell: {
        background: '#f5ff7f',
    },
    blueCell: {
        background: '#e8eaf6',
    },
    positionsTableCell: {
        minWidth: 140,
        padding: '0 5px 0 5px',
    },
    settingsChefTableCell: {
        paddingRight: '10px',
        paddingLeft: '10px',
    },
    deliveriesTableCell: {
        minWidth: '85px',
        width: '110px',
        padding: '0',
    },
    addPositionTextField: {
        margin: '5px 0 5px',
    },
    settingsChefTextField: {
        marginRight: '18px',
        marginBottom: '15px',
    },
    buttonPrimary: {
        color: '#ffffff',
    },
    buttonSave: {
        marginBottom: '10px',
        width: '137px',
    },
    regularButton: {
        height: '36px',
        width: '108px',
        marginBottom: '10px',
        marginRight: '10px',

    },
    wideButton: {
        marginRight: '10px',
        marginBottom: '10px',
        minWidth: '230px',
    },
    tableButton: {
        width: '108px',
    },
    navLink: {
        color: '#3f51b5',
        boxShadow: '0 2px 0 0 #3f51b5',
    },
    circularProgress: {
        position: 'absolute',
        top: '0',
        right: '0',
        bottom: '0',
        left: '0',
        display: 'block',
        margin: 'auto',
    },
};

export default styles;
